import React from 'react'
import { useSelector } from 'react-redux'
import { Typography, useMediaQuery } from '@material-ui/core'
import { ReactComponent as CronosAltIcon } from 'assets/icons/cronos-logo-alt-icon.svg'
import { ReactComponent as WhatsAppIcon } from 'assets/icons/original-whatsapp-icon.svg'
import clsx from 'clsx'
import { SearchWidget } from 'pages/HomePage/components'
import { MainLayout, NavBar, PageContainer } from 'shared'

import { FeatureProductCard, Footer, SectionFeature, SectionFeatureProduct } from './components'
import { useStyles } from './TimingPage.style'

const WHATSAPP_URL =
  'https://wa.me/5493885205251?text=Hola,%20quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20sistema%20de%20Cronometraje'

const TimingPage = () => {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const { user } = useSelector((state) => state.auth)

  return (
    <MainLayout>
      <div className={classes.floatingWhatsappContainer}>
        <a href={WHATSAPP_URL} target='_blank' rel='noreferrer'>
          <WhatsAppIcon width={50} height={50} />
        </a>
      </div>
      <NavBar
        widget={(props) => <SearchWidget mini {...props} />}
        showWidget={false}
        darkMode
        hiddenUserSessión={!user}
      />
      <PageContainer altMode customContainerClassName={classes.page}>
        <div className={classes.landingHeader}>
          <div className={classes.container}>
            <div className={classes.titleContainer}>
              <Typography className={classes.title}>EL TIEMPO ES LO QUE VALE</Typography>
              <CronosAltIcon className={classes.cronos} />
            </div>
          </div>
        </div>

        <div className={clsx(classes.container, classes.content)}>
          <Typography className={classes.cronosText}>
            Crono es una herramienta que permite cronometrar el evento deportivo. Brinda información
            en tiempo real de las posiciones, minimizando errores en la clasificación final.
            <br />
            Contamos con licencias para cronometristas, y brindamos también el servicio de
            cronometraje.
          </Typography>
        </div>
        <div className={classes.darkContainer}>
          <div className={classes.container}>
            <SectionFeature />
          </div>
        </div>

        <div className={classes.container}>
          <Typography variant='h2' className={classes.timmingTitle}>
            ¿SOS CRONOMETRISTA?
          </Typography>
        </div>
        <div className={classes.backgroundHeader} />
        <div className={classes.featureProductContainer}>
          <SectionFeatureProduct />
        </div>

        <div className={classes.container}>
          <Typography variant='h2' className={classes.cronosToEventTitle}>
            ¿NECESITAS QUE CRONOMETREMOS TU EVENTO?
          </Typography>
        </div>
        <div className={classes.backgroundHeader} />
        <div className={classes.cronosToEventSection}>
          <FeatureProductCard
            title='CRONO'
            subTitle='EN TU EVENTO'
            actionButton='mailto:crono@sportmetric.net'
            className={classes.cronosToEvent}
            actionTextButton={isDesktop ? 'SOLICITAR COTIZACIÓN' : 'COTIZAR'}
            textDescription={{
              primary:
                'Si preferís delegar el registro de participantes y el cronometraje de las competencias, también tenemos la solución:',
              secondary: 'Vamos a tu evento (**) y lo resolvemos todo.'
            }}
            textAnotation='(**) Área de cobertura Jujuy, Salta y Tucumán.'
          />
        </div>
      </PageContainer>

      <Footer />
    </MainLayout>
  )
}

export default TimingPage
