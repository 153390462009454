import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Typography } from '@material-ui/core'
import { EVENT_INSCRIPTION_STATES } from 'utils/constants'

import { AssignNumberDialog } from '../AssignNumberDialog'

import { useStyles } from './AssignNumberRow.style'

const AssignNumberRow = ({
  inscription,
  searchInputText,
  selectedFilter,
  currentPageSize,
  currentState
}) => {
  const classes = useStyles()

  const { bibNumbers } = useSelector((state) => state.events)

  const [openAssignNumberDialog, setOpenAssignNumberDialog] = useState(false)

  const hasNumber = bibNumbers.some((x) => !x.assignedAt)

  return (
    <>
      {!inscription.number ? (
        inscription.state === EVENT_INSCRIPTION_STATES.ACCEPTED && (
          <Button
            color='secondary'
            variant='contained'
            disabled={!hasNumber}
            className={classes.assignNumberButton}
            onClick={() => setOpenAssignNumberDialog(true)}>
            Asignar número
          </Button>
        )
      ) : (
        <Typography color='primary' variant='h6' align='center'>
          Número asignado
        </Typography>
      )}
      <AssignNumberDialog
        open={openAssignNumberDialog}
        onClose={() => setOpenAssignNumberDialog(false)}
        currentPageSize={currentPageSize}
        inscription={inscription}
        currentInscriptionState={currentState}
        selectedFilter={selectedFilter}
        searchInputText={searchInputText}
      />
    </>
  )
}

export default AssignNumberRow
