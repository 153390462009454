import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import {
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Instagram as InstagramIcon
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import altNoImage from 'assets/img/blue-user-mock-alt.jpg'
import noImage from 'assets/img/user-mock.png'
import clsx from 'clsx'
import { Formik } from 'formik'
import debounce from 'lodash.debounce'
import { CountriesProxy, UserAccountsProxy } from 'services'
import {
  AdministratorPermissionDialog,
  CustomizedTooltip,
  SectionLayoutDialog,
  StyledSwitch
} from 'shared'
import { resetValue, updateOrganization } from 'state/modules/organizations'
import { loadOrganizationsAdminAll } from 'state/modules/userAccounts'
import { STRING_EMPTY, URL_PATTERN, USER_ROLE } from 'utils/constants'
import {
  getCountries,
  normalizeUrl,
  removeDiacritics,
  searchDisciplines,
  validateCuit
} from 'utils/functions'
import { showSnackbarError, showSnackbarSuccess } from 'utils/snackbar'
import * as Yup from 'yup'

import { useStyles } from './FormEditDialog.style'

const ARGENTINA_COUNTRY = { label: 'Argentina', value: '01fa2de7-519f-4089-a33c-d48483ea0f20' }
const CUIT_LENGTH = 11

const FormEditDialog = ({ open, onClose, organization = {} }) => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const { userData } = useSelector((state) => state.auth)
  const { pending, errorUpdate } = useSelector((state) => state.organizations)
  const { activeRole } = useSelector((state) => state.app)

  const [countries, setcountries] = useState([])
  const [provinces, setProvinces] = useState([])
  const [options, setOptions] = useState([])
  const [disciplines, setDisciplines] = useState([])
  const [loading, setLoading] = useState(false)
  const [checkSocialMedia, setCheckSocialMedia] = useState({
    facebookUrl: !!organization.facebookUrl,
    instagramUrl: !!organization.instagramUrl,
    youTubeUrl: !!organization.youTubeUrl,
    web: !!organization.web
  })
  const [openAdministratorDialog, setOpenAdministratorDialog] = useState(false)

  const socialSize = { height: 48, width: 48 }
  const empty = { value: '', label: '' }
  const color = activeRole === USER_ROLE.ORGANIZER ? 'secondary' : 'primary'

  const organizationValues = {
    id: organization.id,
    name: organization.name,
    phone: organization.phone || STRING_EMPTY,
    eMail: organization.eMail || STRING_EMPTY,
    handle: organization.handle || STRING_EMPTY,
    idNumber: organization.idNumber || STRING_EMPTY,
    countryId: organization.countryId || STRING_EMPTY,
    areaLevel1Id: organization.areaLevel1Id || STRING_EMPTY,
    facebookUrl: organization.facebookUrl || STRING_EMPTY,
    instagramUrl: organization.instagramUrl || STRING_EMPTY,
    youTubeUrl: organization.youTubeUrl || STRING_EMPTY,
    createdById: organization.createdById,
    web: organization.web || STRING_EMPTY,
    hasActiveContract: organization.hasActiveContract,
    referentName: organization.referentName || organization.createdByFullName || STRING_EMPTY,
    activeAddOrganizationAdmin: organization.userAccounts.some(
      (x) => !!x.joinedAt && x.userAccountId !== organization.createdById
    ),
    userAdmins:
      organization.userAccounts
        .filter((x) => !!x.joinedAt && x.userAccountId !== organization.createdById)
        .map((x) => ({
          id: x.userAccountId,
          username: x.userAccountUsername,
          eMail: x.userAccountEMail
        })) ?? [],
    disciplines: organization.disciplines.map((d) => ({ value: d.id, label: d.name })),
    userSelected: null,
    hasWhatsApp: organization.hasWhatsApp
  }

  const isOrganizationOwner = organization.createdById === userData?.id

  const fetchCountries = async () => setcountries(await getCountries()(STRING_EMPTY))
  const fetchDisciplines = async () => setDisciplines(await searchDisciplines(''))

  useEffect(() => {
    fetchCountries()
    fetchDisciplines()

    onCountryChange()(organization.countryId)
  }, [])

  useEffect(() => {
    if (errorUpdate) showSnackbarError(errorUpdate, 'Error', 2500)

    return () => dispatch(resetValue('errorUpdate'))
  }, [errorUpdate])

  const fetchUsers = async (searchText = '', setFieldValue, values) => {
    setFieldValue('usernameOrEmail', searchText)

    if (!searchText || searchText.length < 3) {
      setOptions([])
      return
    }

    try {
      setLoading(true)

      const proxy = new UserAccountsProxy()
      const data = await proxy.getUserAccountOrganizationSuggestions(searchText, 1, 20)

      setOptions([...values.userAdmins, ...data.results])

      return data.results
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async ({
    name,
    handle,
    eMail,
    countryId,
    phone,
    areaLevel1Id,
    youTubeUrl,
    facebookUrl,
    instagramUrl,
    idNumber,
    referentName,
    id,
    web,
    disciplines,
    userAdmins,
    hasWhatsApp
  }) => {
    const values = {
      id,
      name: name.trim(),
      handle: handle.trim(),
      referentName: referentName.trim(),
      eMail,
      countryId,
      idNumber,
      disciplines: disciplines.map((d) => ({ id: d.value })),
      phone,
      areaLevel1Id,
      youTubeUrl: normalizeUrl(youTubeUrl),
      instagramUrl: normalizeUrl(instagramUrl),
      facebookUrl: normalizeUrl(facebookUrl),
      web: normalizeUrl(web),
      hasWhatsApp,
      userAccounts: userAdmins.map((x) => ({ userAccountId: x.id, isAdmin: true }))
    }

    const updateSucces = await dispatch(updateOrganization(values))

    if (updateSucces) {
      onClose()
      dispatch(loadOrganizationsAdminAll())
      showSnackbarSuccess('¡Organización actualizada correctamente!')
    }
  }

  const onCountryChange = (setFieldValue) => async (option) => {
    try {
      if (setFieldValue) {
        setFieldValue('countryId', option)
        setFieldValue('areaLevel1Id', null, false)
      }

      setProvinces([])

      if (option !== empty.value) {
        const proxy = new CountriesProxy()
        const data = await proxy.getAreaLevel1(option)
        if (Array.isArray(data) && !!data.length) {
          if (option !== ARGENTINA_COUNTRY.value) {
            setProvinces([empty, ...data.map((d) => ({ value: d.id, label: d.name }))])
          } else {
            setProvinces(data.map((d) => ({ value: d.id, label: d.name })))
          }
        }
      }
    } catch (e) {}
  }

  const debouncerFetchUser = (inputValue, setFieldValue, values) =>
    fetchUsers(inputValue, setFieldValue, values)

  const debounceOnChange = debounce(debouncerFetchUser, 700)

  const onDisciplinesChange = (setFieldValue) => (options) => setFieldValue('disciplines', options)

  const validationSchema = Yup.object().shape({
    eMail: Yup.string()
      .required('Ingresa tu dirección de correo')
      .min(5, 'El e-mail debe tener al menos 5 caracteres')
      .email('El e-mail es inválido')
      .max(254, 'El email debe tener como máximo 254 caracteres'),
    handle: Yup.string()
      .required('Ingresa el alias de la organización')
      .min(3, 'El alias de la organización debe tener al menos 3 caracteres')
      .max(30, 'El alias de la organización debe tener como máximo 30 caracteres')
      .matches(
        /^[a-z0-9]+$/i,
        'El alias de la organización no debe tener caracteres especiales ni espacios'
      )
      .trim(),
    name: Yup.string()
      .max(100, 'El nombre de la organización es demasiado largo')
      .required('Ingresa el nombre de la organización'),
    phone: Yup.string()
      .min(5, 'El número de teléfono debe tener al menos de 5 caracteres')
      .max(20, 'El número de teléfono no puede tener más de 20 caracteres')
      .required('Debe ingresar un número de télefono')
      .matches(/^[+]*(?:[0-9]?){5,20}[0-9]$/, 'Debe ingresar un número de télefono válido')
      .test(
        'test-phone',
        'El número de teléfono no puede contener números negativos',
        (value) => Number(value) > 0
      ),
    idNumber: Yup.string().when('hasActiveContract', {
      is: (hasActiveContract) => hasActiveContract,
      then: Yup.string()
        .required('Debe ingresar el CUIT/CUIL')
        .matches(/^[0-9]+$/i, 'El CUIT/CUIL no debe tener caracteres especiales ni espacios')
        .test(
          'test-length',
          'El CUIT/CUIL debe tener un tamaño de 11 caracteres',
          (value) => !!value && value.length === CUIT_LENGTH
        )
        .test('test-cuit', 'El CUIT/CUIL no es válido', validateCuit)
        .trim()
    }),

    countryId: Yup.string().required('Debe elegir el páis'),
    referentName: Yup.string()
      .transform((value) => removeDiacritics(value))
      .required('Debe ingresar el nombre del referente de la organización')
      .max(50, 'El nombre debe tener un tamaño máximo de 50 caracteres')
      .matches(
        /^[a-z ,.-]+$/i,
        'El nombre del referente no debe tener caracteres especiales ni números'
      )
      .trim(),
    facebookUrl: Yup.string().test(
      'test-url',
      'La URL ingresada no corresponde con una dirección facebook válida',
      (value) => !value || URL_PATTERN.test(normalizeUrl(value))
    ),
    instagramUrl: Yup.string().test(
      'test-url',
      'La URL ingresada no corresponde con una dirección instagram válida',
      (value) => !value || URL_PATTERN.test(normalizeUrl(value))
    ),
    youTubeUrl: Yup.string().test(
      'test-url',
      'La URL ingresada no corresponde con una dirección youtube válida',
      (value) => !value || URL_PATTERN.test(normalizeUrl(value))
    ),
    web: Yup.string().test(
      'test-url',
      'La URL ingresada no corresponde con una dirección web válida',
      (value) => !value || URL_PATTERN.test(normalizeUrl(value))
    ),
    disciplines: Yup.mixed()
      .nullable()
      .test(
        'test-min',
        'Debe seleccionar al menos una opción',
        (value) => Array.isArray(value) && value.some(Boolean)
      )
  })

  const renderAdminOption = (option) => {
    const { eMail, profileImages, username, id, isOrganizer } = option

    const currentNoImage = isOrganizer ? altNoImage : noImage

    const imageUrl =
      Array.isArray(profileImages) && profileImages.length > 0 ? profileImages[0] : currentNoImage

    return (
      <div className={clsx(classes.optionContainer, isOrganizer && 'organizer')}>
        <div className={classes.imageContainer} style={{ backgroundImage: `url(${imageUrl})` }} />

        <div>
          <Typography variant='h6' title={eMail}>
            {eMail}
          </Typography>
          <Typography
            variant='h6'
            title={`@${username || id} (Rol ${isOrganizer ? 'organizador' : 'deportista'})`}>
            @{username || id} <span>(Rol {isOrganizer ? 'organizador' : 'deportista'})</span>
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <SectionLayoutDialog
      title='EDITAR ORGANIZACIÓN'
      open={open}
      onClose={onClose}
      color={color}
      className={classes.dialog}>
      <div className={classes.mainContainer}>
        <Typography color='primary' variant='h6' className={classes.title}>
          Información de la Organización
        </Typography>
        <Formik
          initialValues={organizationValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={validationSchema}>
          {({
            values,
            handleSubmit,
            errors,
            touched,
            handleBlur,
            handleChange,
            isValid,
            setFieldValue,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.input}
                  name='name'
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete='off'
                  helperText={errors.name}
                  error={touched.name && Boolean(errors.name)}
                  fullWidth
                  required
                  disabled
                  label='Nombre de la Organización'
                  variant='outlined'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {touched.name && Boolean(errors.name) && <ErrorIcon color='error' />}
                      </InputAdornment>
                    )
                  }}
                  size='small'
                  margin='none'
                />

                <TextField
                  className={classes.input}
                  name='handle'
                  value={values.handle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete='off'
                  helperText={errors.handle || 'Nombre corto que identifique a la organización'}
                  error={touched.handle && Boolean(errors.handle)}
                  fullWidth
                  required
                  variant='outlined'
                  label='Alias de la organización'
                  size='small'
                  margin='none'
                  InputProps={{
                    endAdornment: touched.handle && Boolean(errors.handle) && (
                      <InputAdornment position='end'>
                        <ErrorIcon color='error' />
                      </InputAdornment>
                    )
                  }}
                />
              </div>

              <div className={classes.formGroup}>
                <TextField
                  name='eMail'
                  className={classes.input}
                  value={values.eMail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.eMail}
                  error={touched.eMail && Boolean(errors.eMail)}
                  fullWidth
                  required
                  label='E-mail de la Organización'
                  autoComplete='off'
                  variant='outlined'
                  size='small'
                  type='email'
                  margin='none'
                  InputProps={{
                    endAdornment: touched.eMail && Boolean(errors.eMail) && (
                      <InputAdornment position='end'>
                        <ErrorIcon color='error' />
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  className={classes.input}
                  name='idNumber'
                  value={values.idNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete='off'
                  helperText={errors.idNumber || 'CUIT/CUIL usado por la organización'}
                  error={touched.idNumber && Boolean(errors.idNumber)}
                  fullWidth
                  disabled={organization.hasActiveContract}
                  required
                  variant='outlined'
                  label='Número de CUIT/CUIL'
                  size='small'
                  margin='none'
                  InputProps={{
                    endAdornment: touched.idNumber && Boolean(errors.idNumber) && (
                      <InputAdornment position='end'>
                        <ErrorIcon color='error' />
                      </InputAdornment>
                    )
                  }}
                />
              </div>

              <div className={classes.formGroup}>
                <TextField
                  className={classes.input}
                  name='referentName'
                  value={values.referentName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete='off'
                  helperText={errors.referentName}
                  error={touched.referentName && Boolean(errors.referentName)}
                  fullWidth
                  required
                  variant='outlined'
                  label='Referente contacto de la organización'
                  size='small'
                  margin='none'
                  InputProps={{
                    endAdornment: touched.referentName && Boolean(errors.referentName) && (
                      <InputAdornment position='end'>
                        <ErrorIcon color='error' />
                      </InputAdornment>
                    )
                  }}
                />
                <div className={classes.phoneContainer}>
                  <TextField
                    name='phone'
                    className={classes.input}
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.phone}
                    error={touched.phone && Boolean(errors.phone)}
                    fullWidth
                    required
                    label='Teléfono de contacto'
                    autoComplete='off'
                    variant='outlined'
                    size='small'
                    type='text'
                    margin='none'
                    InputProps={{
                      endAdornment: touched.phone && Boolean(errors.phone) && (
                        <InputAdornment position='end'>
                          <ErrorIcon color='error' />
                        </InputAdornment>
                      )
                    }}
                  />

                  <div className={classes.whatsAppContainer}>
                    <StyledSwitch
                      checked={values.hasWhatsApp}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='hasWhatsApp'
                    />
                    <Typography variant='h6' className={classes.whatsAppTitle}>
                      Tiene WhatsApp
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={classes.formGroup}>
                <FormControl variant='outlined'>
                  <InputLabel id='country-native-simple' className={classes.labelSelect}>
                    País*
                  </InputLabel>
                  <Select
                    className={classes.select}
                    value={values.countryId}
                    error={Boolean(errors.countryId) && touched.countryId}
                    onBlur={handleBlur}
                    name='countryId'
                    onChange={(e) => onCountryChange(setFieldValue)(e.target.value)}
                    color='primary'
                    variant='outlined'
                    labelId='country-native-simple'
                    label='País*'>
                    {countries.map((x) => (
                      <MenuItem value={x.value} key={x.value}>
                        {x.label}
                      </MenuItem>
                    ))}
                  </Select>

                  <Typography variant='caption' className={classes.helperText}>
                    {errors.countryId || 'Seleccione su pais'}
                  </Typography>
                </FormControl>

                <FormControl variant='outlined'>
                  <InputLabel
                    id='area-level-1-select-outlined-label'
                    className={classes.labelSelect}>
                    Seleccione su Provincia
                  </InputLabel>
                  <Select
                    name='areaLevel1Id'
                    labelId='area-level-1-select-outlined-label'
                    id='area-level-1-select-outlined'
                    label='Seleccione su Provincia'
                    value={values.areaLevel1Id}
                    onChange={handleChange}
                    error={Boolean(errors.areaLevel1Id) && touched.areaLevel1Id}
                    onBlur={handleBlur}
                    className={classes.select}>
                    <MenuItem value={empty.value}>{empty.label} </MenuItem>

                    {provinces.map((x) => (
                      <MenuItem value={x.value} key={x.value}>
                        {x.label}
                      </MenuItem>
                    ))}
                  </Select>

                  <Typography variant='caption' className={classes.helperText}>
                    {errors.areaLevel1Id}
                  </Typography>
                </FormControl>
              </div>

              <div className={classes.formGroup}>
                <div className={classes.blockContainer}>
                  <div className={classes.selectContainer}>
                    <FormControl variant='outlined'>
                      <Autocomplete
                        multiple
                        id='disciplinesSelect-autocomplete'
                        options={disciplines}
                        getOptionLabel={(option) => option.label}
                        selectOnFocus
                        onChange={(event, optionSelected) =>
                          onDisciplinesChange(setFieldValue)(optionSelected)
                        }
                        getOptionSelected={(x) =>
                          values.disciplines.some((o) => o.value === x.value)
                        }
                        clearText='Quitar todo'
                        openText='Abrir desplegable'
                        closeText='Cerrar desplegable'
                        filterSelectedOptions
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant='outlined'
                              label={option.label}
                              key={option.value}
                              title={option.label}
                              {...getTagProps({ index })}
                              color='primary'
                              className={classes.option}
                            />
                          ))
                        }
                        value={values.disciplines}
                        className={classes.combo}
                        loadingText={<Typography align='center'> Cargando...</Typography>}
                        noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={classes.comboInput}
                            name='disciplines'
                            label='Seleccione Deporte/ Disciplina*'
                            variant='outlined'
                            onBlur={handleBlur}
                            error={touched.disciplines && !!errors.disciplines}
                            helperText={
                              errors.disciplines || 'Seleccione la o las opciones del desplegable'
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                </div>
                {isOrganizationOwner && (
                  <div className={classes.blockContainer}>
                    <div className={classes.adminContainer}>
                      <div className={classes.checkContainer}>
                        <FormControlLabel
                          className={classes.formControl}
                          classes={{
                            label: classes.checkLabel
                          }}
                          control={
                            <Checkbox
                              checked={values.activeAddOrganizationAdmin}
                              className={classes.checkbox}
                              onChange={(e) => {
                                if (!values.activeAddOrganizationAdmin)
                                  setFieldValue('userAdmins', [])

                                handleChange(e)
                              }}
                              name='activeAddOrganizationAdmin'
                              color='primary'
                            />
                          }
                          label='¿Desea añadir uno o más colaboradores?'
                        />

                        <CustomizedTooltip
                          position={isDesktop ? 'right' : 'top-start'}
                          disabledClickAway
                          className={classes.adminTooltip}
                          buttonClassName={classes.adminTooltipButton}
                          arrowClassName={classes.adminTooltipArrow}
                          isTopMost
                          title={
                            <Typography className={classes.tooltipTitle}>
                              La persona que añadas podrá gestionar eventos bajo tu supervisión/
                              autorización.
                              <Link
                                to='#'
                                className={classes.tooltipLinkBlue}
                                onClick={(e) => {
                                  e.preventDefault()
                                  setOpenAdministratorDialog(true)
                                }}>
                                ¿Te cuento más?
                              </Link>
                            </Typography>
                          }>
                          {({ handleTooltip }) => (
                            <IconButton
                              onMouseDown={(event) => event.preventDefault()}
                              edge='end'
                              size='small'
                              color='primary'
                              onClick={handleTooltip}>
                              <InfoIcon className={classes.infoIcon} />
                            </IconButton>
                          )}
                        </CustomizedTooltip>
                      </div>
                    </div>

                    {values.activeAddOrganizationAdmin && (
                      <div className={classes.selectContainer}>
                        <Autocomplete
                          id='organizationUser'
                          options={options.filter((x) => values.createdById !== x.id)}
                          selectOnFocus
                          multiple
                          onInputChange={(e) =>
                            debounceOnChange(e?.target.value, setFieldValue, values)
                          }
                          onChange={(e, optionSelected) =>
                            setFieldValue('userAdmins', optionSelected)
                          }
                          getOptionSelected={(x) => values.userAdmins.some((u) => u.id === x.id)}
                          closeIcon={<CloseIcon color='primary' className={classes.closeIcon} />}
                          handleHomeEndKeys
                          filterSelectedOptions
                          filterOptions={(x) => x}
                          loading={loading}
                          loadingText={<Typography align='center'> Cargando...</Typography>}
                          getOptionLabel={(option) => option.username || option.eMail}
                          noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                          className={classes.combo}
                          clearText='Quitar todo'
                          openText='Abrir desplegable'
                          closeText='Cerrar desplegable'
                          value={values.userAdmins}
                          renderOption={renderAdminOption}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant='outlined'
                                label={option.username || option.eMail}
                                key={option.id}
                                title={option.username || option.eMail}
                                {...getTagProps({ index })}
                                color='primary'
                                className={classes.option}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className={classes.comboInput}
                              name='usernameOrEmail'
                              label='Escribí el email o alias del usuario*'
                              variant='outlined'
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loading && <CircularProgress color='primary' size={16} />}

                                    <CustomizedTooltip
                                      position={isDesktop ? 'right' : 'top-start'}
                                      disabledClickAway
                                      isTopMost
                                      className={classes.altTooltip}
                                      buttonClassName={classes.adminTooltipButton}
                                      arrowClassName={classes.adminTooltipArrow}
                                      title={
                                        <Typography className={classes.tooltipTitle}>
                                          El mail de invitación al/los colaboradores se enviará al
                                          guardar los cambios.
                                        </Typography>
                                      }>
                                      {({ handleTooltip }) => (
                                        <IconButton
                                          onMouseDown={(event) => event.preventDefault()}
                                          size='small'
                                          color='primary'
                                          className={classes.endButton}
                                          onClick={handleTooltip}>
                                          <InfoIcon className={classes.infoIcon} />
                                        </IconButton>
                                      )}
                                    </CustomizedTooltip>
                                  </>
                                )
                              }}
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className={classes.socialContainer}>
                <Typography color='primary' variant='h6' className={classes.socialTitle}>
                  Redes sociales
                </Typography>
                <div className={classes.socialForm}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.checkboxSocial}
                        checked={checkSocialMedia.web}
                        onChange={() => {
                          setCheckSocialMedia({
                            ...checkSocialMedia,
                            web: !checkSocialMedia.web
                          })
                        }}
                        name='webEnabled'
                        color='primary'
                      />
                    }
                    className={classes.control}
                    classes={{ label: classes.webCheckLabel }}
                    label='Web'
                  />
                  {checkSocialMedia.web && (
                    <TextField
                      color='primary'
                      variant='outlined'
                      autoFocus
                      name='web'
                      size='small'
                      placeholder='Web'
                      fullWidth
                      onChange={handleChange}
                      value={values.web}
                      onBlur={handleBlur}
                      error={touched.web && Boolean(errors.web)}
                      helperText={errors.web}
                      className={classes.inputSocial}
                    />
                  )}
                </div>
                <div className={classes.socialForm}>
                  <FormControlLabel
                    value={true}
                    onChange={() => {
                      setCheckSocialMedia({
                        ...checkSocialMedia,
                        facebookUrl: !checkSocialMedia.facebookUrl
                      })
                    }}
                    name='facebookUrl'
                    className={classes.checkboxRoles}
                    control={
                      <Checkbox
                        color='primary'
                        checked={checkSocialMedia.facebookUrl}
                        name='facebookUrl'
                        className={classes.checkboxSocial}
                      />
                    }
                    label={
                      <SocialIcon
                        url={values.facebookUrl || undefined}
                        network='facebook'
                        style={socialSize}
                        bgColor={'transparent'}
                        fgColor={theme.palette.primary.main}
                        target='_blank'
                        className={classes.socialIcon}
                      />
                    }
                  />
                  {checkSocialMedia.facebookUrl && (
                    <TextField
                      name='facebookUrl'
                      value={values.facebookUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={touched.facebookUrl && Boolean(errors.facebookUrl)}
                      required
                      helperText={errors.facebookUrl}
                      variant='outlined'
                      size='small'
                      margin='normal'
                      className={classes.inputSocial}
                      placeholder='Facebook'
                    />
                  )}
                </div>
                <div className={classes.socialForm}>
                  <FormControlLabel
                    value={true}
                    onChange={() => {
                      setCheckSocialMedia({
                        ...checkSocialMedia,
                        instagramUrl: !checkSocialMedia.instagramUrl
                      })
                    }}
                    name='instagramUrl'
                    className={classes.checkboxRoles}
                    control={
                      <Checkbox
                        checked={checkSocialMedia.instagramUrl}
                        name='instagramUrl'
                        className={classes.checkboxSocial}
                      />
                    }
                    label={<InstagramIcon className={classes.instagramIcon} />}
                  />
                  {checkSocialMedia.instagramUrl && (
                    <TextField
                      name='instagramUrl'
                      value={values.instagramUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={touched.instagramUrl && Boolean(errors.instagramUrl)}
                      required
                      variant='outlined'
                      size='small'
                      margin='normal'
                      className={classes.inputSocial}
                      placeholder='Instagram'
                    />
                  )}
                </div>
                <div className={classes.socialForm}>
                  <FormControlLabel
                    value={true}
                    onChange={() => {
                      setCheckSocialMedia({
                        ...checkSocialMedia,
                        youTubeUrl: !checkSocialMedia.youTubeUrl
                      })
                    }}
                    name='youTubeUrl'
                    className={classes.checkboxRoles}
                    control={
                      <Checkbox
                        checked={checkSocialMedia.youTubeUrl}
                        name='youTubeUrl'
                        className={classes.checkboxSocial}
                      />
                    }
                    label={
                      <SocialIcon
                        url={values.youTubeUrl || undefined}
                        network='youtube'
                        style={socialSize}
                        bgColor={'transparent'}
                        fgColor={theme.palette.primary.main}
                        target='_blank'
                        className={classes.socialIconYoutube}
                      />
                    }
                  />
                  {checkSocialMedia.youTubeUrl && (
                    <TextField
                      name='youTubeUrl'
                      value={values.youTubeUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={touched.youTubeUrl && Boolean(errors.youTubeUrl)}
                      required
                      variant='outlined'
                      size='small'
                      margin='normal'
                      className={classes.inputSocial}
                      placeholder='YouTube'
                    />
                  )}
                </div>
              </div>
              <AdministratorPermissionDialog
                open={openAdministratorDialog}
                onClose={() => setOpenAdministratorDialog(false)}
              />

              <Button
                color='primary'
                variant='contained'
                type='submit'
                className={classes.button}
                disabled={!isValid || isSubmitting}
                endIcon={pending && <CircularProgress size={16} />}>
                Guardar
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </SectionLayoutDialog>
  )
}

export default FormEditDialog
