import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  inscriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      width: 360,
      margin: '0 auto'
    }
  },
  input: {
    flexGrow: 1,
    '& fieldset': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      minHeight: 45
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing()
    },

    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.contrastText,
      paddingRight: theme.spacing()
    },
    '& input': {
      zIndex: 1,
      fontWeight: 500
    },
    '& > p': {
      color: theme.palette.secondary.contrastText
    }
  },
  combo: {
    width: '100%',

    '& > div > label': {
      transform: 'translate(14px, 10px) scale(1)'
    },
    '& > div > div': {
      paddingBottom: `0 !important`,
      paddingTop: `0 !important`,
      paddingLeft: `${theme.spacing()} !important`,
      paddingRight: `${theme.spacing()} !important`
    },
    '& > div > div > div': {
      zIndex: 2
    },
    '& svg': {
      color: theme.palette.primary.main
    },

    [theme.breakpoints.up('md')]: {
      width: 350
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    alignItems: 'center'
  },
  saveButton: {
    width: 250,
    height: 48,
    borderRadius: 10,

    '& span': {
      color: theme.palette.primary.main,
      fontSize: '1.125rem',
      fontWeight: 500
    },

    '&:disabled': {
      '& span': {
        color: 'rgba(0, 0, 0, 0.26)'
      }
    }
  },
  option: {
    width: '100%',
    height: '100%',
    border: `1px solid ${theme.palette.primary.main}`,

    '&:hover': {
      backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.6)
    }
  },
  optionLabel: {
    color: theme.palette.secondary.contrastText,

    '&:hover': {
      color: theme.palette.primary.main
    },

    '&.alert': {
      color: `${theme.palette.error.main} !important`
    }
  },
  optionAlert: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(),
    gap: theme.spacing(),

    '& p': {
      fontSize: '.875rem',
      fontWeight: 500
    }
  },
  formGroup: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  },
  iconContainer: {
    alignSelf: 'center',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    padding: theme.spacing(1.5)
  },
  icon: {
    width: 61,
    height: 61
  },
  number: {
    fontWeight: 600,
    fontSize: '2rem'
  },
  fullName: {
    fontSize: '1.5rem',
    fontWeight: 500,

    [theme.breakpoints.up('md')]: {
      fontSize: '1.25rem'
    }
  },
  inscriptionInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  distanceAndCategory: {
    fontSize: '1.5rem',
    fontWeight: 500,

    [theme.breakpoints.up('md')]: {
      fontSize: '1.25rem'
    }
  }
}))
