import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  form: {
    margin: theme.spacing(3, 0)
  },
  title: {
    padding: theme.spacing(0, 2),
    fontSize: '.875rem',

    [theme.breakpoints.up('md')]: {
      fontWeight: 700,
      fontSize: '1.125rem',
      padding: 0
    }
  },
  distanceContainer: {
    margin: theme.spacing(2, 0),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  }
}))
