import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '69.2vh'
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.primary.contrastText,
    flexDirection: 'column',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    marginBottom: theme.spacing(),
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      minHeight: theme.spacing(15),
      padding: theme.spacing(0, 10)
    }
  },
  eventName: {
    textAlign: 'center',
    padding: theme.spacing(2, 4),
    fontSize: '1rem',
    cursor: 'pointer',

    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem'
    }
  },
  paymentInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.utils.rgba('#D9D9D9', 0.7),
    padding: theme.spacing(2)
  },
  title: {
    marginTop: theme.spacing(2)
  },
  paymentInfo: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline'
    }
  },
  paymentMethodContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 4),
    gap: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(3),
      flexDirection: 'row',
      padding: 0,
      alignItems: 'center'
    }
  },
  paymentMethodTitle: {
    fontWeight: 600
  },
  paymentMethodEnabled: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),

    '& span': {
      color: '#000000',
      fontWeight: 600
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: theme.spacing(5)
    }
  },
  bodyContainer: {
    backgroundColor: '#ffff',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: 1280,
      padding: theme.spacing(0, 4),
      margin: '0 auto'
    }
  },
  icon: {
    position: 'absolute',
    right: 15,
    top: 7
  },
  optionButton: {
    width: 200,
    position: 'relative',
    borderRadius: 10
  },
  buttonsSection: {
    display: 'flex',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(3),
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),

    '&.assignNumber': {
      order: 1,
      [theme.breakpoints.up('md')]: {
        order: 0
      }
    },
    '&.hidden': {
      display: 'none'
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(3),
      gap: theme.spacing(3),
      flexDirection: 'row',
      justifyContent: 'center'
    },

    '&.alt': {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 2),
      alignItems: 'flex-start',

      [theme.breakpoints.up('md')]: {
        backgroundColor: theme.palette.alt.main,
        flexDirection: 'row',
        borderRadius: 10,
        alignItems: 'center',
        padding: theme.spacing(3, 4),
        margin: theme.spacing(4, 0),
        justifyContent: 'space-between'
      }
    }
  },
  createNumbersButton: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: 200,
      height: 40,
      borderRadius: 10,
      marginRight: theme.spacing(2),

      '& span': {
        textAlign: 'center'
      }
    }
  },
  createNumbersLink: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '.875rem',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  messageContainer: {
    backgroundColor: '#ffff',
    height: '69.1vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0 20px'
  },
  searchContainer: {
    display: 'flex',
    gap: theme.spacing(4),
    paddingTop: theme.spacing(3),
    flexDirection: 'column-reverse',

    '& form': {
      flexGrow: 1
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      flexDirection: 'row'
    }
  },
  searchSection: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),

    [theme.breakpoints.up('md')]: {
      padding: 0
    }
  },
  field: {
    '& > div': {
      paddingRight: 0
    },

    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500
    },

    '& input[type="search"]::-webkit-search-cancel-button': {
      display: 'none'
    },
    '& input[type="search"]::-moz-search-cancel-button': {
      display: 'none'
    },

    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      minHeight: 45
    },

    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3)
    }
  },

  searchButton: {
    height: 40
  },

  statusEllipse: {
    width: 18,
    height: 18
  },

  button: {
    padding: theme.spacing(2),
    '& svg': {
      fontSize: '3rem',
      width: '100%',
      fontWeight: 600,
      lineHeight: '20px'
    }
  },

  tooltipArrow: {
    color: theme.palette.tooltip.main
  },
  tooltip: {
    width: 300,
    backgroundColor: theme.palette.tooltip.main,

    [theme.breakpoints.up(750)]: {
      width: '100%',
      maxWidth: 600
    }
  },

  assignNumberButton: {
    borderRadius: 10,
    width: 200,
    '& span': {
      color: theme.palette.primary.main
    },

    '&:disabled': {
      '& span': {
        color: theme.palette.disabled.main
      }
    }
  },
  altAssignNumberButton: {
    borderRadius: 10,
    width: 200,
    alignSelf: 'center',

    '& span': {
      color: theme.palette.primary.main
    },

    '&:disabled': {
      backgroundColor: theme.palette.alt.light,

      '& span': {
        color: theme.palette.primary.contrastText
      }
    },

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  notFoundContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '68.5vh',
    justifyContent: 'center',
    alignItems: 'center',
    ' & h5': {
      fontWeight: 600
    }
  },

  disabledIcon: {
    color: theme.utils.rgba(theme.palette.disabled.main, 0.7)
  },

  assignNumberHeaderContainer: {
    position: 'relative',
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  returnInscriptionListLink: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      marginTop: theme.spacing(2),
      fontWeight: 500,
      textDecoration: 'none',
      fontSize: '1rem',
      position: 'absolute',
      left: 0,

      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  assignHelperText: {
    fontWeight: 700,
    fontSize: '1rem',
    padding: theme.spacing(0, 2),
    paddingTop: theme.spacing(),

    [theme.breakpoints.up('md')]: {
      fontSize: '1.125rem',
      padding: 0
    }
  },
  assignInfo: {
    fontWeight: 600,
    padding: theme.spacing(0, 2),
    fontSize: '.75rem',

    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      padding: 0
    }
  },
  editNumberButton: {
    width: 200,
    height: 40,
    borderRadius: 10,

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  backButton: {
    alignSelf: 'center',

    '& svg': {
      width: 50,
      height: 50
    },

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  alertIcon: {
    color: theme.palette.tooltip.light,
    position: 'absolute',
    right: -14,
    top: 6
  },
  altTooltip: {
    backgroundColor: theme.palette.tooltip.light,
    width: 245,
    fontSize: '.75rem'
  },
  altButton: {
    right: '8px !important',
    top: '5px !important'
  },
  altArrow: {
    color: theme.palette.tooltip.light
  },
  assignContainer: {
    position: 'relative',
    alignSelf: 'center',

    '&.full': {
      width: '100%'
    }
  }
}))
