import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  assignNumberButton: {
    boxShadow: 'none',
    width: '100%',

    '& span': {
      color: theme.palette.primary.main
    },

    '&:disabled': {
      backgroundColor: theme.palette.alt.light,

      '& span': {
        color: theme.palette.primary.contrastText
      }
    }
  }
}))
