import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress } from '@material-ui/core'
import { Formik } from 'formik'
import moment from 'moment'
import { ROUTES } from 'routes'
import {
  deleteRulesDocument,
  load,
  resetValue,
  updateEvent,
  uploadRulesDocument
} from 'state/modules/events'
import {
  DATE_FORMAT,
  EVENT_STATES,
  INFORMATION_NAME_FIELDS,
  MAX_DATE,
  MIN_DATE,
  STRING_EMPTY
} from 'utils/constants'
import { getTextFromHtmlString } from 'utils/functions'
import { useQuery } from 'utils/hooks'
import { showSnackbarError, showSnackbarSuccess } from 'utils/snackbar'
import * as Yup from 'yup'

import { CircuitSection } from '../CircuitSection'
import { DescriptionSection } from '../DescriptionSection'
import { EventDateSection } from '../EventDateSection'
import { InformationSection } from '../InformationSection'
import { LinkInscriptionSection } from '../LinkInscriptionSection'
import { RulesSection } from '../RulesSection'
import { ScheduleSection } from '../ScheduleSection'
import { StateSection } from '../StateSection'
import { SuspensionReasonSection } from '../SuspensionReasonSection'
import { SuspensionUpdateEventSection } from '../SuspensionUpdateEventSection'
import { TimingSection } from '../TimingSection'

import { useStyles } from './EditForm.style'

const MAX_ALLOWED_DAYS = 7
const ARGENTINA_COUNTRY = { label: 'Argentina', value: '01fa2de7-519f-4089-a33c-d48483ea0f20' }
const MAX_LENGTH_DESCRIPTION = 4000
const MIN_LENGTH_DESCRIPTION = 100
const MAX_ALLOWED_DISCIPLINES = 3

const EditForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const query = useQuery()

  const focus = query.get('focus')

  const { event, error } = useSelector((state) => state.events)

  useEffect(() => {
    if (error) showSnackbarError(error)

    return () => dispatch(resetValue('error'))
  }, [error])

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .max(100, 'El nombre del evento tiene un máximo de 100 caracteres')
        .required('Ingrese el nombre del evento')
        .trim(),
      fromDate: Yup.mixed()
        .nullable()
        .required('Ingrese una fecha/hora de inicio del evento')
        .test('valid-date', 'Formato de fecha/hora inválida', (value) => moment(value).isValid())
        .test('range-date', 'Fecha/hora inválida', (value) =>
          moment(value).isBetween(moment(MIN_DATE), moment(MAX_DATE), 'YYYY-MM-DD')
        )
        .when(['toDate', 'toTime', 'fromTime'], {
          is: (toDate, toTime, fromTime) =>
            !!toDate &&
            moment(toDate).isValid() &&
            !!toTime &&
            moment(toTime).isValid() &&
            !!fromTime &&
            moment(fromTime).isValid(),
          then: (schema) =>
            schema
              .test(
                'from-max',
                'La fecha y hora de inicio debe ser menor a la fecha de fin del evento',
                (value, { parent }) =>
                  moment(
                    `${moment(value).format('YYYY-MM-DD')} ${moment(parent.fromTime).format(
                      'HH:mm'
                    )}`
                  ).isBefore(
                    moment(
                      `${moment(parent.toDate).format('YYYY-MM-DD')} ${moment(parent.toTime).format(
                        'HH:mm'
                      )}`
                    )
                  )
              )
              .test(
                'to-duration-max',
                'La duración del evento no puede exceder los 7 días',
                (value, { parent }) =>
                  moment(moment(parent.toDate).format('YYYY-MM-DD')).diff(
                    moment(value).format('YYYY-MM-DD'),
                    'days'
                  ) < MAX_ALLOWED_DAYS
              )
        }),
      fromTime: Yup.mixed()
        .nullable()
        .required('Ingrese una fecha/hora de inicio del evento')
        .test('valid-date', 'Formato de fecha/hora inválida', (value) => moment(value).isValid()),
      toDate: Yup.mixed()
        .nullable()
        .required('Ingrese una fecha/hora de fin del evento')
        .test('valid-date', 'Formato de fecha/hora inválida', (value) => moment(value).isValid())
        .test('range-date', 'Fecha/hora inválida', (value) =>
          moment(value).isBetween(moment(MIN_DATE), moment(MAX_DATE), 'YYYY-MM-DD')
        )
        .when(['fromDate', 'fromTime', 'toTime'], {
          is: (fromDate, fromTime, toTime) =>
            !!toTime &&
            moment(toTime).isValid() &&
            !!fromDate &&
            moment(fromDate).isValid() &&
            !!fromTime &&
            moment(fromTime).isValid(),
          then: (schema) =>
            schema
              .test(
                'to-min',
                'La fecha y hora de cierre debe ser mayor a la fecha de inicio del evento',
                (value, { parent }) =>
                  moment(
                    `${moment(value).format('YYYY-MM-DD')} ${moment(parent.toTime).format('HH:mm')}`
                  ).isAfter(
                    moment(
                      `${moment(parent.fromDate).format('YYYY-MM-DD')} ${moment(
                        parent.fromTime
                      ).format('HH:mm')}`
                    )
                  )
              )
              .test(
                'from-duration-max',
                'La duración del evento no puede exceder los 7 días',
                (value, { parent }) =>
                  moment(moment(value).format('YYYY-MM-DD')).diff(
                    moment(parent.fromDate).format('YYYY-MM-DD'),
                    'days'
                  ) < MAX_ALLOWED_DAYS
              )
        }),
      toTime: Yup.mixed()
        .nullable()
        .required('Ingrese una fecha/hora de fin del evento')
        .test('valid-date', 'Formato de fecha/hora inválida', (value) => moment(value).isValid()),
      disciplinesSelect: Yup.array()
        .nullable()
        .test(
          'test-disciplines',
          'Debes seleccionar al menos una disciplina',
          (value) => !!value?.length
        )
        .max(MAX_ALLOWED_DISCIPLINES, 'Puede seleccionar hasta 3 disciplinas'),
      location: Yup.string().when('undefinedLocation', {
        is: (undefinedLocation) => !undefinedLocation,
        then: (schema) =>
          schema.test('test-location', 'Debes seleccionar una ubicación', (value) => !!value)
      }),
      areaLevel1Id: Yup.string()
        .nullable()
        .when('countryId', {
          is: (countryId) => countryId === ARGENTINA_COUNTRY.value,
          then: (schema) => schema.required('Debe seleccionar una provincia / Región / Estado')
        }),
      address: Yup.string()
        .nullable()
        .when('undefinedLocation', {
          is: (undefinedLocation) => !undefinedLocation,
          then: (schema) => schema.required('Debe ingresar la dirección exacta del evento').trim()
        }),
      descriptionText: Yup.string()
        .min(MIN_LENGTH_DESCRIPTION, 'La descripción debe tener al menos 100 caracteres')
        .max(MAX_LENGTH_DESCRIPTION, 'La descripción tiene un máximo de 4000 caracteres')
        .test('test-description', 'Ingrese una descripción', (value) => !!value && !!value.trim())
        .trim(),

      circuitText: Yup.string().nullable().max(3000, 'Se puede ingresar hasta 3000 caracteres'),

      schedule: Yup.array().of(
        Yup.object()
          .nullable()
          .shape({
            descriptionText: Yup.string()
              .nullable()
              .max(3000, 'Se puede ingresar hasta 3000 caracteres')
          })
      ),

      suspensionDescriptionText: Yup.string()
        .nullable()
        .when('state', {
          is: (state) => event.state === EVENT_STATES.SUSPENDED,
          then: Yup.string()
            .nullable()
            .required('Debe ingresar una descripción sobre la suspensión')
            .max(1000, 'La descripción de la suspensión debe tener hasta 1000 caracteres')
            .trim()
        }),

      rulesUrl: Yup.string()
        .nullable()
        .transform((value) =>
          !!value && !value.includes('http://') && !value.includes('https://')
            ? `http://${value}`
            : value
        )
        .trim()
        .url('Debe ingresar una url válida'),
      externalInscriptionUrl: Yup.string()
        .nullable()
        .test(
          'test-externalInscriptionUrl',
          'El link de inscripción no tiene una url valida',
          (value) => {
            const pattern =
              /[-a-zA-Z0-9@:%._'+~#=]{1,256}\.[a-zA-Z0-9()]{1,15}\b([-a-zA-Z0-9()@:%_'+.~#?&'/'/=]*)/
            return !value || !!pattern.test(value)
          }
        ),
      phone: Yup.string()
        .nullable()
        .required('Debe ingresar un número de teléfono')
        .min(5, 'El número de teléfono debe tener al menos 5 caracteres')
        .max(20, 'El número de teléfono no puede tener más de 20 caracteres')
        .matches(/^[+]*(?:[0-9]?){5,20}[0-9]$/, 'El número de telefono ingresado no es válido'),
      updateDescription: Yup.string()
        .nullable()
        .when('state', {
          is: (state) => event.state === EVENT_STATES.SUSPENDED,
          then: (schema) =>
            schema
              .min(35, 'Debe ingresar al menos 35 caracteres')
              .max(200, 'La actualización del evento tiene un máximo de 200 caracteres')
              .trim()
        }),

      timekeeperName: Yup.string()
        .nullable()
        .min(3, 'Debe ingresar al menos 3 caracteres')
        .max(100, 'Tiene un máximo de 100 caracteres')
    },

    ['fromDate', 'toDate']
  )

  const organizationOptions = event.organizations
    .filter((x) => x.organization.id !== event.organization.id)
    .map((x) => x.organization)

  const organizationSelectFocused = focus === INFORMATION_NAME_FIELDS.ORGANIZATIONS

  const values = useMemo(
    () => ({
      disciplinesSelect: event.disciplines.map((x) => ({ label: x.name, value: x.id })),
      name: event.name,
      countryId: event.countryId || STRING_EMPTY,
      areaLevel1Id: event.areaLevel1Id || STRING_EMPTY,
      fromDate: moment(event.from),
      fromTime: moment(event.from),
      toDate: moment(event.to),
      toTime: moment(event.to),
      address: event.address,
      latitude: event.latitude,
      longitude: event.longitude,
      description: event.description,
      descriptionText: getTextFromHtmlString(event.description ?? STRING_EMPTY),
      state: event.state,
      location:
        event.latitude !== 0 && event.longitude !== 0
          ? `${event.latitude} | ${event.longitude}`
          : STRING_EMPTY,
      organizationsSelect: organizationOptions,
      enableAddOrganization: !!organizationOptions.length || organizationSelectFocused,
      isPublished: event.isPublished,
      circuit: event.circuit,
      circuitText: getTextFromHtmlString(event.circuit ?? STRING_EMPTY),
      rulesDocumentFile: event.rulesDocumentFile,
      rulesUrl: event.rulesUrl,
      rulesFileNameOrUrl: event.rulesDocumentFile?.fileName.split('rules-')[1] || event.rulesUrl,
      organizationId: event.organization.id,
      schedule: [],
      sectionState: true,
      undefinedLocation: !event.address && event.latitude === 0 && event.longitude === 0,
      externalInscriptionUrl: event.externalInscriptionUrl,
      id: event.id,
      phone: event.phone || STRING_EMPTY,
      hasWhatsApp: event.hasWhatsApp || false,
      organizationPhone: event.organization.phone,
      isEventOwner: event.organization.loggedUserMetadata?.canEdit,
      suspensionDescription: event.suspensionDescription,
      suspensionDescriptionText: getTextFromHtmlString(event.suspensionDescription ?? STRING_EMPTY),
      sectionDisabled: event.state === EVENT_STATES.SUSPENDED,
      updateDescription: event.updateDescription || STRING_EMPTY,
      eventTimekeeper: event.eventTimekeeper,
      timekeeperName: event.eventTimekeeper?.name ?? STRING_EMPTY,
      timekeeperSelected: event.eventTimekeeper?.organization ?? null,
      useOrganizationPhone: event.phone === event.organization.phone
    }),
    [event]
  )

  const handleOnSave = async (values) => {
    const {
      fromDate,
      fromTime,
      toDate,
      toTime,
      name,
      latitude,
      longitude,
      rulesUrl,
      circuit,
      countryId,
      areaLevel1Id,
      address,
      disciplinesSelect,
      isPublished,
      state,
      description,
      phone,
      hasWhatsApp,
      organizationsSelect,
      externalInscriptionUrl,
      updateDescription,
      enableAddOrganization,
      rulesDocumentFile,
      suspensionReason,
      suspensionReasonOther,
      timekeeperName,
      suspensionDescription,
      timekeeperSelected,
      id
    } = values

    const hasTimeKeeperSelected =
      timekeeperSelected &&
      (timekeeperSelected.name === timekeeperName || timekeeperSelected.handle === timekeeperName)

    const eventTimekeeper = {
      id: event.eventTimekeeper?.id,
      organizationId: hasTimeKeeperSelected ? timekeeperSelected.id : null,
      name: timekeeperName
    }

    try {
      if (event.rulesDocumentFile?.fileName !== rulesDocumentFile?.name) {
        if (rulesDocumentFile) {
          await dispatch(uploadRulesDocument(id, rulesDocumentFile))
        } else {
          await dispatch(deleteRulesDocument(id))
        }
      }
    } catch (error) {
      showSnackbarError(error)
    }

    const updateScheduleItems = event.schedule.map((x) => {
      const scheduleItem = values.schedule.find(
        (s) => Boolean(s) && s.scheduleDate.isSame(x.scheduleDate)
      )

      return {
        ...x,
        ...(scheduleItem && {
          ...scheduleItem,
          scheduleDate: scheduleItem.scheduleDate.format(DATE_FORMAT)
        })
      }
    })

    const newSchuduleItems = values.schedule
      .filter(
        (x) =>
          Boolean(x) &&
          Boolean(x.description) &&
          !event.schedule.some((s) => x.scheduleDate.isSame(s.scheduleDate))
      )
      .map((x) => ({
        ...x,
        scheduleDate: x.scheduleDate.format(DATE_FORMAT)
      }))

    const updateEventData = {
      ...event,
      name,
      latitude,
      longitude,
      address,
      description,
      rulesUrl,
      circuit,
      countryId,
      areaLevel1Id,
      state,
      isPublished,
      externalInscriptionUrl,
      organizations: enableAddOrganization
        ? organizationsSelect.map((organization) => ({ organization }))
        : [],
      phone,
      hasWhatsApp,
      suspensionReason,
      suspensionDescription,
      suspensionReasonOther,
      updateDescription,
      disciplines: disciplinesSelect.map((x) => ({ id: x.value })),
      from: moment(
        `${moment(fromDate).format('YYYY-MM-DD')} ${moment(fromTime).format('HH:mm')}`
      ).format(), // `${fromDate}T${fromTime}:00.000${values.timezone}`,
      to: moment(
        `${moment(toDate).format('YYYY-MM-DD')} ${moment(toTime).format('HH:mm')}`
      ).format(),
      eventTimekeeper: timekeeperName ? eventTimekeeper : null,
      schedule: [...updateScheduleItems, ...newSchuduleItems]
    }

    const updateData = await dispatch(updateEvent(updateEventData))

    if (updateData) {
      if (event.name.trim() !== name) {
        history.replace(`${ROUTES.EVENTS.EDIT}/${updateData.slugUrl}`)
      } else {
        dispatch(load(event.id))
      }
      showSnackbarSuccess('El evento se ha actualizado correctamente')
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={handleOnSave}>
      {({ handleSubmit, isSubmitting, isValid, values, errors }) => {
        const scheduleSectionShow =
          !!values.fromDate &&
          moment(values.fromDate).isValid() &&
          !!values.toDate &&
          moment(values.toDate).isValid()

        const eventDateValid =
          (!!values.fromDate && moment(values.fromDate).isValid()) ||
          (!!values.toDate && moment(values.toDate).isValid())

        const sectionDisabled =
          event.state === EVENT_STATES.SUSPENDED &&
          (values.state === EVENT_STATES.SUSPENDED || !eventDateValid)

        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <StateSection />

            {event.state === EVENT_STATES.SUSPENDED && values.state === EVENT_STATES.SUSPENDED && (
              <SuspensionReasonSection />
            )}

            {event.state === EVENT_STATES.SUSPENDED && values.state === EVENT_STATES.SUSPENDED && (
              <SuspensionUpdateEventSection />
            )}

            {event.state === EVENT_STATES.SUSPENDED && values.state === EVENT_STATES.ACTIVE && (
              <EventDateSection />
            )}

            <InformationSection sectionDisabled={sectionDisabled} />

            <DescriptionSection sectionDisabled={sectionDisabled} />

            <TimingSection sectionDisabled={sectionDisabled} />

            {!!event.externalInscriptionUrl && (
              <LinkInscriptionSection sectionDisabled={sectionDisabled} />
            )}

            <RulesSection sectionDisabled={sectionDisabled} />

            <CircuitSection sectionDisabled={sectionDisabled} />

            {scheduleSectionShow && <ScheduleSection sectionDisabled={sectionDisabled} />}

            <Button
              variant='contained'
              color='primary'
              type='submit'
              className={classes.saveButton}
              disabled={isSubmitting || !isValid || !values.sectionState}
              endIcon={isSubmitting && <CircularProgress size={16} color='primary' />}>
              Guardar cambios
            </Button>
          </form>
        )
      }}
    </Formik>
  )
}

export default EditForm
