import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '300px !important',
    [theme.breakpoints.up('md')]: {
      width: 400,
      maxWidth: 400
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  }
}))
