import React, { useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, IconButton, Typography, useMediaQuery } from '@material-ui/core'
import {
  Check as CheckIcon,
  EditOutlined as EditOutlinedIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  MailOutline as MailOutlineIcon,
  WhatsApp as WhatsAppIcon
} from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import clsx from 'clsx'
import moment from 'moment'
import { EllipseStatus } from 'shared'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'
import {
  accreditEventInscription,
  deleteSaleOrderPayment,
  loadBibNumbers,
  loadListInscriptions,
  loadListInscriptionSearchMetadata,
  setListinscription,
  updateEventInscription,
  updateSaleOrderPayment
} from 'state/modules/events'
import {
  EVENT_INSCRIPTION_STATES,
  INSCRIPTION_ORDER_BY,
  ORDER_DIRECTIONS,
  PAYMENT_TYPES,
  SALE_ORDER_STATES,
  STRING_EMPTY
} from 'utils/constants'
import { loadAccreditTicket } from 'utils/functions'
import { showSnackbarError, showSnackbarSuccess } from 'utils/snackbar'

import { AccreditDialog } from '../AccreditDialog'
import { AccreditTicketDialog } from '../AccreditTicketDialog'
import { AssignNumberRow } from '../AssignNumberRow'
import { ChangeStateDialog } from '../ChangeStateDialog'
import { EditInscriptionFormDialog } from '../EditInscriptionFomDialog'
import { InfoInscriptionDialog } from '../InfoInscriptionDialog'
import { InscriptionCard } from '../InscriptionCard'
import { MercadoPagoInfoDialog } from '../MercadoPagoInfoDialog'
import { ViewFileReceiptDialog } from '../ViewFileReceiptDialog'
import { WarningStateDialog } from '../WarningStateDialog'

import { useStyles } from './RegisteredList.style'

const PAGE_SIZE = 25
const MIN_PAGE_SIZE = 3

const SELECT_FILTER_INITIAL_VALUE = {
  state: STRING_EMPTY,
  orderBy: INSCRIPTION_ORDER_BY.NUMBER,
  orderDirection: ORDER_DIRECTIONS.DESC,
  saleOrderState: STRING_EMPTY
}

const RegisteredList = ({
  searchInputText,
  currentPageSize,
  isInscriptionsPage,
  currentInscriptionState,
  inscriptionSelected,
  handleSelected,
  selectedFilter = SELECT_FILTER_INITIAL_VALUE,
  setSelectedFilter = () => {}
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const {
    event,
    listInscription,
    listInscriptionPageCurrentPage,
    listInscriptionPageLastPage,
    listInscriptionPending,
    listInscriptionPageCount
  } = useSelector((state) => state.events)

  const [nextOrderBy, setNextOrderBy] = useState(STRING_EMPTY)
  const [filterPending, setFilterPending] = useState({})
  const [selectOpenDialog, setSelectOpenDialog] = useState(null)
  const [openWarningStateDialog, setOpenWarningStateDialog] = useState(false)
  const [activeEdit, setActiveEdit] = useState(false)
  const [saveChangesState, setSaveChangesState] = useState([])
  const [updateInscriptionStatesPending, setUpdateInscriptionStatesPending] = useState(false)
  const [openChangeStateDialog, setOpenChangeStateDialog] = useState(false)
  const [inscriptionData, setInscriptionData] = useState({})
  const [openAccreditDialog, setOpenAccreditDialog] = useState(false)
  const [openAccreditTicketDialog, setOpenAccreditTicketDialog] = useState(false)
  const [accreditPending, setAccreditPending] = useState(false)
  const [fileUrl, setfileUrl] = useState(null)

  const { id, to } = event

  const inscriptions = useMemo(
    () =>
      listInscription.map((x) => ({
        ...x,
        state:
          x.state === EVENT_INSCRIPTION_STATES.CANCELLED &&
          x.saleOrder?.state === SALE_ORDER_STATES.EXPIRED
            ? SALE_ORDER_STATES.EXPIRED
            : x.state
      })),
    [listInscription]
  )

  const hasFilterActiveLoading = Object.values(filterPending).some(Boolean)
  const canEditInscription = moment(to).isSameOrAfter(moment())
  const canEditStateInscription =
    isInscriptionsPage &&
    canEditInscription &&
    event.isPublished &&
    !activeEdit &&
    !!inscriptions.length

  const numberFilterOnlyApply =
    !searchInputText && (selectedFilter.withNumber || selectedFilter.withoutNumber)

  const observerOptions = [
    {
      value: EVENT_INSCRIPTION_STATES.PENDING,
      label: (
        <EllipseStatus status='yellow' title='Preinscripto' className={classes.statusEllipse} />
      )
    },
    {
      value: EVENT_INSCRIPTION_STATES.ACCEPTED,
      label: <EllipseStatus status='green' title='Inscripto' className={classes.statusEllipse} />
    },
    {
      value: EVENT_INSCRIPTION_STATES.CANCELLED,
      label: <EllipseStatus status='red' title='Anulado' className={classes.statusEllipse} />
    },
    {
      value: SALE_ORDER_STATES.EXPIRED,
      label: <EllipseStatus status='grey' title='Vencido' className={classes.statusEllipse} />,
      optionHidden: true
    }
  ]

  const renderOrderDirectionArrow = (direction = ORDER_DIRECTIONS.DESC, orderByApply) => {
    const showArrow =
      (!hasFilterActiveLoading && nextOrderBy === orderByApply) ||
      (!filterPending[orderByApply] && selectedFilter.orderBy === orderByApply)

    if (!showArrow) return

    if (direction === ORDER_DIRECTIONS.DESC) return <KeyboardArrowDownIcon color='primary' />
    else if (direction === ORDER_DIRECTIONS.ASC) return <KeyboardArrowUpIcon color='primary' />
  }

  const handleSaveStateCancel = () => {
    inscriptions.forEach((element) => {
      if ('newState' in element) {
        delete element.newState
      }
    })
    setActiveEdit(!activeEdit)
    setSaveChangesState([])
  }

  const getStatusInscription = (state) => observerOptions.find((x) => x.value === state)

  const handleChangeCanEdit = () => setActiveEdit(!activeEdit)

  const handleChangeSaveState = (e, field) => {
    if (saveChangesState.some((element) => element.id === field.id)) {
      const response = saveChangesState.find((element) => element.id === field.id)
      response.newState = e.value
      const result = inscriptions.find((element) => element.id === field.id)
      result.newState = e.value
    } else {
      const result = inscriptions.find((element) => element.id === field.id)
      result.newState = e.value

      setSaveChangesState([
        ...saveChangesState,
        {
          ...result
        }
      ])
    }
  }

  const handleOpenDialog = () => {
    const updateListInscription = saveChangesState.filter(
      (element) => element.state !== element.newState
    )

    if (updateListInscription.length === 0) handleChangeCanEdit()
    else {
      setSaveChangesState(updateListInscription)
      setOpenChangeStateDialog(!openChangeStateDialog)
    }
  }

  const handleChange = async (onLoad, value) => {
    if (listInscriptionPageCurrentPage === value) return

    if (activeEdit && saveChangesState.length > 0) {
      setOpenWarningStateDialog(true)
      setSelectOpenDialog(() => onLoad)
    } else {
      await onLoad()
    }
  }

  const handleUpdateState = async () => {
    try {
      setUpdateInscriptionStatesPending(true)

      for (const { newState, saleOrder, ...restProp } of saveChangesState) {
        const isInscriptionExpired = newState === SALE_ORDER_STATES.EXPIRED

        await dispatch(
          updateEventInscription({
            ...restProp,
            state: isInscriptionExpired ? EVENT_INSCRIPTION_STATES.CANCELLED : newState,
            saleOrder: saleOrder && {
              ...saleOrder,
              state:
                !isInscriptionExpired && saleOrder.state === SALE_ORDER_STATES.EXPIRED
                  ? SALE_ORDER_STATES.CANCELLED
                  : saleOrder.state
            }
          })
        )
      }

      await dispatch(loadListInscriptions(event.id, searchInputText))
      await dispatch(loadListInscriptionSearchMetadata(event.id, searchInputText))

      setActiveEdit(!activeEdit)
      setSaveChangesState([])
      setSelectedFilter({
        ...selectedFilter,
        ...SELECT_FILTER_INITIAL_VALUE
      })
    } catch (error) {
    } finally {
      setUpdateInscriptionStatesPending(false)
    }
  }

  const handleChangeOrder = async (orderBy) => {
    if (hasFilterActiveLoading) return

    try {
      setFilterPending({ [orderBy]: true })

      const orderDirection =
        selectedFilter.orderBy === orderBy &&
        selectedFilter.orderDirection === ORDER_DIRECTIONS.DESC
          ? ORDER_DIRECTIONS.ASC
          : ORDER_DIRECTIONS.DESC

      await dispatch(
        loadListInscriptions(
          event.id,
          searchInputText,
          1,
          currentInscriptionState,
          selectedFilter.saleOrderState,
          currentPageSize,
          orderBy,
          orderDirection,
          'replace',
          selectedFilter.withNumber,
          selectedFilter.withoutNumber
        )
      )

      setSelectedFilter({ ...selectedFilter, orderBy, orderDirection })
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setFilterPending({})
    }
  }

  const handleChangeNextOrderBy = (state) => setNextOrderBy(state)

  const clearnextOrderBy = () => setNextOrderBy(STRING_EMPTY)

  const handleDeletePayment = (saleOrder, payment) => async () => {
    const data = await dispatch(deleteSaleOrderPayment(saleOrder.id, payment.id))

    if (!data) return

    await dispatch(
      loadListInscriptions(
        event.id,
        searchInputText,
        listInscriptionPageCurrentPage,
        selectedFilter.state,
        selectedFilter.saleOrderState,
        currentPageSize,
        selectedFilter.orderBy,
        selectedFilter.orderDirection
      )
    )

    showSnackbarSuccess('¡Se eliminó el comprobante de pago con éxito!')
  }

  const handleAccepted = (onActionAccepted) => async () => {
    if (activeEdit && saveChangesState.length > 0) {
      await dispatch(loadListInscriptions(event.id))
      dispatch(loadListInscriptionSearchMetadata(event.id))
      setActiveEdit(false)
      setSaveChangesState([])

      if (onActionAccepted) onActionAccepted()
    }
  }

  const handleUpdatePayment = (payment) => async () => {
    const data = await dispatch(updateSaleOrderPayment(payment))

    return data
  }

  const miniLoadInscriptions = (page, inscription) => {
    const inscriptions = [...listInscription]

    const index = inscriptions.findIndex((x) => x.id === inscription.id)

    inscriptions[index] = inscription

    dispatch(loadBibNumbers(id))
    dispatch(setListinscription(inscriptions))

    dispatch(loadListInscriptionSearchMetadata(id, searchInputText))
  }

  const getFileReceiptProps = (inscription) => {
    if (!inscription.saleOrder) return { disabledViewReceipt: true }

    const activePayments = inscription.saleOrder.payments.filter(
      (x) => !x.removedAt && x.hasReceiptFile
    )

    if (!activePayments.length) return { disabledViewReceipt: true }

    const lastPayment = activePayments.at(-1)

    return {
      fileUrl: lastPayment.receiptFileUrl,
      fileExtension: lastPayment.receiptFile.fileName.split('.').pop(),
      reviewed: !!lastPayment.updatedAt,

      onDeletePayment: handleDeletePayment(inscription.saleOrder, lastPayment),
      onUpdatePayment: handleUpdatePayment(lastPayment)
    }
  }

  const loadInscriptions = async () => {
    await dispatch(loadListInscriptionSearchMetadata(event.id, searchInputText))

    await dispatch(
      loadListInscriptions(
        event.id,
        searchInputText,
        listInscriptionPageCurrentPage,
        currentInscriptionState,
        selectedFilter.saleOrderState,
        PAGE_SIZE,
        selectedFilter.orderBy,
        selectedFilter.orderDirection,
        'replace',
        selectedFilter.withNumber,
        selectedFilter.withoutNumber
      )
    )
  }

  const tryAccreditInscription = async (inscription) => {
    try {
      setAccreditPending(true)

      if (!inscription.accreditedAt) {
        const data = await dispatch(accreditEventInscription(inscription.id))
        inscription.accreditedAt = data.accreditedAt

        await dispatch(
          loadListInscriptions(
            event.id,
            searchInputText,
            listInscriptionPageCurrentPage,
            currentInscriptionState,
            selectedFilter.saleOrderState,
            PAGE_SIZE,
            selectedFilter.orderBy,
            selectedFilter.orderDirection,
            'replace',
            selectedFilter.withNumber,
            selectedFilter.withoutNumber
          )
        )
      }

      const url = await loadAccreditTicket(inscription)
      setOpenAccreditDialog(true)
      setInscriptionData(inscription)
      setfileUrl(url)
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setAccreditPending(false)
    }
  }

  const renderFileViewerReceipt = (inscription) => {
    const fileReceiptProps = getFileReceiptProps(inscription)

    return (
      <ViewFileReceiptDialog
        inscription={inscription}
        load={isDesktop ? loadInscriptions : miniLoadInscriptions}
        {...fileReceiptProps}
      />
    )
  }

  const handleOpenWarning = (handleOpenDialog) => {
    if (activeEdit && saveChangesState.length > 0) {
      setSelectOpenDialog(() => handleOpenDialog)
      setOpenWarningStateDialog(true)
    } else {
      handleOpenDialog()
      setActiveEdit(false)
    }
  }

  const hasInscriptionWithoutNumber =
    inscriptionSelected &&
    inscriptionSelected.state === EVENT_INSCRIPTION_STATES.ACCEPTED &&
    !inscriptionSelected.number

  const hasInscriptionSearch =
    !!searchInputText ||
    (isInscriptionsPage
      ? selectedFilter.state
      : selectedFilter.withNumber || selectedFilter.withoutNumber)

  return (
    <div>
      <div className={classes.listContainer}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th
                onMouseOut={clearnextOrderBy}
                onMouseOver={() => handleChangeNextOrderBy(INSCRIPTION_ORDER_BY.NUMBER)}
                className={clsx(!hasFilterActiveLoading && classes.headerCol)}
                onClick={() => handleChangeOrder(INSCRIPTION_ORDER_BY.NUMBER)}>
                <div className={classes.colContainer}>
                  <Typography
                    color='primary'
                    className={clsx(
                      selectedFilter.orderBy === INSCRIPTION_ORDER_BY.NUMBER && classes.option
                    )}>
                    N°
                  </Typography>
                  {filterPending[INSCRIPTION_ORDER_BY.NUMBER] && (
                    <CircularProgress color='primary' size={16} className={classes.filterLoading} />
                  )}

                  {renderOrderDirectionArrow(
                    selectedFilter.orderBy === INSCRIPTION_ORDER_BY.NUMBER
                      ? selectedFilter.orderDirection
                      : ORDER_DIRECTIONS.DESC,
                    INSCRIPTION_ORDER_BY.NUMBER
                  )}
                </div>
              </th>
              <th
                onMouseOut={clearnextOrderBy}
                onMouseOver={() => handleChangeNextOrderBy(INSCRIPTION_ORDER_BY.LAST_NAME)}
                className={clsx(!hasFilterActiveLoading && classes.headerCol)}
                onClick={() => handleChangeOrder(INSCRIPTION_ORDER_BY.LAST_NAME)}>
                <div className={classes.colContainer}>
                  <Typography
                    color='primary'
                    className={clsx(
                      selectedFilter.orderBy === INSCRIPTION_ORDER_BY.LAST_NAME && classes.option
                    )}>
                    Apellido y nombre
                  </Typography>

                  {filterPending[INSCRIPTION_ORDER_BY.LAST_NAME] && (
                    <CircularProgress color='primary' size={16} className={classes.filterLoading} />
                  )}

                  {renderOrderDirectionArrow(
                    selectedFilter.orderBy === INSCRIPTION_ORDER_BY.LAST_NAME
                      ? selectedFilter.orderDirection
                      : ORDER_DIRECTIONS.DESC,
                    INSCRIPTION_ORDER_BY.LAST_NAME
                  )}
                </div>
              </th>
              <th
                onMouseOut={clearnextOrderBy}
                onMouseOver={() => handleChangeNextOrderBy(INSCRIPTION_ORDER_BY.ID_NUMBER)}
                className={clsx(!hasFilterActiveLoading && classes.headerCol)}
                onClick={() => handleChangeOrder(INSCRIPTION_ORDER_BY.ID_NUMBER)}>
                <div className={classes.colContainer}>
                  <Typography
                    color='primary'
                    className={clsx(
                      selectedFilter.orderBy === INSCRIPTION_ORDER_BY.ID_NUMBER && classes.option
                    )}>
                    D.N.I&nbsp;
                  </Typography>

                  {filterPending[INSCRIPTION_ORDER_BY.ID_NUMBER] && (
                    <CircularProgress color='primary' size={16} className={classes.filterLoading} />
                  )}

                  {renderOrderDirectionArrow(
                    selectedFilter.orderBy === INSCRIPTION_ORDER_BY.ID_NUMBER
                      ? selectedFilter.orderDirection
                      : ORDER_DIRECTIONS.DESC,
                    INSCRIPTION_ORDER_BY.ID_NUMBER
                  )}
                </div>
              </th>
              <th
                onMouseOut={clearnextOrderBy}
                onMouseOver={() => handleChangeNextOrderBy(INSCRIPTION_ORDER_BY.DISTANCE_NAME)}
                className={clsx(!hasFilterActiveLoading && classes.headerCol)}
                onClick={() => handleChangeOrder(INSCRIPTION_ORDER_BY.DISTANCE_NAME)}>
                <div className={classes.colContainer}>
                  <Typography
                    color='primary'
                    className={clsx(
                      selectedFilter.orderBy === INSCRIPTION_ORDER_BY.DISTANCE_NAME &&
                        classes.option
                    )}>
                    Distancia&nbsp;
                  </Typography>

                  {filterPending[INSCRIPTION_ORDER_BY.DISTANCE_NAME] && (
                    <CircularProgress color='primary' size={16} className={classes.filterLoading} />
                  )}

                  {renderOrderDirectionArrow(
                    selectedFilter.orderBy === INSCRIPTION_ORDER_BY.DISTANCE_NAME
                      ? selectedFilter.orderDirection
                      : ORDER_DIRECTIONS.DESC,
                    INSCRIPTION_ORDER_BY.DISTANCE_NAME
                  )}
                </div>
              </th>
              <th
                onMouseOut={clearnextOrderBy}
                onMouseOver={() =>
                  handleChangeNextOrderBy(INSCRIPTION_ORDER_BY.CATEGORY_SHORT_NAME)
                }
                className={clsx(!hasFilterActiveLoading && classes.headerCol)}
                onClick={() => handleChangeOrder(INSCRIPTION_ORDER_BY.CATEGORY_SHORT_NAME)}>
                <div className={classes.colContainer}>
                  <Typography
                    color='primary'
                    className={clsx(
                      selectedFilter.orderBy === INSCRIPTION_ORDER_BY.CATEGORY_SHORT_NAME &&
                        classes.option
                    )}>
                    Categoría&nbsp;
                  </Typography>

                  {filterPending[INSCRIPTION_ORDER_BY.CATEGORY_SHORT_NAME] && (
                    <CircularProgress color='primary' size={16} className={classes.filterLoading} />
                  )}

                  {renderOrderDirectionArrow(
                    selectedFilter.orderBy === INSCRIPTION_ORDER_BY.CATEGORY_SHORT_NAME
                      ? selectedFilter.orderDirection
                      : ORDER_DIRECTIONS.DESC,
                    INSCRIPTION_ORDER_BY.CATEGORY_SHORT_NAME
                  )}
                </div>
              </th>
              <th>
                Estado
                {canEditStateInscription && (
                  <IconButton color='primary' onClick={handleChangeCanEdit}>
                    <EditOutlinedIcon />
                  </IconButton>
                )}
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!inscriptions.length && (
              <tr>
                <td colSpan={6}>
                  {hasInscriptionSearch && (
                    <div className={classes.unResultsContainer}>
                      <Typography color='primary' variant='h6' align='center'>
                        No hay resultados para la búsqueda realizada.
                      </Typography>
                    </div>
                  )}
                </td>
              </tr>
            )}

            {inscriptions.map((inscription) => (
              <tr key={inscription.id}>
                <td>{inscription.number ? inscription.number.number : '-'}</td>
                <td>
                  {inscription.lastName} {inscription.firstName}
                </td>
                <td>{inscription.idNumber}</td>
                <td>{inscription.distance.name}</td>
                <td>{inscription.category.shortName}</td>
                {!activeEdit ? (
                  <td className={classes.altData}>
                    <div className={classes.inscriptionStatusContainer}>
                      {getStatusInscription(inscription.state).label}
                    </div>
                  </td>
                ) : (
                  <td className={classes.altData}>
                    <StyledSelect
                      defaultValue={getStatusInscription(inscription.state)}
                      isDisabled={updateInscriptionStatesPending}
                      defaultOptions={observerOptions}
                      colorOptions='primary'
                      filterOption={(option) =>
                        !option.data.optionHidden &&
                        option.value !== (inscription.newState ?? inscription.state)
                      }
                      isSearchable={false}
                      className={classes.select}
                      onChange={(e) => handleChangeSaveState(e, inscription)}
                    />
                  </td>
                )}

                {isInscriptionsPage && (
                  <td className={classes.altData}>
                    {!!inscription.paymentType &&
                      (inscription.paymentType !== PAYMENT_TYPES.MercadoPago ? (
                        renderFileViewerReceipt(inscription)
                      ) : (
                        <MercadoPagoInfoDialog inscription={inscription} />
                      ))}
                  </td>
                )}

                {!isInscriptionsPage && (
                  <td className={classes.altData}>
                    <AssignNumberRow
                      inscription={inscription}
                      currentState={currentInscriptionState}
                      selectedFilter={selectedFilter}
                      searchInputText={searchInputText}
                      currentPageSize={currentPageSize}
                    />
                  </td>
                )}

                {isInscriptionsPage && (
                  <td className={classes.altData}>
                    {!inscription.hasWhatsApp ? (
                      <InfoInscriptionDialog field={inscription} />
                    ) : (
                      <Button
                        color='primary'
                        variant='contained'
                        href={`https://wa.me/${inscription.phone}?text=Desde%20la%20organización%20${event.organization.name}%20nos%20comunicamos%20por%20el%20evento%20${event.name}%20`}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={classes.actionButton}>
                        <WhatsAppIcon />
                      </Button>
                    )}
                  </td>
                )}

                {isInscriptionsPage && (
                  <td className={classes.altData}>
                    <Button
                      color='primary'
                      variant='contained'
                      href={`mailto:${inscription.eMail}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={classes.actionButton}>
                      <MailOutlineIcon />
                    </Button>
                  </td>
                )}
                {canEditInscription && (
                  <td className={classes.altData}>
                    <EditInscriptionFormDialog
                      title={event.name}
                      load={(page) => {
                        dispatch(
                          loadListInscriptions(
                            id,
                            searchInputText,
                            page || listInscriptionPageCurrentPage,
                            currentInscriptionState,
                            selectedFilter.saleOrderState,
                            PAGE_SIZE,
                            selectedFilter.orderBy,
                            selectedFilter.orderDirection
                          )
                        )

                        dispatch(loadBibNumbers(id))
                        dispatch(loadListInscriptionSearchMetadata(id, searchInputText))
                      }}
                      inscription={inscription}
                      handleOpen={handleOpenWarning}
                    />
                  </td>
                )}
                <td className={classes.altData}>
                  <Button
                    color={inscription.accreditedAt ? 'secondary' : 'primary'}
                    variant='contained'
                    disabled={!inscription.number || accreditPending}
                    className={classes.actionButton}
                    onClick={async () => await tryAccreditInscription(inscription)}>
                    {!accreditPending || !inscription.number ? (
                      <CheckIcon />
                    ) : (
                      <CircularProgress size={16} color='primary' />
                    )}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {activeEdit && (
          <div className={classes.btnSaveContainer}>
            <Button
              color='primary'
              variant='contained'
              onClick={handleOpenDialog}
              className={classes.saveButton}
              disabled={updateInscriptionStatesPending}
              endIcon={
                updateInscriptionStatesPending && <CircularProgress size={16} color='primary' />
              }>
              Guardar
            </Button>
            <ChangeStateDialog
              open={openChangeStateDialog}
              onClose={() => setOpenChangeStateDialog(false)}
              data={saveChangesState}
              onCancel={handleSaveStateCancel}
              handleUpdateState={handleUpdateState}
            />
          </div>
        )}
        <WarningStateDialog
          open={openWarningStateDialog}
          onClose={() => setOpenWarningStateDialog(false)}
          onAccepted={handleAccepted(selectOpenDialog)}
        />
      </div>

      <div className={classes.root}>
        {!!inscriptions.length && (
          <div className={classes.paginationContainer}>
            <Pagination
              showFirstButton
              showLastButton
              count={listInscriptionPageCount}
              page={listInscriptionPageCurrentPage}
              onChange={(e, value) =>
                handleChange(async () => {
                  await dispatch(
                    loadListInscriptions(
                      event.id,
                      searchInputText,
                      value,
                      currentInscriptionState,
                      selectedFilter.saleOrderState,
                      PAGE_SIZE,
                      selectedFilter.orderBy,
                      selectedFilter.orderDirection
                    )
                  )
                }, value)
              }
              variant='outlined'
              shape='rounded'
              color='primary'
            />
          </div>
        )}
      </div>

      <AccreditDialog
        open={openAccreditDialog}
        onClose={() => setOpenAccreditDialog(false)}
        handleOpenAccreditTicket={() => {
          setOpenAccreditDialog(false)
          setOpenAccreditTicketDialog(true)
        }}
        loadInscriptions={loadInscriptions}
        inscription={inscriptionData}
      />
      <AccreditTicketDialog
        open={openAccreditTicketDialog}
        onClose={() => setOpenAccreditTicketDialog(false)}
        fileUrl={fileUrl}
      />
      {!isDesktop && (
        <div
          className={clsx(
            classes.inscriptionsContainer,
            numberFilterOnlyApply && !isDesktop && 'alt'
          )}>
          {!isInscriptionsPage && !inscriptionSelected && !!inscriptions.length && (
            <div className={classes.inscriptionInfo}>
              <Typography color='primary' variant='h6'>
                Hacé click en el deportista para seleccionarlo.
              </Typography>
            </div>
          )}

          {!inscriptions.length && !listInscriptionPending && hasInscriptionSearch && (
            <div className={classes.emptyMessageContainer}>
              <Typography variant='h6' color='primary'>
                No hay resultados para la búsqueda realizada.
              </Typography>
            </div>
          )}

          {!!inscriptions.length && (
            <InfiniteScroll
              dataLength={inscriptions.length}
              hasMore={!hasInscriptionWithoutNumber && !listInscriptionPageLastPage}
              style={{ overflow: 'inherit' }}
              next={() =>
                dispatch(
                  loadListInscriptions(
                    id,
                    searchInputText,
                    listInscriptionPageCurrentPage + 1,
                    currentInscriptionState,
                    selectedFilter.saleOrderState,
                    MIN_PAGE_SIZE,
                    selectedFilter.orderBy,
                    selectedFilter.orderDirection,
                    'accumulator'
                  )
                )
              }>
              {inscriptions
                .filter(
                  (x) =>
                    !inscriptionSelected ||
                    !!inscriptionSelected.number ||
                    inscriptionSelected.id === x.id
                )
                .map((x, index) => (
                  <InscriptionCard
                    inscription={x}
                    key={x.id}
                    onSelected={handleSelected}
                    canClicked
                    cardSelected={inscriptionSelected?.id === x.id}
                    paymentComponent={
                      !!x.paymentType &&
                      (x.paymentType !== PAYMENT_TYPES.MercadoPago ? (
                        renderFileViewerReceipt(x)
                      ) : (
                        <MercadoPagoInfoDialog inscription={x} />
                      ))
                    }
                    editProps={{
                      canEditInscription,
                      load: miniLoadInscriptions,
                      handleOpenWarning
                    }}
                  />
                ))}
            </InfiniteScroll>
          )}

          {listInscriptionPending && (
            <div className={classes.containerLoader}>
              <CircularProgress size={40} color='primary' />
            </div>
          )}

          {!!inscriptions.length && (
            <div className={classes.buttonsContainer}>
              {!listInscriptionPending &&
                !listInscriptionPageLastPage &&
                !hasInscriptionWithoutNumber && (
                  <div className={classes.buttonContainer}>
                    <Typography
                      color='primary'
                      variant='h6'
                      disabled={listInscriptionPending}
                      align='center'
                      className={clsx(classes.viewMoreButton, listInscriptionPending && 'disabled')}
                      onClick={() => {
                        if (listInscriptionPending) return

                        dispatch(
                          loadListInscriptions(
                            id,
                            searchInputText,
                            listInscriptionPageCurrentPage + 1,
                            currentInscriptionState,
                            selectedFilter.saleOrderState,
                            MIN_PAGE_SIZE,
                            selectedFilter.orderBy,
                            selectedFilter.orderDirection,
                            'accumulator'
                          )
                        )
                      }}>
                      Ver más
                    </Typography>
                  </div>
                )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default RegisteredList
