import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, TextField, Typography } from '@material-ui/core'
import { Done as DoneIcon, Error as ErrorIcon } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import clsx from 'clsx'
import { Formik } from 'formik'
import { SectionLayoutDialog } from 'shared'
import {
  loadBibNumbers,
  loadListInscriptions,
  loadListInscriptionSearchMetadata,
  updateEventInscription
} from 'state/modules/events'
import { STRING_EMPTY } from 'utils/constants'
import * as Yup from 'yup'

import { InscriptionCard } from '../InscriptionCard'

import { useStyles } from './AssignNumberDialog.style'

const generateOptionLabel = (option) =>
  `${option.number}${option.categoryId ? ` - ${option.categoryName}` : STRING_EMPTY}`

const DIALOG_STATES = {
  FORM_STATE: 'formState',
  INFO_STATE: 'infoState'
}

const AssignNumberDialog = ({
  open,
  onClose = () => {},
  inscription,
  searchInputText,
  selectedFilter,
  currentPageSize,
  currentInscriptionState,
  state,
  inscriptionInfo
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { event, bibNumbers, listInscriptionPageCurrentPage } = useSelector((state) => state.events)

  const [dialogState, setDialogState] = useState(DIALOG_STATES.FORM_STATE)
  const [inscriptionState, setInscriptionState] = useState({})

  useEffect(() => {
    if (open) setDialogState(state || DIALOG_STATES.FORM_STATE)
  }, [open])

  useEffect(() => {
    if (inscriptionInfo) setInscriptionState(inscriptionInfo)
  }, [inscriptionInfo])

  const assignNumberToInscription = async ({ numberSelected: number }) => {
    const inscriptionPayload = {
      ...inscription,
      number,
      numberId: number.id
    }

    const updateData = await dispatch(updateEventInscription(inscriptionPayload))
    if (updateData) {
      setInscriptionState(updateData.inscription)
      setDialogState(DIALOG_STATES.INFO_STATE)

      dispatch(
        loadListInscriptions(
          event.id,
          searchInputText,
          updateData.page || listInscriptionPageCurrentPage,
          currentInscriptionState,
          selectedFilter.saleOrderState,
          currentPageSize,
          selectedFilter.orderBy,
          selectedFilter.orderDirection,
          'replace',
          selectedFilter.withNumber,
          selectedFilter.withoutNumber
        )
      )

      dispatch(loadBibNumbers(event.id))

      dispatch(loadListInscriptionSearchMetadata(event.id))
    }
  }

  const assignNumberValidation = Yup.object().shape({
    numberSelected: Yup.mixed().nullable().required('Debe escoger un número para asignar')
  })

  const onInputChange = (setFieldValue) => (e, text) => setFieldValue('numberText', text)

  const numberOptions = bibNumbers
    .map((x) => ({
      ...x,
      alert:
        x.distanceId !== inscription?.distance.id ||
        (x.categoryId && x.categoryId !== inscription?.category.id)
    }))
    .filter((x) => !x.assignedAt)
    .sort(
      (x, y) =>
        x.alert - y.alert ||
        x.number - y.number ||
        generateOptionLabel(x).localeCompare(generateOptionLabel(y))
    )

  const values = {
    numberSelected: Array.isArray(numberOptions) ? numberOptions[0] : null,
    numberText: STRING_EMPTY
  }

  const renderOption = (option) => (
    <Typography className={clsx(classes.optionLabel, option.alert && 'alert')}>
      {generateOptionLabel(option)}
    </Typography>
  )

  return (
    <SectionLayoutDialog
      open={open}
      onClose={onClose}
      title={dialogState === DIALOG_STATES.FORM_STATE ? 'Asignar número' : 'Asignación exitosa'}>
      {dialogState === DIALOG_STATES.FORM_STATE ? (
        <div className={classes.inscriptionContainer}>
          <InscriptionCard inscription={inscription} readOnly cardSelected />
          <Formik
            enableReinitialize
            initialValues={values}
            onSubmit={assignNumberToInscription}
            validationSchema={assignNumberValidation}>
            {({
              values,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              isValid,
              touched,
              errors
            }) => (
              <form className={classes.form} onSubmit={handleSubmit}>
                <div className={classes.formGroup}>
                  <Autocomplete
                    id='number-autocomplete'
                    options={
                      !values.numberSelected
                        ? numberOptions.filter((x) =>
                            generateOptionLabel(x)
                              .toLowerCase()
                              .trim()
                              .includes(values.numberText.toLowerCase().trim())
                          )
                        : numberOptions
                    }
                    getOptionLabel={generateOptionLabel}
                    selectOnFocus
                    onInputChange={onInputChange(setFieldValue)}
                    onChange={(event, optionSelected) =>
                      setFieldValue('numberSelected', optionSelected)
                    }
                    filterOptions={(x) => x}
                    className={classes.combo}
                    value={values.numberSelected}
                    loadingText={<Typography align='center'> Cargando...</Typography>}
                    noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                    clearText='Quitar todo'
                    openText='Abrir desplegable'
                    closeText='Cerrar desplegable'
                    classes={{
                      option: classes.option
                    }}
                    disableClearable
                    renderOption={renderOption}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={clsx(classes.input, classes.comboInput)}
                        name='numberSelected'
                        label='Asignar número al deportista'
                        variant='outlined'
                        error={touched.numberSelected && Boolean(errors.numberSelected)}
                        helperText={errors.numberSelected}
                        onBlur={handleBlur}
                      />
                    )}
                  />

                  {values.numberSelected && values.numberSelected.alert && (
                    <div className={classes.optionAlert}>
                      <ErrorIcon color='error' />
                      <Typography color='error'>
                        El número que elegiste no corresponde a la distancia/categoría del
                        deportista.
                      </Typography>
                    </div>
                  )}
                </div>
                <Button
                  variant='contained'
                  color='secondary'
                  endIcon={isSubmitting && <CircularProgress size={16} color='primary' />}
                  disabled={!isValid || isSubmitting}
                  className={classes.saveButton}
                  type='submit'>
                  Asignar número
                </Button>
              </form>
            )}
          </Formik>
        </div>
      ) : (
        <div className={classes.inscriptionContainer}>
          <div className={classes.iconContainer}>
            <DoneIcon color='primary' className={classes.icon} />
          </div>

          <div className={classes.inscriptionInfo}>
            <Typography className={classes.number} color='primary' align='center'>
              N° {inscriptionState.number.number}
            </Typography>

            <Typography className={classes.fullName} color='primary' align='center'>
              {inscriptionState.lastName} {inscriptionState.firstName}
            </Typography>
            <Typography className={classes.distanceAndCategory} color='primary' align='center'>
              {inscriptionState.distance.name} {inscriptionState.category.name}
            </Typography>
          </div>
        </div>
      )}
    </SectionLayoutDialog>
  )
}

export default AssignNumberDialog
