import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '400px !important'
  },
  fileViewerContainer: {
    width: '100%',
    position: 'relative'
  },

  fileViewer: {
    height: 'auto',

    '& > div > div > div:nth-of-type(1)': {
      position: 'absolute',
      top: 'auto',
      left: 20,
      bottom: -50,
      boxShadow: 'none',
      backgroundColor: 'transparent',

      '& > button': {
        boxShadow: 'none',
        width: 40,
        height: 40,
        backgroundColor: 'transparent',

        '& g': {
          fill: theme.palette.primary.main
        }
      }
    },

    '& #pdf-zoom-reset, & #pdf-toggle-pagination, & #pdf-download, & #pdf-pagination': {
      display: 'none'
    }
  },
  printButton: {
    position: 'absolute',
    padding: '5px 6px',
    minWidth: 10,
    bottom: -40,
    right: 40
  },
  content: {
    backgroundColor: '#eee'
  }
}))
