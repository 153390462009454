import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  section: {
    transition: 'all .3s',
    padding: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      borderRadius: 10,
      backgroundColor: theme.utils.rgba(theme.palette.alt.main, 0.6),
      width: '100%',
      margin: '0 auto'
    }
  },

  container: {
    width: '100%',

    [theme.breakpoints.up(1099)]: {
      width: 1115,
      margin: '0 auto'
    }
  },
  actions: {
    display: 'flex',
    cursor: 'pointer',
    userSelect: 'none'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  textField: {
    borderRadius: 4,

    '& label': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      fontWeight: 500
    },

    '& input': {
      zIndex: 1,
      fontWeight: 500
    },

    '& fieldset': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      borderRadius: 4
    }
  },
  form: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: 350
    }
  },
  altForm: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: 350
    }
  },
  saveButton: {
    alignSelf: 'center',
    width: 250,
    height: 48,
    borderRadius: 10,

    '& span': {
      fontSize: '1.125rem'
    }
  },

  combo: {
    width: '100%',

    '& > div > label': {
      transform: 'translate(14px, 10px) scale(1)'
    },
    '& > div > div': {
      paddingBottom: `0 !important`,
      paddingTop: `0 !important`,
      paddingLeft: `${theme.spacing()} !important`,
      paddingRight: `${theme.spacing()} !important`
    },
    '& > div > div > div': {
      zIndex: 2
    },
    '& svg': {
      color: theme.palette.primary.main
    },

    [theme.breakpoints.up('md')]: {
      width: 350
    }
  },
  input: {
    flexGrow: 1,
    '& fieldset': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      minHeight: 45
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing()
    },

    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.contrastText,
      paddingRight: theme.spacing()
    },
    '& input': {
      zIndex: 1,
      fontWeight: 500
    },
    '& > p': {
      color: theme.palette.secondary.contrastText
    },

    '&.disabled': {
      '& svg': {
        color: theme.palette.disabled.main
      }
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  options: {
    display: 'flex',
    backgroundColor: theme.palette.primary.contrastText,
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    border: `1px solid ${theme.palette.alt.light}`,
    padding: theme.spacing(2),
    borderRadius: 10,
    maxHeight: 318,
    overflowY: 'auto'
  },
  optionSelectedHelperText: {
    display: 'inline-block',
    fontWeight: 500,
    margin: theme.spacing(2, 0)
  },
  numberOption: {
    flexShrink: 0,
    transition: 'all .3s',
    backgroundColor: theme.utils.rgba(theme.palette.alt.light, 0.8),
    padding: theme.spacing(1.5),
    borderRadius: 10,
    color: theme.palette.primary.contrastText,

    fontSize: '.875rem',
    width: 'calc( 50% - 8px )',
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    userSelect: 'none',
    height: 44,

    '&:hover': {
      backgroundColor: theme.palette.alt.light
    },

    '&.disabled': {
      backgroundColor: theme.palette.alt.light,
      cursor: 'default',

      '&:hover': {
        backgroundColor: theme.palette.alt.light
      }
    },

    '&.assigned': {
      border: `1px solid ${theme.utils.rgba(theme.palette.option.main, 0.8)}`,
      color: theme.palette.option.main,
      backgroundColor: theme.palette.primary.contrastText,
      cursor: 'default'
    },

    '&.selected': {
      backgroundColor: theme.utils.rgba(theme.palette.option.main, 0.8),

      '&:hover': {
        backgroundColor: theme.palette.option.main
      }
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 200
    }
  },

  altButton: {
    backgroundColor: theme.palette.primary.contrastText,
    alignSelf: 'center',
    width: 250,
    height: 48,
    borderRadius: 10,

    '& span': {
      fontSize: '1.125rem'
    }
  },
  addLink: {
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '.875rem',
    userSelect: 'none',
    marginTop: theme.spacing(2),
    alignSelf: 'flex-start'
  },

  link: {
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '.875rem',
    userSelect: 'none',

    '&.disabled': {
      cursor: 'default',
      color: theme.palette.disabled.main
    }
  },
  underlineTitle: {
    textDecoration: 'underline'
  },
  emptyMessage: {
    color: theme.palette.disabled.main,
    fontSize: '.875rem',

    [theme.breakpoints.up('md')]: {
      textAlign: 'center'
    }
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      gap: theme.spacing(4)
    }
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  selectedFilter: {
    backgroundColor: theme.utils.rgba(theme.palette.option.main, 0.8),
    height: 23,
    width: 23,
    flexShrink: 0
  },
  unSelectedFilter: {
    backgroundColor: theme.utils.rgba(theme.palette.alt.light, 0.8),
    height: 23,
    flexShrink: 0,
    width: 23
  },
  useFilter: {
    border: `1px solid ${theme.utils.rgba(theme.palette.option.main, 0.8)}`,
    backgroundColor: theme.palette.primary.contrastText,
    height: 23,
    width: 23,
    flexShrink: 0
  }
}))
