import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Button, CircularProgress, TextField, Typography } from '@material-ui/core'
import { Error as ErrorIcon } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import clsx from 'clsx'
import { Formik } from 'formik'
import { updateEventInscription } from 'state/modules/events'
import { STRING_EMPTY } from 'utils/constants'
import * as Yup from 'yup'

import { useStyles } from './AssignNumberForm.style'

const generateOptionLabel = (option) =>
  `${option.number}${option.categoryId ? ` - ${option.categoryName}` : STRING_EMPTY}`

const AssignNumberForm = ({ inscription, onSuccess = () => {} }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { slugUrlOrId } = useParams()

  const { bibNumbers } = useSelector((state) => state.events)

  const assignNumberValidation = Yup.object().shape({
    numberSelected: Yup.mixed().nullable().required('Debe escoger un número para asignar')
  })

  const onInputChange = (setFieldValue) => (e, text) => setFieldValue('numberText', text)

  const assignNumberToInscription = async ({ numberSelected: number }) => {
    const inscriptionPayload = {
      ...inscription,
      number,
      numberId: number.id
    }

    const updateData = await dispatch(updateEventInscription(inscriptionPayload))

    if (updateData) onSuccess(updateData.inscription)
  }

  const numberOptions = bibNumbers
    .map((x) => ({
      ...x,
      alert:
        x.distanceId !== inscription?.distance.id ||
        (x.categoryId && x.categoryId !== inscription?.category.id)
    }))
    .filter((x) => !x.assignedAt)
    .sort(
      (x, y) =>
        x.alert - y.alert ||
        x.number - y.number ||
        generateOptionLabel(x).localeCompare(generateOptionLabel(y))
    )

  const values = {
    numberSelected: Array.isArray(numberOptions) ? numberOptions[0] : null,
    numberText: STRING_EMPTY
  }

  const renderOption = (option) => (
    <Typography className={clsx(classes.optionLabel, option.alert && 'alert')}>
      {generateOptionLabel(option)}
    </Typography>
  )

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      onSubmit={assignNumberToInscription}
      validationSchema={assignNumberValidation}>
      {({
        values,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        isValid,
        errors,
        touched
      }) => (
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.formGroup}>
            <Autocomplete
              id='number-autocomplete'
              options={
                !values.numberSelected
                  ? numberOptions.filter((x) =>
                      generateOptionLabel(x)
                        .toLowerCase()
                        .trim()
                        .includes(values.numberText.toLowerCase().trim())
                    )
                  : numberOptions
              }
              getOptionLabel={generateOptionLabel}
              selectOnFocus
              onInputChange={onInputChange(setFieldValue)}
              onChange={(event, optionSelected) => setFieldValue('numberSelected', optionSelected)}
              filterOptions={(x) => x}
              className={classes.combo}
              value={values.numberSelected}
              loadingText={<Typography align='center'> Cargando...</Typography>}
              noOptionsText={<Typography align='center'> Sin opciones</Typography>}
              clearText='Quitar todo'
              openText='Abrir desplegable'
              disableClearable
              closeText='Cerrar desplegable'
              classes={{
                option: classes.option
              }}
              renderOption={renderOption}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.input}
                  name='numberSelected'
                  label='Asignar número al deportista'
                  variant='outlined'
                  error={touched.numberSelected && Boolean(errors.numberSelected)}
                  helperText={errors.numberSelected}
                  onBlur={handleBlur}
                />
              )}
            />

            {values.numberSelected && values.numberSelected.alert && (
              <div className={classes.optionAlert}>
                <ErrorIcon color='error' />
                <Typography color='error'>
                  El número que elegiste no corresponde a la distancia/categoría del deportista.
                </Typography>
              </div>
            )}
          </div>
          <Button
            variant='contained'
            color='secondary'
            endIcon={isSubmitting && <CircularProgress size={16} color='primary' />}
            disabled={!isValid || isSubmitting}
            className={classes.saveButton}
            type='submit'>
            Asignar número
          </Button>

          {values.numberSelected && values.numberSelected.alert && (
            <Link to={`/events/${slugUrlOrId}/edit-number`} className={classes.addNumberLink}>
              +Añadir números
            </Link>
          )}
        </form>
      )}
    </Formik>
  )
}

export default AssignNumberForm
