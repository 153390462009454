import React, { useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import { Phone as PhoneIcon } from '@material-ui/icons'
import { LayoutDialog } from 'shared'

import { useStyles } from './InfoInscriptionDialog.style'

const InfoInscriptionDialog = ({ field, onCardSelected = () => {} }) => {
  const classes = useStyles()

  const [openDialog, setOpenDialog] = useState(false)

  return (
    <>
      <Button
        color='primary'
        variant='contained'
        onClick={(e) => {
          setOpenDialog(true)
          onCardSelected()
        }}
        className={classes.button}>
        <PhoneIcon />
      </Button>
      <LayoutDialog
        title='TELÉFONO DE CONTACTO'
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        className={classes.dialog}>
        <div className={classes.bodyContainer}>
          <Typography color='primary' variant='h6'>
            Apellido y Nombre
          </Typography>
          <Typography variant='subtitle1' gutterBottom>
            {`${field.lastName}, ${field.firstName}`}
          </Typography>
          <Typography color='primary' variant='h6'>
            Teléfono
          </Typography>
          <Typography variant='subtitle1' gutterBottom>
            {field.phone}
          </Typography>
        </div>
      </LayoutDialog>
    </>
  )
}

export default InfoInscriptionDialog
