import { makeStyles } from '@material-ui/styles'
import homeHeaderBg from 'assets/img/home-header-bg.jpg'

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${homeHeaderBg})`,
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    height: 500,
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      height: 600
    }
  },
  container: {
    padding: theme.spacing(2),
    margin: '0 auto',
    width: '100%',
    height: 420,
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      marginTop: theme.spacing(2),

      [theme.breakpoints.up('lg')]: {
        marginTop: 0
      }
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
      width: 1280,
      height: 350
    }
  },
  titleContainer: {
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
    alignSelf: 'center',
    animation: `$titleAnimation 2000ms 1500ms forwards`,
    [theme.breakpoints.up('md')]: {
      padding: 0
    }
  },
  title: {
    fontSize: '2em',
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: '3em'
    }
  },
  '@keyframes titleAnimation': {
    '100%': {
      fontSize: 0,
      padding: 0
    }
  },
  searchContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  tabsContainer: {},
  tabContent: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      flexGrow: 1
    }
  },
  form: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      '&.secondary': {
        backgroundColor: theme.palette.primary.contrastText
      }
    }
  },
  inputField: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.125rem',
    color: theme.palette.primary.main,
    border: 'none',
    backgroundColor: '#ffffff',
    padding: theme.spacing(),
    marginBottom: theme.spacing(),
    '&:focus': {
      outline: 'none'
    },
    '&::placeholder': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      padding: theme.spacing(2),
      marginBottom: 0
    }
  },
  inputFieldMini: {
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: 'transparent',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.up('md')]: {
      color: theme.palette.primary.contrastText,
      fontSize: '.875rem',
      padding: theme.spacing(2),
      margin: 0,
      borderRight: 'none',
      '&::placeholder': {
        color: theme.palette.primary.contrastText
      },

      '&.secondary': {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRight: 'none !important',
        color: theme.palette.primary.main,
        '&::placeholder': {
          color: theme.palette.primary.main
        }
      }
    }
  },
  searchField: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 1
    }
  },
  calendarField: {
    [theme.breakpoints.up('md')]: {
      width: 140
    }
  },
  locationField: {
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  sportField: {
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  searchButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    },
    margin: theme.spacing(2.8, 0),
    '& svg': {
      width: 25,
      height: 25
    },
    alignSelf: 'flex-end',
    width: theme.spacing(7),
    height: theme.spacing(7),
    [theme.breakpoints.up('md')]: {
      height: 42,
      margin: 0,
      alignSelf: 'stretch',
      '&.secondary': {
        backgroundColor: theme.palette.primary.main,
        '& svg': {
          color: theme.palette.primary.contrastText
        }
      }
    }
  },
  miniWidgetContainer: {
    paddingLeft: theme.spacing(2),
    '&.fullWidth': {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      padding: 0,
      width: '100%'
    }
  },
  miniSearchContainer: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      height: 42,
      width: '100%'
    }
  },
  miniSearchButtonContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  miniSearchButton: {
    padding: 0,
    alignSelf: 'flex-end',
    color: theme.palette.primary.contrastText
  },
  miniSearchButtonActive: {
    color: theme.palette.secondary.main
  },
  menuIcon: {
    width: 30,
    height: 30
  },
  searchPopup: {
    top: '80px !important',
    left: '0 !important',
    maxWidth: 'none',
    width: '100%',
    position: 'relative',
    backgroundColor: '#fff',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    '& input': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#ffffff'
    }
  },
  searchPopupTransparent: {
    backgroundColor: 'transparent'
  },
  searchPopupWhite: {
    backgroundColor: '#ffff'
  },
  formControlLabel: {
    margin: 0,
    paddingLeft: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up('md')]: {
      color: theme.palette.primary.main,
      backgroundColor: '#ffffff',
      borderColor: theme.palette.background.default,
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      '& span': {
        fontSize: '.875rem'
      }
    }
  },
  formControlLabelMini: {
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
      borderColor: theme.palette.secondary.main
    }
  },
  customInputField: {
    [theme.breakpoints.down('md')]: {
      color: theme.palette.primary.main
    },
    '&.secondary': {
      border: 'none',
      color: theme.palette.primary.main
    }
  },

  checkboxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 100,
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      alignItems: 'inherit',
      height: 'auto',
      '&.secondary': {
        border: `1px solid ${theme.palette.primary.main}`,
        borderLeft: 'none'
      }
    },
    '& button': {
      alignSelf: 'center'
    }
  },

  checkbox: {
    color: theme.palette.primary.contrastText,
    '& *': {
      color: theme.palette.primary.contrastText
    },
    [theme.breakpoints.up('md')]: {
      color: theme.palette.primary.main,
      '& *': {
        color: theme.palette.primary.main
      }
    }
  },
  checkboxMini: {
    color: theme.palette.primary.contrastText,
    '& *': {
      color: theme.palette.primary.contrastText
    },
    '& svg': {
      height: 20,
      width: 20
    },
    '&.customCheck': {
      [theme.breakpoints.down('md')]: {
        '& *': {
          color: theme.palette.primary.main
        }
      }
    },
    '&.secondary': {
      [theme.breakpoints.up('md')]: {
        '& *': {
          color: theme.palette.primary.main
        }
      }
    }
  },
  eventSearchForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      justifyContent: 'center'
    }
  },

  eventInput: {
    border: 'none',
    width: '100%',
    height: 25,
    borderRadius: '10px',
    paddingLeft: theme.spacing(2),
    backgroundColor: 'rgba(255, 255, 255, 0.80)',
    fontFamily: theme.typography.fontFamily,
    '&:focus': {
      outline: 'none'
    },
    animation: `$inputFieldMini 2000ms 0ms forwards`,

    [theme.breakpoints.up('md')]: {
      width: 200,
      animation: `$inputFieldFocus 1500ms 150ms forwards`,
      animationPlayState: 'paused',
      '&:focus': {
        animationPlayState: 'running'
      }
    }
  },
  eventSearchButton: {
    color: 'rgba(255, 255, 255, 0.80)',
    margin: theme.spacing(0, 1),
    '& svg': {
      width: 27,
      height: 27
    }
  },

  '@keyframes inputFieldMini': {
    '0%': {
      width: '0%'
    },
    '100%': {
      width: '100%'
    }
  },

  '@keyframes inputFieldFocus': {
    '0%': {
      width: 200
    },
    '100%': {
      width: 300
    }
  },
  closeButton: {
    alignSelf: 'flex-end',
    position: 'absolute',
    right: 8,
    top: 5,
    '& svg': {
      width: 20,
      height: 20
    }
  },
  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(5),
    order: -1,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5)
    },
    [theme.breakpoints.up('lg')]: {
      order: 0
    }
  },
  organiceButton: {
    alignSelf: 'flex-start',
    borderRadius: 10,
    height: 25,
    width: 140,
    '& span': {
      color: theme.palette.primary.main,
      fontSize: '1rem',
      fontWeight: 600
    }
  },
  timingButton: {
    borderRadius: 10,
    height: 25,
    width: 140,
    backgroundColor: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.main
    },

    '& span': {
      fontSize: '1rem',
      fontWeight: 600
    }
  },
  createEventButton: {
    alignSelf: 'center',
    borderRadius: 10,
    height: 25,
    marginTop: theme.spacing(12),
    width: 160,
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
      marginBottom: theme.spacing(5),
      alignSelf: 'flex-start'
    },
    '& span': {
      color: theme.palette.primary.main,
      fontSize: '1rem',
      fontWeight: 600
    }
  }
}))

export default useStyles
