import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 600,
      maxWidth: 600
    }
  },
  fileViewer: {
    height: 'auto',
    backgroundColor: 'transparent !important',
    '& > div > div > div:nth-of-type(1)': {
      display: 'none'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5)
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  subtitle: {
    fontWeight: 600
  },
  fullName: {
    fontWeight: 500
  },
  bibNumber: {
    fontWeight: 600,
    fontSize: '1.25rem'
  },
  button: {
    width: 210,
    borderRadius: 10,
    boxShadow: 'none'
  }
}))
