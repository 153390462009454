import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginBotom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
    transition: 'all .3s',

    '&.selected': {
      backgroundColor: '#DAD9F3'
    },
    '&.clicked': {
      cursor: 'pointer'
    }
  },
  infoText: {
    fontWeight: 500,
    color: theme.palette.disabled.main,

    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 500
    }
  },
  stateInfo: {
    display: 'inline-flex',
    gap: theme.spacing(),
    fontSize: '1rem'
  },
  actionButtons: {
    alignSelf: 'center',
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2)
  },

  actionButton: {
    minWidth: 40,
    padding: '6px',

    '&:disabled': {
      color: theme.palette.primary.contrastText
    }
  },
  state: {
    display: 'flex',
    gap: theme.spacing()
  }
}))
