import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.contrastText,
    position: 'fixed',
    top: 80,
    right: 0,
    zIndex: 1100,
    left: 0
  },

  headerContainer: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#ffff',
    flexDirection: 'column',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    marginBottom: theme.spacing(),
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      flexDirection: 'row',
      minHeight: theme.spacing(15),
      padding: theme.spacing(0, 10)
    }
  },
  eventName: {
    textAlign: 'center',
    padding: theme.spacing(2, 4),
    fontSize: '1rem',
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem'
    }
  },

  mainContainer: {
    backgroundColor: theme.palette.tooltip.main,
    padding: theme.spacing(),
    paddingRight: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'space-between'
  },

  filter: {
    fontSize: '.875rem',
    fontWeight: 500
  },
  filterLink: {
    fontSize: '.875rem',
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}))
