import React from 'react'
import { Button } from '@material-ui/core'
import { Print as PrintIcon } from '@material-ui/icons'
import { FileViewerContent, SectionLayoutDialog } from 'shared'

import { useStyles } from './AccreditTicketDialog.style'

const AccreditTicketDialog = ({ open, onClose, fileUrl }) => {
  const classes = useStyles()

  const printPDF = () => {
    const proxy = window.open(fileUrl, '_blank')

    if (proxy) {
      proxy.onload = () => {
        proxy.print()
      }
    }
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      title='TICKET'
      mainContainerClassName={classes.content}>
      <div className={classes.fileViewerContainer}>
        {fileUrl && (
          <FileViewerContent
            fileUrl={fileUrl}
            fileExtension='pdf'
            fileViewerClassName={classes.fileViewer}
          />
        )}
        <Button
          color='primary'
          variant='contained'
          className={classes.printButton}
          onClick={printPDF}>
          <PrintIcon />
        </Button>
      </div>
    </SectionLayoutDialog>
  )
}

export default AccreditTicketDialog
