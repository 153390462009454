import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  statusSection: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
    flexShrink: 0
  },
  numberFilterSection: {
    display: 'flex',
    gap: theme.spacing(5),
    alignItems: 'center',
    flexShrink: 0,
    flexGrow: 2
  },

  filterLabel: {
    alignSelf: 'center'
  },
  filterTitle: {
    fontWeight: 600,
    color: theme.palette.secondary.contrastText,
    fontSize: '.875rem'
  },

  statusEllipse: {
    width: 18,
    height: 18
  },
  stateContainer: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      color: theme.palette.link.main,
      marginLeft: theme.spacing(),
      fontWeight: 600,
      fontSize: '.875rem',
      cursor: 'pointer',
      userSelect: 'none',

      '&.filter': {
        fontWeight: 600,
        color: theme.palette.primary.main,
        textDecoration: 'underline'
      }
    },

    '&.center': {
      alignSelf: 'center'
    }
  },
  stateBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginTop: theme.spacing(),
    borderRadius: 10,
    gap: theme.spacing(2),
    backgroundColor: theme.palette.background.default
  },
  editNumberButton: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      width: 200,
      height: 40,
      borderRadius: 10
    }
  },
  numberSection: {
    justifyContent: 'space-between'
  }
}))
