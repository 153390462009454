import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { IconButton, Typography } from '@material-ui/core'
import { ArrowBack as ArrowBackIcon, ArrowLeft as ArrowLeftIcon } from '@material-ui/icons'
import { SearchWidget } from 'pages/HomePage/components'
import { ROUTES } from 'routes'
import { BibNumberForm, Footer, MainLayout, NavBar, PageContainer } from 'shared'
import { load, loadBibNumbers, loadDistances } from 'state/modules/events'
import { FORM_STATES } from 'utils/constants'

import { useStyles } from './EditBibNumberPage.style'

const EditBibNumberPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { slugUrlOrId } = useParams()

  const [firstLoad, setFirstLoad] = useState(false)

  const {
    pending,
    event,
    error: asyncError,
    distances,
    distancesPending,
    bibNumbersPending
  } = useSelector((state) => state.events)

  const activeLoading =
    (!pending && !event && !asyncError) ||
    pending ||
    distancesPending ||
    (!firstLoad && bibNumbersPending)

  useEffect(() => {
    dispatch(load(slugUrlOrId))
  }, [dispatch])

  useEffect(() => {
    if (event) {
      dispatch(loadDistances(event.id))
      dispatch(loadBibNumbers(event.id))

      setFirstLoad(true)
    }
  }, [event])

  const redirectEventProfile = () => history.push(`${ROUTES.EVENTS.PROFILE}/${slugUrlOrId}`)

  const renderLoading = () => {}

  const renderPage = () => {
    const activeFormDistances = event.activeInscriptionForm?.items.filter((x) =>
      distances?.some((d) => d.id === x.eventDistanceId)
    )

    const itemsGroupBy = Object.groupBy(activeFormDistances ?? [], (item) => item.eventDistanceId)

    const activeDistances = Object.keys(itemsGroupBy).map((x) => ({
      value: x,
      label: activeFormDistances.find((d) => d.eventDistanceId === x).eventDistanceName
    }))

    return (
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <Typography
            color='primary'
            variant='h6'
            className={classes.eventName}
            onClick={redirectEventProfile}>
            {event.name}
          </Typography>
        </div>

        <div className={classes.mainContainer}>
          <div className={classes.assignNumberHeaderContainer}>
            <Link
              to={`/events/${slugUrlOrId}/assign-number`}
              className={classes.returnAssignNumberLink}>
              <ArrowLeftIcon color='primary' /> Volver a pantalla anterior
            </Link>
            <Typography color='primary' variant='h6' className={classes.title} align='center'>
              Editar números
            </Typography>
          </div>

          <BibNumberForm
            distances={activeDistances}
            items={itemsGroupBy}
            mode={FORM_STATES.UPDATE}
          />

          <IconButton
            color='primary'
            className={classes.backButton}
            component={Link}
            to={`/events/${slugUrlOrId}/assign-number`}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
    )
  }

  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />
      <PageContainer altMode>{activeLoading ? renderLoading() : renderPage()}</PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default EditBibNumberPage
