import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Button, CircularProgress, Typography, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import { Formik } from 'formik'
import { ROUTES } from 'routes'
import { CustomTextField, EllipseStatus, FileViewerDialog, SectionLayoutDialog } from 'shared'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'
import { loadEventInscriptionByIdNumberAndCountry, resetValue } from 'state/modules/events'
import { STRING_EMPTY } from 'utils/constants'
import { getCountries } from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'
import * as Yup from 'yup'

import { PaymentMethodDialog } from '../PaymentMethodDialog'
import { UploadReceiptDialog } from '../UploadReceiptDialog'

import { useStyles } from './CheckStateInscriptionDialog.style'

const ARGENTINA_COUNTRY = { label: 'Argentina', value: '01fa2de7-519f-4089-a33c-d48483ea0f20' }

const SALE_ORDER_STATES = {
  PAID: 'Paid',
  CANCELLED: 'Cancelled',
  PENDING: 'Pending',
  EXPIRED: 'Expired'
}

const DIALOG_STATES = {
  SEARCH_INSCRIPTION_DIALOG: {
    value: 'SearchInscription',
    title: 'CONSULTAR ESTADO DE MI INSCRIPCIÓN'
  },
  STATE_INSCRIPTION_DIALOG: {
    value: 'StateInscription',
    title: 'ESTADO DE MI INSCRIPCIÓN'
  }
}

const PAYMENT_TYPES = {
  PaymentLocation: {
    value: 'PaymentLocation',
    label: 'EN EFECTIVO'
  },
  BankAccount: {
    value: 'BankAccount',
    label: 'TRANSFERENCIA'
  },
  MercadoPago: {
    value: 'MercadoPago',
    label: 'Mercado Pago'
  },
  PaymentUrl: {
    value: 'PaymentUrl',
    label: 'Link de pago'
  }
}

const observerOptions = {
  Pending: {
    value: <EllipseStatus status='yellow' title='Preinscripto' />,
    label: 'Preinscripto'
  },
  Accepted: {
    value: <EllipseStatus status='green' title='Inscripto' />,
    label: 'Inscripto',
    key: 'Accepted'
  },
  Cancelled: {
    value: <EllipseStatus status='red' title='Anulado' />,
    label: 'Anulado'
  }
}

const CheckStateInscriptionDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const { event, eventInscription, eventInscriptionError } = useSelector((state) => state.events)
  const dispatch = useDispatch()
  const history = useHistory()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const [stateDialog, setStateDialog] = useState(DIALOG_STATES.SEARCH_INSCRIPTION_DIALOG)
  const [openPaymentMethodDialog, setOpenPaymentMethodDialog] = useState(false)
  const [openUploadReceiptDialog, setOpenUploadReceiptDialog] = useState(false)
  const [openFileViewerDialog, setOpenFileViewerDialog] = useState(false)

  const handleOnClose = () => {
    onClose()
    dispatch(resetValue('eventInscription'))
    setStateDialog(DIALOG_STATES.SEARCH_INSCRIPTION_DIALOG)
  }

  useEffect(() => {
    if (eventInscriptionError) showSnackbarError(eventInscriptionError)

    return () => dispatch(resetValue('eventInscriptionError'))
  }, [eventInscriptionError])

  const renderSearchInscription = () => {
    const values = {
      country: ARGENTINA_COUNTRY,
      idNumber: STRING_EMPTY
    }

    const validationSchema = Yup.object().shape({
      idNumber: Yup.string()
        .required('Debe ingresar el DNI')
        .min(6, 'El DNI debe poseer como mínimo 6 caracteres')
        .max(15, 'El DNI debe poseer como máximo 15 caracteres')
        .matches(/^[a-z0-9]+$/i, 'El DNI no debe tener caracteres especiales ni espacios')
    })

    const searchInscription = async ({ country, idNumber }) => {
      const eventInscription = {
        countryId: country.value,
        countryName: country.label,
        idNumber: idNumber.trim(),
        inscriptionFormId: event.activeInscriptionFormId
      }

      const eventInscriptionSuccess = await dispatch(
        loadEventInscriptionByIdNumberAndCountry(eventInscription)
      )

      if (eventInscriptionSuccess) setStateDialog(DIALOG_STATES.STATE_INSCRIPTION_DIALOG)
    }

    return (
      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={searchInscription}>
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          isValid
        }) => (
          <form className={classes.mainContainer} onSubmit={handleSubmit}>
            <div className={classes.formGroup}>
              <Typography color='primary' variant='h6'>
                País
              </Typography>
              <StyledSelect
                cacheOptions
                defaultOptions
                colorOptions='secondary'
                className={classes.select}
                onChange={(option) => setFieldValue('country', option)}
                loadOptions={getCountries()}
                placeholder='Seleccione una opción'
                maxMenuHeight={185}
                value={values.country}
              />
            </div>
            <div className={classes.formGroup}>
              <Typography className={classes.label} color='primary' variant='h6'>
                Ingresá tu DNI
              </Typography>
              <CustomTextField
                variant='outlined'
                name='idNumber'
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.idNumber && Boolean(errors.idNumber)}
                helperText={errors.idNumber}
                value={values.idNumber}
                className={classes.textField}
                placeholder='Ingresá tu número de DNI sin puntos y espacios'
              />
              {!event.isFree && (
                <Typography color='primary' variant='caption' className={classes.helperText}>
                  *Si realizaste el pago con MP tené en cuenta que puede demorar 1 hora en impactar
                  en el estado de tu inscripción.
                </Typography>
              )}
            </div>
            <Button
              className={classes.button}
              color='primary'
              type='submit'
              variant='contained'
              disabled={!isValid || isSubmitting}
              endIcon={isSubmitting && <CircularProgress color='primary' size={16} />}>
              Consultar
            </Button>
          </form>
        )}
      </Formik>
    )
  }

  const renderStateInscription = () => {
    const {
      id,
      firstName,
      lastName,
      idNumber,
      category,
      distance,
      state,
      paymentType,
      saleOrder,
      ticketTypeId,
      number
    } = eventInscription ?? {}

    const inscriptionState = observerOptions[state]
    const inscriptionApproved = inscriptionState === observerOptions.Accepted
    const hasSaleOrderPending =
      !inscriptionApproved &&
      !saleOrder?.payments.filter((x) => !x.removedAt).at(-1)?.hasReceiptFile

    const inscriptionExpired =
      inscriptionState === observerOptions.Cancelled &&
      saleOrder?.state === SALE_ORDER_STATES.EXPIRED

    const verifyActivePaymentType = (paymentType) => {
      const ticketType = event.activeInscriptionForm.ticketTypes.find(
        (ticket) => ticket.id === ticketTypeId
      )

      if (!ticketType) return

      switch (paymentType) {
        case PAYMENT_TYPES.MercadoPago:
          return event.mercadoPagoEnabled && ticketType.mercadoPagoEnabled
        case PAYMENT_TYPES.BankAccount:
          return event.eventBankAccountsEnabled && ticketType.bankAccountsEnabled
        case PAYMENT_TYPES.PaymentLocation:
          return event.eventPaymentLocationsEnabled && ticketType.paymentLocationsEnabled
        case PAYMENT_TYPES.PaymentUrl:
          return !!ticketType.paymentUrl
        default:
      }
    }

    const handleRedirectInscriptionForm = () =>
      history.push(`${ROUTES.ROOT}events/${event.slugUrl}/inscription/${id}`)

    const renderViewReceiptFile = () => {
      const { payments } = saleOrder
      const lastPayment = payments.filter((x) => !x.removedAt).at(-1)

      if (!lastPayment) return

      return (
        <>
          <Button
            color='primary'
            variant='contained'
            className={classes.uploadReceiptButton}
            onClick={() => setOpenFileViewerDialog(true)}>
            Ver mi comprobante
          </Button>
          {lastPayment.hasReceiptFile && (
            <FileViewerDialog
              title={lastPayment.receiptFile.fileName}
              fileUrl={lastPayment.receiptFileUrl}
              fileExtension={lastPayment.receiptFile.contentType?.split('/')[1]}
              open={openFileViewerDialog}
              onClose={() => setOpenFileViewerDialog(false)}
            />
          )}
        </>
      )
    }

    return (
      <div className={classes.stateContainer}>
        <Typography variant='h6' align='center'>
          {firstName} {lastName} - DNI {idNumber}
        </Typography>
        <div
          className={clsx(classes.stateMainContainer, inscriptionApproved && inscriptionState.key)}>
          {!inscriptionExpired && (
            <>
              <div className={classes.header}>
                <Typography color='primary' variant='h6'>
                  {event.name}
                </Typography>

                {isDesktop && (
                  <>
                    {inscriptionState === observerOptions.Pending &&
                      PAYMENT_TYPES[paymentType] !== PAYMENT_TYPES.MercadoPago &&
                      saleOrder &&
                      verifyActivePaymentType(PAYMENT_TYPES[paymentType]) &&
                      (hasSaleOrderPending ? (
                        <>
                          <Button
                            color='primary'
                            variant='contained'
                            className={classes.uploadReceiptButton}
                            onClick={() => setOpenUploadReceiptDialog(true)}>
                            Subir comprobante
                          </Button>
                          <UploadReceiptDialog
                            open={openUploadReceiptDialog}
                            saleOrder={saleOrder}
                            onClose={() => setOpenUploadReceiptDialog(false)}
                          />
                        </>
                      ) : (
                        renderViewReceiptFile()
                      ))}

                    {inscriptionState === observerOptions.Cancelled && (
                      <Button
                        color='primary'
                        variant='contained'
                        className={classes.retryInscription}
                        onClick={handleRedirectInscriptionForm}>
                        Inscribirme
                      </Button>
                    )}
                  </>
                )}
              </div>
              <Typography className={classes.item}>
                {distance.name} {category.name}
              </Typography>
              <div className={classes.state}>
                <div className={classes.inscriptionState} title={inscriptionState.label}>
                  ESTADO {inscriptionState.value}
                  {isDesktop && inscriptionState.label}
                  {!!paymentType &&
                    observerOptions[state] === observerOptions.Pending &&
                    !hasSaleOrderPending && (
                      <Typography
                        className={classes.saleOrderState}
                        color='primary'
                        variant='caption'>
                        (Pendiente revisión)
                      </Typography>
                    )}
                </div>

                {paymentType &&
                  inscriptionState === observerOptions.Pending &&
                  hasSaleOrderPending && (
                    <>
                      <Typography
                        color='primary'
                        variant='h6'
                        className={classes.paymentMethodLink}
                        onClick={() => setOpenPaymentMethodDialog(true)}>
                        {verifyActivePaymentType(PAYMENT_TYPES[paymentType])
                          ? 'Medio de pago elegido'
                          : 'Seleccionar modalidad de pago.'}
                      </Typography>
                      <PaymentMethodDialog
                        open={openPaymentMethodDialog}
                        activePaymentType={verifyActivePaymentType(PAYMENT_TYPES[paymentType])}
                        onClose={() => setOpenPaymentMethodDialog(false)}
                      />
                    </>
                  )}
              </div>
              {number?.number && (
                <Typography variant='h6'>Número asignado&nbsp; {number.number}</Typography>
              )}
              {!isDesktop && (
                <>
                  {inscriptionState === observerOptions.Pending &&
                    PAYMENT_TYPES[paymentType] !== PAYMENT_TYPES.MercadoPago &&
                    !!saleOrder &&
                    (hasSaleOrderPending ? (
                      <>
                        <Button
                          color='primary'
                          variant='contained'
                          className={classes.uploadReceiptButton}
                          onClick={() => setOpenUploadReceiptDialog(true)}>
                          Subir comprobante
                        </Button>
                        <UploadReceiptDialog
                          open={openUploadReceiptDialog}
                          saleOrder={saleOrder}
                          onClose={() => setOpenUploadReceiptDialog(false)}
                        />
                      </>
                    ) : (
                      renderViewReceiptFile()
                    ))}

                  {inscriptionState === observerOptions.Cancelled && (
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.uploadReceiptButton}
                      onClick={handleRedirectInscriptionForm}>
                      Inscribirme
                    </Button>
                  )}
                </>
              )}
            </>
          )}

          {inscriptionExpired && (
            <Typography color='primary' variant='h6'>
              {PAYMENT_TYPES[paymentType] === PAYMENT_TYPES.MercadoPago
                ? `La inscripción al evento ${event.name} se dió de baja automáticamente, por problemas con el pago`
                : `La preinscripción al evento ${event.name} se dió de baja automáticamente, porque el comprobante no fue subido en el plazo establecido por el organizador`}
              .
              <br />
              Si deseas retomar la inscripción hacé click&nbsp;
              <Link className={classes.link} to={`/events/${event.slugUrl}/inscription/${id}`}>
                aquí.
              </Link>
            </Typography>
          )}
        </div>
      </div>
    )
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={handleOnClose}
      title={stateDialog.title}
      className={classes.dialog}
      contentClassName={classes.container}>
      {stateDialog === DIALOG_STATES.SEARCH_INSCRIPTION_DIALOG && renderSearchInscription()}
      {stateDialog === DIALOG_STATES.STATE_INSCRIPTION_DIALOG && renderStateInscription()}
    </SectionLayoutDialog>
  )
}
export default CheckStateInscriptionDialog
