import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'
import { discreditEventInscription } from 'state/modules/events'
import { showSnackbarError } from 'utils/snackbar'

import { useStyles } from './AccreditDialog.style'

const AccreditDialog = ({
  open,
  onClose = () => {},
  inscription = {},
  handleOpenAccreditTicket = () => {},
  loadInscriptions = () => {}
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [discreditPending, setDiscreditPending] = useState(false)

  const { firstName, lastName, distance, category, number: bibNumber, id } = inscription

  const handleDiscreditInscription = async () => {
    try {
      setDiscreditPending(true)
      const data = await dispatch(discreditEventInscription(id))

      if (data) await loadInscriptions(1, data)

      onClose()
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setDiscreditPending(false)
    }
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={onClose}
      title='Acreditación'
      className={classes.dialog}
      contentClassName={classes.content}>
      <div className={classes.container}>
        <div className={classes.infoContainer}>
          <Typography color='primary' variant='h6' align='center' className={classes.subtitle}>
            Acreditaste a
          </Typography>

          <Typography color='primary' variant='h5' align='center' className={classes.fullName}>
            {lastName} {firstName}
          </Typography>
          <Typography color='primary' variant='h5' align='center' className={classes.bibNumber}>
            N° {bibNumber?.number}
          </Typography>

          <Typography color='primary' variant='h6' align='center' className={classes.info}>
            {distance?.name} {category?.name}
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            disabled={discreditPending}
            endIcon={discreditPending && <CircularProgress size={16} color='primary' />}
            onClick={handleDiscreditInscription}>
            Deshacer
          </Button>

          <Button
            color='primary'
            variant='outlined'
            disabled={discreditPending}
            className={classes.button}
            onClick={handleOpenAccreditTicket}>
            Ver ticket
          </Button>
        </div>
      </div>
    </SectionLayoutDialog>
  )
}

export default AccreditDialog
