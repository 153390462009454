import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core'
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import clsx from 'clsx'
import { Formik } from 'formik'
import {
  createBibNumbers,
  deleteBibNumbers,
  loadBibNumbers,
  updateBibNumbers
} from 'state/modules/events'
import { FORM_STATES, STRING_EMPTY } from 'utils/constants'
import { showSnackbarSuccess } from 'utils/snackbar'
import * as Yup from 'yup'

import { AssociateCategoryDialog, InfoDialog } from './components'
import { useStyles } from './DistanceBlock.style'

const NUMBER_TEXT_PATTERN =
  /^(?:[1-9]\d{0,3}|\s*[1-9]\d{0,3}\s*-\s*[1-9]\d{0,3})(?:\s*,\s*(?:[1-9]\d{0,3}|\s*[1-9]\d{0,3}\s*-\s*[1-9]\d{0,3}))*$/

const DistanceBlock = ({ id, name, categoryList, mode = FORM_STATES.CREATE }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    event,
    bibNumbers,
    createBibNumbersPending,
    updateBibNumbersPending,
    deleteBibNumbersPending
  } = useSelector((state) => state.events)

  const numberOptions = bibNumbers
    .filter((x) => x.distanceId === id)
    .sort((x, y) => x.number - y.number)

  const [openSection, setOpenSection] = useState(false)
  const [categories, setCategories] = useState(categoryList)
  const [activeForm, setActiveForm] = useState(!numberOptions.length)
  const [numbersSeleted, setNumbersSeleted] = useState({})
  const [duplicateNumbersState, setDuplicateNumbersState] = useState([])
  const [openInfoDialog, setOpenInfoDialog] = useState(false)
  const [createPending, setCreatePending] = useState(false)
  const [associateCategoryDialogProps, setAssociateCategoryDialogProps] = useState({
    numbers: [],
    open: false,
    onConfirm: () => {}
  })

  const createNumbers = async ({ numbersText, categorySelected }, { resetForm }) => {
    setCreatePending(true)

    const numbers = generateNumbers(numbersText.trim())

    const duplicateNumbers = numbers.filter((x) => bibNumbers.some((n) => n.number === x))

    const numberList = numbers
      .filter((x) => !duplicateNumbers.some((n) => n === x))
      .map((x) => ({
        number: x,
        categoryId: categorySelected ? categorySelected.value : null
      }))

    const numbersPayload = {
      distanceId: id,
      numbers: numberList
    }

    const createSuccess = await dispatch(createBibNumbers(event.id, numbersPayload))

    if (createSuccess) {
      if (duplicateNumbers.length) {
        setDuplicateNumbersState(duplicateNumbers)
        setOpenInfoDialog(true)
      }

      resetForm()
      setActiveForm(false)
      dispatch(loadBibNumbers(event.id))
      showSnackbarSuccess('¡Números creados con éxito!')
    }

    setCreatePending(false)
  }

  const handleUpdateNumbers = async ({ categorySelected }, { resetForm }) => {
    const numbers = numberOptions
      .filter((x) => !!numbersSeleted[x.id] && !numbersSeleted[x.id].categoryId)
      .map((x) => ({
        ...x,
        categoryId: categorySelected ? categorySelected.value : null
      }))

    setAssociateCategoryDialogProps({
      numbers,
      open: true,
      categoryName: categorySelected.label,
      distanceName: name,
      onConfirm: updateNumbers(resetForm)
    })
  }

  const updateNumbers = (resetForm) => async (numbers) => {
    const payload = {
      distanceId: id,
      numbers
    }

    const updateSuccess = await dispatch(updateBibNumbers(event.id, payload))

    if (updateSuccess) {
      dispatch(loadBibNumbers(event.id))
      resetForm()
      clearNumbersSelected()
      setAssociateCategoryDialogProps({ ...associateCategoryDialogProps, open: false })
      showSnackbarSuccess('¡Números asociados con categoría!')
    }
  }

  const generateNumbers = (inputText) => {
    if (!inputText) return []

    const nums = new Set()
    const regex = /\d+\s*-\s*\d+|\d+/g
    const result = inputText.match(regex)

    if (!result) return []

    for (const item of result) {
      if (item.includes('-')) {
        const [start, end] = item.split('-').map((n) => Number(n.trim()))

        if (isNaN(start) || isNaN(end)) continue

        const from = start <= end ? start : end
        const to = start <= end ? end : start

        for (let i = from; i <= to; i++) nums.add(i)
      } else {
        const num = Number(item.trim())

        if (isNaN(num)) continue

        nums.add(num)
      }
    }

    return [...nums].sort((x, y) => x - y)
  }

  const values = {
    categorySelected: null,
    categoryText: STRING_EMPTY,
    numbersText: STRING_EMPTY
  }

  const asociateCategoryValues = {
    categorySelected: null,
    categoryText: STRING_EMPTY
  }

  const hasNumbers = !!numberOptions.length

  const onInputChange =
    (setFieldValue) =>
    (e, value = STRING_EMPTY) => {
      setFieldValue('categoryText', value, false)
      setCategories(
        categoryList.filter((x) =>
          x.label.toLowerCase().trim().includes(value?.toLowerCase().trim())
        )
      )
    }

  const handleSelected = (number) => {
    if (activeForm || number.assignedAt) return

    const hasNumberSelected = numbersSeleted[number.id]

    setNumbersSeleted({ ...numbersSeleted, [number.id]: !hasNumberSelected ? number : null })
  }

  const handleActiveForm = () => {
    if (hasSomeOptionSelected) clearNumbersSelected()

    setActiveForm(true)
  }

  const handleDeleteNumber = async () => {
    const payload = {
      distanceId: id,
      numbers: Object.keys(numbersSeleted)
        .filter((x) => !!numbersSeleted[x])
        .map((x) => ({ id: x }))
    }

    const deleteSuccess = await dispatch(deleteBibNumbers(event.id, payload))

    if (deleteSuccess) {
      const data = await dispatch(loadBibNumbers(event.id))

      const hasNumbers = data.some((x) => !x.assignedAt && x.distanceId === id)

      setActiveForm(hasNumbers ? activeForm : true)
      clearNumbersSelected()

      showSnackbarSuccess('¡Número/s eliminados con éxito!')
    }
  }

  const clearNumbersSelected = () => {
    if (activeForm) return

    setNumbersSeleted({})
  }

  const allNumbersSelected = () => {
    if (activeForm) return

    setNumbersSeleted(
      numberOptions
        .filter((x) => !x.assignedAt)
        .reduce(
          (acc, el) => ({
            ...acc,

            [el.id]: el
          }),
          {}
        )
    )
  }

  const numbersKeys = Object.keys(numbersSeleted)
  const hasSomeOptionSelected = !!numbersKeys.length && numbersKeys.some((x) => !!numbersSeleted[x])

  const hasOptionWithoutAssociate =
    hasSomeOptionSelected && numbersKeys.every((x) => !numbersSeleted[x]?.categoryId)

  const createValidationSchema = Yup.object().shape({
    numbersText: Yup.string()
      .required('Debe ingresar números. Ej: 1,2,3 o 1-10')
      .max(50, 'Hasta 50 caracteres')
      .matches(
        NUMBER_TEXT_PATTERN,
        'Sólo se puede ingresar números enteros positivos hasta 4 dígitos separados por una coma, un guión'
      )
      .test(
        'duplicate-test',
        'Todos los números o rangos de números ingresados son duplicados',
        (text = STRING_EMPTY) => {
          if (!text.match(NUMBER_TEXT_PATTERN)) return false

          const numbers = generateNumbers(text?.trim())
          return numbers.some((x) => !bibNumbers.some((n) => n.number === x))
        }
      )
      .trim()
  })

  const asociateValidationSchema = Yup.object().shape({
    categorySelected: Yup.mixed().nullable().required('Debe escoger una categoría')
  })

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.actions}>
            <Typography
              color='primary'
              variant='h6'
              onClick={() => {
                setActiveForm(!numberOptions.length)
                setOpenSection(!openSection)
              }}
              className={clsx(openSection && classes.underlineTitle)}>
              {name}
            </Typography>

            <IconButton
              color='primary'
              onClick={() => {
                setActiveForm(!numberOptions.length)
                setOpenSection(!openSection)
              }}
              size='small'>
              {openSection ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </div>

          {hasNumbers &&
            openSection &&
            (hasSomeOptionSelected ? (
              <Typography
                className={clsx(classes.link, activeForm && 'disabled')}
                color='primary'
                variant='h6'
                onClick={clearNumbersSelected}>
                Limpiar selección
              </Typography>
            ) : (
              <Typography
                className={clsx(classes.link, activeForm && 'disabled')}
                color='primary'
                variant='h6'
                onClick={allNumbersSelected}>
                Seleccionar todos
              </Typography>
            ))}
        </div>
        <Collapse in={openSection}>
          {hasNumbers && (
            <div className={classes.numberContainer}>
              <Typography
                color='primary'
                variant='caption'
                className={classes.optionSelectedHelperText}>
                Para seleccionar haga click en los números que desea asociar o en seleccionar todos,
                luego seleccione la categoría del desplegable.
              </Typography>
              <div className={classes.filterContainer}>
                <div className={classes.filter}>
                  <div className={classes.unSelectedFilter} />
                  <Typography color='primary' variant='caption'>
                    Sin seleccionar
                  </Typography>
                </div>

                <div className={classes.filter}>
                  <div className={classes.selectedFilter} />
                  <Typography color='primary' variant='caption'>
                    Seleccionados
                  </Typography>
                </div>

                <div className={classes.filter}>
                  <div className={classes.useFilter} />
                  <Typography color='primary' variant='caption'>
                    Asignados a deportistas (No se pueden seleccionar).
                  </Typography>
                </div>
              </div>

              <div className={classes.options}>
                {numberOptions.map((x) => (
                  <div
                    key={x.id}
                    className={clsx(
                      classes.numberOption,
                      activeForm && 'disabled',
                      !!numbersSeleted[x.id] && 'selected',
                      !!x.assignedAt && 'assigned'
                    )}
                    onClick={() => handleSelected(x)}
                    title={`${x.number} - ${!x.categoryId ? 'Sin asociar' : x.categoryName}`}>
                    {x.number} - {!x.categoryId ? 'Sin asociar' : x.categoryName}
                  </div>
                ))}
              </div>
            </div>
          )}
          {!hasNumbers && mode === FORM_STATES.UPDATE && (
            <Typography variant='h6' className={classes.emptyMessage}>
              No hay números para la distancia seleccionada, debes crearlos
            </Typography>
          )}
          <div className={classes.content}>
            {!activeForm && (
              <Typography
                color='primary'
                variant='h6'
                className={classes.addLink}
                onClick={handleActiveForm}>
                + Añadir números
              </Typography>
            )}
            {activeForm ? (
              <Formik
                enableReinitialize
                initialValues={values}
                onSubmit={createNumbers}
                validationSchema={createValidationSchema}>
                {({
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  isValid
                }) => (
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                      variant='outlined'
                      color='primary'
                      size='small'
                      fullWidth
                      name='numbersText'
                      label='Ingrese números*'
                      helperText={errors.numbersText || 'Ej: 1,2,3 o 1-10'}
                      value={values.numbersText}
                      onChange={handleChange}
                      autoComplete='off'
                      onBlur={handleBlur}
                      error={touched.numbersText && !!errors.numbersText}
                      className={classes.textField}
                    />
                    <Autocomplete
                      id='category-autocomplete'
                      options={categories}
                      getOptionLabel={(option) => option.label}
                      selectOnFocus
                      onInputChange={onInputChange(setFieldValue)}
                      onChange={(event, optionSelected) =>
                        setFieldValue('categorySelected', optionSelected)
                      }
                      filterOptions={(x) => x}
                      className={classes.combo}
                      value={values.categorySelected}
                      loadingText={<Typography align='center'> Cargando...</Typography>}
                      noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                      clearText='Quitar todo'
                      openText='Abrir desplegable'
                      closeText='Cerrar desplegable'
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={clsx(classes.input, classes.comboInput)}
                          name='categorySelected'
                          label='Asociar los números con categoría'
                          variant='outlined'
                          onBlur={handleBlur}
                          helperText='Seleccione una opción del desplegable. Opcional.'
                        />
                      )}
                    />
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={
                        !isValid || isSubmitting || createBibNumbersPending || createPending
                      }
                      endIcon={
                        (isSubmitting || createPending) && (
                          <CircularProgress size={16} color='primary' />
                        )
                      }
                      className={classes.saveButton}
                      type='submit'>
                      Crear números
                    </Button>

                    {hasNumbers && (
                      <Button
                        color='primary'
                        variant='outlined'
                        className={classes.altButton}
                        onClick={() => setActiveForm(false)}>
                        Cancelar
                      </Button>
                    )}
                  </form>
                )}
              </Formik>
            ) : (
              <Formik
                enableReinitialize
                initialValues={asociateCategoryValues}
                onSubmit={handleUpdateNumbers}
                validationSchema={asociateValidationSchema}>
                {({ values, handleBlur, handleSubmit, setFieldValue, isSubmitting, isValid }) => (
                  <form className={classes.altForm} onSubmit={handleSubmit}>
                    <Autocomplete
                      id='category-autocomplete'
                      options={categories}
                      getOptionLabel={(option) => option.label}
                      selectOnFocus
                      onInputChange={onInputChange(setFieldValue)}
                      onChange={(event, optionSelected) =>
                        setFieldValue('categorySelected', optionSelected)
                      }
                      filterOptions={(x) => x}
                      className={classes.combo}
                      value={values.categorySelected}
                      disabled={!hasOptionWithoutAssociate || deleteBibNumbersPending}
                      loadingText={<Typography align='center'> Cargando...</Typography>}
                      noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                      clearText='Quitar todo'
                      openText='Abrir desplegable'
                      closeText='Cerrar desplegable'
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={clsx(
                            classes.input,
                            classes.comboInput,
                            (!hasOptionWithoutAssociate || deleteBibNumbersPending) && 'disabled'
                          )}
                          name='categorySelected'
                          label='Asociar los números con categoría'
                          variant='outlined'
                          onBlur={handleBlur}
                          autoFocus
                          helperText='Seleccione una opción del desplegable. Opcional.'
                        />
                      )}
                    />

                    <Button
                      variant='contained'
                      color='primary'
                      endIcon={
                        (isSubmitting || updateBibNumbersPending) && (
                          <CircularProgress size={16} color='primary' />
                        )
                      }
                      disabled={
                        !isValid ||
                        !values.categorySelected ||
                        isSubmitting ||
                        !hasOptionWithoutAssociate ||
                        updateBibNumbersPending
                      }
                      className={classes.saveButton}
                      type='submit'>
                      Asociar números
                    </Button>
                  </form>
                )}
              </Formik>
            )}

            <InfoDialog
              open={openInfoDialog}
              onClose={() => setOpenInfoDialog(false)}
              numbers={duplicateNumbersState}
            />
            <AssociateCategoryDialog
              {...associateCategoryDialogProps}
              onClose={() =>
                setAssociateCategoryDialogProps({ ...associateCategoryDialogProps, open: false })
              }
            />

            {hasNumbers && !activeForm && (
              <Button
                variant='outlined'
                color='primary'
                className={classes.altButton}
                onClick={handleDeleteNumber}
                disabled={!hasSomeOptionSelected || deleteBibNumbersPending}
                endIcon={deleteBibNumbersPending && <CircularProgress size={16} color='primary' />}>
                Eliminar
              </Button>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  )
}

export default DistanceBlock
