import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '300px !important',
    [theme.breakpoints.up('md')]: {
      width: 450,
      maxWidth: 450
    }
  },
  text: {
    color: theme.palette.disabled.main,

    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 500
    }
  },
  numbers: {
    fontWeight: 500,
    marginBottom: theme.spacing(2)
  },
  buttonContainer: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignItems: 'center',

    '& button': {
      borderRadius: 10,
      width: 210
    }
  }
}))
