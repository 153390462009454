import React from 'react'
import { Typography } from '@material-ui/core'
import { EVENT_INSCRIPTION_STATES, SALE_ORDER_STATES, STRING_EMPTY } from 'utils/constants'

import { useStyles } from './InscriptionFilterTab.style'

const FILTER_STATES = {
  [EVENT_INSCRIPTION_STATES.PENDING]: {
    title: 'PREINSCRIPTO',
    label: 'Preinscripto'
  },
  [EVENT_INSCRIPTION_STATES.ACCEPTED]: {
    title: 'INSCRIPTO',
    label: 'Inscripto'
  },
  [EVENT_INSCRIPTION_STATES.CANCELLED]: {
    title: 'ANULADO',
    label: 'Anulado'
  },
  [SALE_ORDER_STATES.EXPIRED]: {
    title: 'VENCIDO',
    label: 'Vencido'
  },
  withNumber: {
    label: 'Con número'
  },
  withoutNumber: {
    label: 'Sin número'
  }
}

const InscriptionFilterTab = ({
  filterSelected = STRING_EMPTY,
  filterCount = 0,
  filterCountHidden = false,
  title = STRING_EMPTY,
  filterClean = () => {}
}) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography color='primary' variant='h6' className={classes.eventName}>
          {title}
        </Typography>
      </div>
      <div className={classes.mainContainer}>
        <Typography className={classes.filter}>
          {FILTER_STATES[filterSelected]?.label} {!filterCountHidden && `(${filterCount})`}
        </Typography>

        <Typography className={classes.filterLink} onClick={filterClean}>
          Limpiar filtro
        </Typography>
      </div>
    </div>
  )
}

export default InscriptionFilterTab
