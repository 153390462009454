import React from 'react'
import { useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './AssociateCategoryDialog.style'

const AssociateCategoryDialog = ({
  open,
  onClose = () => {},
  onConfirm = () => {},
  numbers,
  categoryName,
  distanceName
}) => {
  const classes = useStyles()
  const { updateBibNumbersPending } = useSelector((state) => state.events)

  const generateNumbersRanges = () => {
    if (!numbers.length) return []

    return numbers
      .map((x) => x.number)
      .reduce((ranges, num, i, arr) => {
        if (i === 0 || num !== arr[i - 1] + 1) {
          ranges.push([num])
        } else {
          ranges[ranges.length - 1][1] = num
        }
        return ranges
      }, [])
      .map(([start, end]) => (end ? `${start} al ${end}` : start))
      .join(', ')
  }

  return (
    <SectionLayoutDialog open={open} title='Asociaste números' className={classes.dialog}>
      <Typography className={classes.text}>
        Asociaste los siguientes números de {distanceName}:
      </Typography>
      <Typography color='primary' className={classes.numbers}>
        {generateNumbersRanges()}
      </Typography>
      <Typography className={classes.text}>
        Con la categoría <span>{categoryName}.</span>
      </Typography>

      <div className={classes.buttonContainer}>
        <Button
          color='primary'
          variant='contained'
          onClick={() => onConfirm(numbers)}
          disabled={updateBibNumbersPending}
          endIcon={updateBibNumbersPending && <CircularProgress size={16} color='primary' />}>
          Aceptar
        </Button>
        <Button
          color='primary'
          variant='outlined'
          onClick={onClose}
          disabled={updateBibNumbersPending}>
          Deshacer
        </Button>
      </div>
    </SectionLayoutDialog>
  )
}

export default AssociateCategoryDialog
