import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { EllipseStatus } from 'shared'
import { EVENT_INSCRIPTION_STATES, SALE_ORDER_STATES } from 'utils/constants'

import { useStyles } from './InscriptionFilter.style'

const INSCRIPTIONS_PAGE_STATES = {
  REGISTERED: 'registered',
  ASSIGN_NUMBER: 'assign-number'
}

const InscriptionFilter = ({
  mini,
  mode = 'registered',
  selectedFilter,
  stateItems = {},
  orderItems = {},
  handleStateFilter = () => {},
  handleNumberFilter = () => {},
  resetFilter = () => {},
  handleChangeSaleOrderState = () => {},
  slugUrlOrId
}) => {
  const classes = useStyles()

  const { bibNumbers } = useSelector((state) => state.events)

  const expiredCount = orderItems[SALE_ORDER_STATES.EXPIRED] || 0

  const cancelledCount = (stateItems[EVENT_INSCRIPTION_STATES.CANCELLED] || 0) - expiredCount

  const totalState = Object.values(stateItems).reduce((acc, item) => acc + item, 0)
  const totalStateCount = totalState - expiredCount

  const renderFilterMini = () =>
    mode === INSCRIPTIONS_PAGE_STATES.REGISTERED ? (
      <div className={classes.statusContainer}>
        <div className={clsx(classes.stateContainer, 'center')}>
          <Typography
            className={clsx(!selectedFilter.state && !selectedFilter.saleOrderState && 'filter')}
            onClick={resetFilter}>
            Total ({totalStateCount})
          </Typography>
        </div>

        <div className={classes.statusSection}>
          <div className={classes.stateBlock}>
            <div className={classes.stateContainer}>
              <EllipseStatus
                status='yellow'
                title='Preinscripto'
                className={classes.statusEllipse}
              />
              <Typography
                className={clsx(
                  selectedFilter.state === EVENT_INSCRIPTION_STATES.PENDING && 'filter'
                )}
                onClick={() => handleStateFilter(EVENT_INSCRIPTION_STATES.PENDING)}>
                Preinscripto ({stateItems[EVENT_INSCRIPTION_STATES.PENDING] || 0})
              </Typography>
            </div>
            <div className={classes.stateContainer}>
              <EllipseStatus status='red' title='Anulado' className={classes.statusEllipse} />
              <Typography
                className={clsx(
                  selectedFilter.state === EVENT_INSCRIPTION_STATES.CANCELLED && 'filter'
                )}
                onClick={() => handleStateFilter(EVENT_INSCRIPTION_STATES.CANCELLED)}>
                Anulado ({cancelledCount})
              </Typography>
            </div>
          </div>

          <div className={classes.stateBlock}>
            <div className={classes.stateContainer}>
              <EllipseStatus status='green' title='Inscripto' className={classes.statusEllipse} />
              <Typography
                className={clsx(
                  selectedFilter.state === EVENT_INSCRIPTION_STATES.ACCEPTED && 'filter'
                )}
                onClick={() => handleStateFilter(EVENT_INSCRIPTION_STATES.ACCEPTED)}>
                Inscripto ({stateItems[EVENT_INSCRIPTION_STATES.ACCEPTED] || 0})
              </Typography>
            </div>

            <div className={classes.stateContainer}>
              <EllipseStatus status='grey' title='Vencido' className={classes.statusEllipse} />
              <Typography
                className={clsx(
                  selectedFilter.saleOrderState === SALE_ORDER_STATES.EXPIRED && 'filter'
                )}
                onClick={() => handleChangeSaleOrderState(SALE_ORDER_STATES.EXPIRED)}>
                Vencido ({expiredCount})
              </Typography>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={classes.statusContainer}>
        <Typography variant='h6' className={classes.filterTitle} align='center'>
          Inscriptos con y sin número
        </Typography>
        <div className={clsx(classes.statusSection, classes.numberSection)}>
          <div className={classes.stateContainer}>
            <Typography
              className={clsx(selectedFilter.withoutNumber && 'filter')}
              onClick={() => handleNumberFilter('withoutNumber', !selectedFilter.withoutNumber)}>
              Sin número
            </Typography>
          </div>

          <div className={classes.stateContainer}>
            <Typography
              className={clsx(selectedFilter.withNumber && 'filter')}
              onClick={() => handleNumberFilter('withNumber', !selectedFilter.withNumber)}>
              Con número
            </Typography>
          </div>
        </div>
      </div>
    )

  const renderFilter = () =>
    mode === INSCRIPTIONS_PAGE_STATES.REGISTERED ? (
      <div className={classes.statusSection}>
        <Typography color='primary' variant='h6' className={classes.filterLabel}>
          Aplicar filtros:
        </Typography>
        <div className={classes.stateContainer}>
          <EllipseStatus status='yellow' title='Preinscripto' className={classes.statusEllipse} />
          <Typography
            className={clsx(selectedFilter.state === EVENT_INSCRIPTION_STATES.PENDING && 'filter')}
            onClick={() => handleStateFilter(EVENT_INSCRIPTION_STATES.PENDING)}>
            Preinscripto ({stateItems[EVENT_INSCRIPTION_STATES.PENDING] || 0})
          </Typography>
        </div>
        <div className={classes.stateContainer}>
          <EllipseStatus status='green' title='Inscripto' className={classes.statusEllipse} />
          <Typography
            className={clsx(selectedFilter.state === EVENT_INSCRIPTION_STATES.ACCEPTED && 'filter')}
            onClick={() => handleStateFilter(EVENT_INSCRIPTION_STATES.ACCEPTED)}>
            Inscripto ({stateItems[EVENT_INSCRIPTION_STATES.ACCEPTED] || 0})
          </Typography>
        </div>
        <div className={classes.stateContainer}>
          <EllipseStatus status='red' title='Anulado' className={classes.statusEllipse} />
          <Typography
            className={clsx(
              selectedFilter.state === EVENT_INSCRIPTION_STATES.CANCELLED && 'filter'
            )}
            onClick={() => handleStateFilter(EVENT_INSCRIPTION_STATES.CANCELLED)}>
            Anulado ({cancelledCount})
          </Typography>
        </div>
        <div className={classes.stateContainer}>
          <Typography
            className={clsx(!selectedFilter.state && !selectedFilter.saleOrderState && 'filter')}
            onClick={resetFilter}>
            Total ({totalStateCount})
          </Typography>
        </div>
        <div className={classes.stateContainer}>
          <EllipseStatus status='grey' title='Vencido' className={classes.statusEllipse} />
          <Typography
            className={clsx(
              selectedFilter.saleOrderState === SALE_ORDER_STATES.EXPIRED && 'filter'
            )}
            onClick={() => handleChangeSaleOrderState(SALE_ORDER_STATES.EXPIRED)}>
            Vencido ({expiredCount})
          </Typography>
        </div>
      </div>
    ) : (
      <div className={classes.numberFilterSection}>
        <div className={classes.stateContainer}>
          <Typography
            className={clsx(selectedFilter.withoutNumber && 'filter')}
            onClick={() => handleNumberFilter('withoutNumber', !selectedFilter.withoutNumber)}>
            Inscripto/s sin número
          </Typography>
        </div>
        <div className={classes.stateContainer}>
          <Typography
            className={clsx(selectedFilter.withNumber && 'filter')}
            onClick={() => handleNumberFilter('withNumber', !selectedFilter.withNumber)}>
            Inscripto/s con número
          </Typography>
        </div>

        {!!bibNumbers.length && (
          <Button
            color='primary'
            variant='contained'
            className={classes.editNumberButton}
            component={Link}
            to={`/events/${slugUrlOrId}/edit-number`}>
            Editar números
          </Button>
        )}
      </div>
    )

  return !mini ? renderFilter() : renderFilterMini()
}

export default InscriptionFilter
