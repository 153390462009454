import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  listContainer: {
    minHeight: 300,
    marginBottom: theme.spacing(2),
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  statusEllipse: {
    width: 18,
    height: 18
  },
  inscriptionStatusContainer: {
    marginLeft: theme.spacing(2)
  },
  table: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    borderCollapse: 'collapse',
    borderSpacing: 0,

    '& th': {
      textAlign: 'left',
      fontSize: '1rem',
      padding: theme.spacing(0.75),
      color: theme.palette.primary.main,
      fontWeight: 500
    },
    '& td': {
      textAlign: 'left',
      padding: theme.spacing(0.75)
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#D9D9D95E'
    },
    '& table': {
      width: '100%'
    }
  },

  headerCol: {
    cursor: 'pointer'
  },
  colContainer: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontWeight: 500
    }
  },
  option: {
    textDecoration: 'underline'
  },
  filterLoading: {
    marginLeft: theme.spacing()
  },
  buttonContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'center'
  },
  containerLoader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(2, 0)
  },
  viewMoreButton: {
    textDecoration: 'underline',
    cursor: 'pointer',
    userSelect: 'none',
    marginLeft: -10
  },
  inscriptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(),

    '& > div > div': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2)
    },

    '&.alt': {
      marginTop: theme.spacing(5)
    },

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  inscriptionInfo: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2),
    padding: theme.spacing(2),
    margin: theme.spacing(),
    borderRadius: 10
  },
  paginationContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    height: 150,
    flexDirection: 'column',
    alignItems: 'center'
  },
  root: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',

      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    }
  },
  btnSaveContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  altData: {
    backgroundColor: theme.palette.primary.contrastText
  },
  select: {
    width: '80px',
    '& svg': {
      color: theme.palette.secondary.main
    }
  },
  actionButton: {
    minWidth: 40,
    padding: '6px',

    '&:disabled': {
      color: theme.palette.primary.contrastText
    }
  },
  saveButton: {
    width: 180,
    height: 40,
    borderRadius: 10
  },
  emptyMessageContainer: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2),
    padding: theme.spacing(1, 2),
    borderRadius: 10
  },
  unResultsContainer: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2),
    padding: theme.spacing(2),
    borderRadius: 10
  }
}))
