import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { CircularProgress, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import noImage from 'assets/img/blue-user-mock-alt.jpg'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import debounce from 'lodash.debounce'
import { OrganizationsProxy } from 'services'
import { STRING_EMPTY } from 'utils/constants'
import { showSnackbarError } from 'utils/snackbar'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './TimingSection.style'

const TimingSection = ({ sectionDisabled }) => {
  const classes = useStyles()

  const { event } = useSelector((state) => state.events)

  const { values, setFieldValue, errors, touched } = useFormikContext()

  const [organizationOptions, setOrganizationOptions] = useState([])
  const [organizationsPending, setOrganizationsPending] = useState(false)
  const [addTimingkepperActive, setAddTimingkepperActive] = useState(event.eventTimekeeper)

  const debounceFetchOrganizations = (inputValue) => fetchOrganizations(inputValue)

  const debounceOnChange = debounce(debounceFetchOrganizations, 700)

  const fetchOrganizations = async (searchText = STRING_EMPTY) => {
    if (!searchText || searchText.length < 3) {
      setOrganizationOptions([])
      return
    }

    try {
      setOrganizationsPending(true)

      const proxy = new OrganizationsProxy()
      const data = await proxy.getEventOrganizationSuggestions(searchText, 1, 20)

      setOrganizationOptions([...data.results])

      return data.results
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setOrganizationsPending(false)
    }
  }

  const onTimekeeperChange = (setFieldValue, options) =>
    setFieldValue('timekeeperSelected', options)

  const renderSelectOption = ({ profileImages, name, handle, id }) => {
    const imageUrl =
      Array.isArray(profileImages) && profileImages.length > 0 ? profileImages[0] : noImage
    const handleOrId = handle || id

    return (
      <div className={classes.selectOption}>
        <div className={classes.imageContainer} style={{ backgroundImage: `url(${imageUrl})` }} />

        <div className={classes.info}>
          <Typography variant='h6' color='primary' className={classes.timingName}>
            {name}
          </Typography>

          <Typography variant='h6' color='primary' className={classes.timingName}>
            @{handleOrId}
          </Typography>
        </div>
      </div>
    )
  }

  const onInputChange = (debounceOnChange, setFieldValue) => (event, value, reason) => {
    value = (value ?? STRING_EMPTY).trim()

    setFieldValue('timekeeperName', value)
    debounceOnChange(value)
  }

  return (
    <SectionBlock title='Cronometra'>
      {addTimingkepperActive ? (
        <Autocomplete
          id='timekeeper-autocomplete'
          options={organizationOptions.filter(
            (x) => !event.organizations.some((o) => o.organization.id === x.id)
          )}
          getOptionLabel={(option) => option.handle || option.name}
          selectOnFocus
          value={values.eventTimekeeper}
          filterSelectedOptions
          onInputChange={onInputChange(debounceOnChange, setFieldValue)}
          onChange={(event, optionSelected) =>
            onTimekeeperChange(setFieldValue, optionSelected, event)
          }
          freeSolo
          renderOption={renderSelectOption}
          filterOptions={(x) => x}
          className={classes.combo}
          loading={organizationsPending}
          disabled={sectionDisabled}
          loadingText={<Typography align='center'> Cargando...</Typography>}
          noOptionsText={<Typography align='center'> Sin opciones</Typography>}
          clearText='Quitar todo'
          openText='Abrir desplegable'
          closeText='Cerrar desplegable'
          renderInput={(params) => (
            <TextField
              {...params}
              className={clsx(classes.input, classes.comboInput)}
              name='timekeeperName'
              label='Nombre o alias del cronometrista'
              variant='outlined'
              error={touched.timekeeperName && Boolean(errors.timekeeperName)}
              helperText={
                errors.timekeeperName || 'Si tiene perfil en Sportmetric escribí su alias.'
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: organizationsPending ? (
                  <CircularProgress color='primary' size={16} />
                ) : (
                  params.InputProps.endAdornment
                )
              }}
            />
          )}
        />
      ) : (
        <Typography
          color='primary'
          variant='h6'
          className={clsx(classes.addLink, sectionDisabled && 'disabled')}
          onClick={() => {
            if (sectionDisabled) return

            setAddTimingkepperActive(true)
          }}>
          + Añadir cronometrista
        </Typography>
      )}
    </SectionBlock>
  )
}

export default TimingSection
