import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import { resetValue } from 'state/modules/events'
import { FORM_STATES } from 'utils/constants'
import { showSnackbarError } from 'utils/snackbar'

import { DistanceBlock } from '../DistanceBlock'

import { useStyles } from './BibNumberForm.style'

const BibNumberForm = ({ distances = [], items = {}, mode = FORM_STATES.CREATE }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { createBibNumbersError, updateBibNumbersError, deleteBibNumbersError } = useSelector(
    (state) => state.events
  )

  useEffect(() => {
    if (createBibNumbersError) showSnackbarError(createBibNumbersError)

    return () => dispatch(resetValue('createBibNumbersError'))
  }, [createBibNumbersError])

  useEffect(() => {
    if (deleteBibNumbersError) showSnackbarError(deleteBibNumbersError)

    return () => dispatch(resetValue('deleteBibNumbersError'))
  }, [deleteBibNumbersError])

  useEffect(() => {
    if (updateBibNumbersError) showSnackbarError(updateBibNumbersError)

    return () => dispatch(resetValue('updateBibNumbersError'))
  }, [updateBibNumbersError])

  return (
    <div className={classes.form}>
      <Typography color='primary' variant='h6' className={classes.title}>
        {mode === FORM_STATES.CREATE
          ? 'Seleccione la distancia para la cual creará números.'
          : 'Seleccione la distancia para acceder a la edición de números.'}
      </Typography>

      <div className={classes.distanceContainer}>
        {distances.map((x, index) => (
          <DistanceBlock
            name={x.label}
            id={x.value}
            mode={mode}
            key={index}
            categoryList={items[x.value].map((c) => ({
              value: c.eventCategoryId,
              label: c.eventCategoryName
            }))}
          />
        ))}
      </div>
    </div>
  )
}

export default BibNumberForm
