import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import {
  ArrowBack,
  ArrowLeft as ArrowLeftIcon,
  Info as InfoIcon,
  Search as SearchIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import moment from 'moment'
import { SearchWidget } from 'pages/HomePage/components'
import { ROUTES } from 'routes'
import {
  CustomizedTooltip,
  EllipseStatus,
  Footer,
  MainLayout,
  NavBar,
  PageContainer,
  SignServiceLetterDialog,
  UpdateServiceLetterInfoDialog
} from 'shared'
import {
  load,
  loadActiveInscriptionForm,
  loadBibNumbers,
  loadListInscriptions,
  loadListInscriptionSearchMetadata,
  loadReset,
  loadTicketTypeFees,
  resetInscriptionForms,
  resetListInscription,
  setListinscription
} from 'state/modules/events'
import {
  EVENT_INSCRIPTION_STATES,
  INSCRIPTION_ORDER_BY,
  ORDER_DIRECTIONS,
  SALE_ORDER_STATES,
  STRING_EMPTY
} from 'utils/constants'
import { validateServiceLetter } from 'utils/functions'
import { showSnackbarSuccess } from 'utils/snackbar'

import {
  AssignNumberDialog,
  AssignNumberForm,
  DownloadListsDialog,
  InscriptionFilter,
  InscriptionFilterTab,
  InscriptionOffLineDialog,
  RegisteredList
} from './components'
import { useStyles } from './InscriptionRegisteredListPage.style'

const PAGE_SIZE = 25
const MIN_PAGE_SIZE = 3

const SELECT_FILTER_INITIAL_VALUE = {
  state: STRING_EMPTY,
  orderBy: INSCRIPTION_ORDER_BY.NUMBER,
  orderDirection: ORDER_DIRECTIONS.DESC,
  saleOrderState: STRING_EMPTY,
  withNumber: false,
  withoutNumber: false
}

const INSCRIPTIONS_PAGE_STATES = {
  REGISTERED: 'registered',
  ASSIGN_NUMBER: 'assign-number'
}
const InscriptionRegisteredListPage = () => {
  const classes = useStyles()
  const { slugUrlOrId } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const {
    event,
    pending,
    error: asyncError,
    ticketTypeFees,
    listInscriptionSearchMetadata,
    downloadInscriptionsSheetPending,
    bibNumbers,
    listInscription,
    listInscriptionPending
  } = useSelector((state) => state.events)
  const { user } = useSelector((state) => state.auth)

  const [searchInputText, setSearchInputText] = useState(STRING_EMPTY)
  const [selectedFilter, setSelectedFilter] = useState(SELECT_FILTER_INITIAL_VALUE)
  const [openInscriptionOffLineDialog, setOpenInscriptionOffLineDialog] = useState(false)
  const [openDownloadListsDialog, setOpenDownloadListsDialog] = useState(false)
  const [openSignServiceLetterDialog, setOpenSignServiceLetterDialog] = useState(false)
  const [openUpdateServiceLetterInfoDialog, setOpenUpdateServiceLetterInfoDialog] = useState(false)
  const [inscriptionSelected, setInscriptionSelected] = useState(null)
  const [inscriptionState, setInscriptionState] = useState(false)
  const [showAssignNumberForm, setShowAssignNumberForm] = useState(false)
  const [openAssignNumberDialog, setOpenAssignNumberDialog] = useState(false)
  const [searchPending, setSearchPending] = useState(false)

  const activeLoading = (!pending && !event && !asyncError) || pending

  const inscriptionListPath = ROUTES.EVENTS.INSCRIPTION_LIST.replace(':slugUrlOrId', slugUrlOrId)
  const isInscriptionsPage = location.pathname === inscriptionListPath

  const pageMode = isInscriptionsPage
    ? INSCRIPTIONS_PAGE_STATES.REGISTERED
    : INSCRIPTIONS_PAGE_STATES.ASSIGN_NUMBER

  const currentPageSize = isDesktop ? PAGE_SIZE : MIN_PAGE_SIZE
  const observerOptions = [
    {
      value: EVENT_INSCRIPTION_STATES.PENDING,
      label: (
        <EllipseStatus status='yellow' title='Preinscripto' className={classes.statusEllipse} />
      )
    },
    {
      value: EVENT_INSCRIPTION_STATES.ACCEPTED,
      label: <EllipseStatus status='green' title='Inscripto' className={classes.statusEllipse} />
    },
    {
      value: EVENT_INSCRIPTION_STATES.CANCELLED,
      label: <EllipseStatus status='red' title='Anulado' className={classes.statusEllipse} />
    },
    {
      value: SALE_ORDER_STATES.EXPIRED,
      label: <EllipseStatus status='grey' title='Vencido' className={classes.statusEllipse} />,
      optionHidden: true
    }
  ]

  useEffect(() => {
    dispatch(load(slugUrlOrId))
    return () => {
      dispatch(loadReset())
    }
  }, [dispatch, slugUrlOrId, user])

  useEffect(() => {
    dispatch(loadTicketTypeFees())
  }, [])

  useEffect(() => {
    if (event) {
      const stateSelected = getCurrentState()

      setInscriptionSelected(null)
      setShowAssignNumberForm(false)
      setSearchInputText(STRING_EMPTY)
      setSelectedFilter(SELECT_FILTER_INITIAL_VALUE)

      dispatch(
        loadListInscriptions(
          event.id,
          STRING_EMPTY,
          1,
          stateSelected,
          SELECT_FILTER_INITIAL_VALUE.saleOrderState,
          currentPageSize,
          SELECT_FILTER_INITIAL_VALUE.orderBy,
          SELECT_FILTER_INITIAL_VALUE.orderDirection
        )
      )

      dispatch(loadBibNumbers(event.id))
      dispatch(loadListInscriptionSearchMetadata(event.id))
    }

    return () => {
      dispatch(resetListInscription())
    }
  }, [dispatch, event, currentPageSize, isInscriptionsPage])

  useEffect(() => {
    if (event) {
      setSearchPending(true)
      debounceOnChange()
    }

    return () => {
      debounceOnChange.cancel()
    }
  }, [searchInputText])

  useEffect(() => {
    if (event && event.activeInscriptionFormId) dispatch(loadActiveInscriptionForm(event))

    return () => {
      dispatch(resetInscriptionForms())
    }
  }, [event, dispatch])

  const stateItems = listInscriptionSearchMetadata.stateItems.reduce((acc, el) => {
    const state = observerOptions.find((x) => `{ State = ${x.value} }` === el.name)

    return {
      ...acc,
      [state.value]: el.eventInscriptionsCount
    }
  }, {})

  const numberItems = listInscriptionSearchMetadata.numbersItems?.reduce(
    (acc, el) => ({
      ...acc,
      [el.name]: el.eventInscriptionsCount
    }),
    {}
  )

  const orderItems = listInscriptionSearchMetadata.orderStateItems.reduce((acc, el) => {
    const state = observerOptions.find((x) => `{ State = ${x.value} }` === el.name)

    return {
      ...acc,
      [state?.value ?? el.name]: el.eventInscriptionsCount
    }
  }, {})

  const handleStateFilter = async (state) => {
    if (listInscriptionPending || searchPending) return

    const saleOrderState = STRING_EMPTY

    await dispatch(
      loadListInscriptions(
        event.id,
        searchInputText,
        1,
        state,
        saleOrderState,
        currentPageSize,
        selectedFilter.orderBy,
        selectedFilter.orderDirection
      )
    )

    handleSelected(null)

    setSelectedFilter({ ...selectedFilter, state, saleOrderState })
  }

  const handleNumberFilter = async (key, value, loadType = 'replace') => {
    if (listInscriptionPending || searchPending) return

    const numberFilterProps = {
      withNumber: false,
      withoutNumber: false,
      [key]: value
    }

    const stateSelected = getCurrentState()

    handleSelected(null)

    await dispatch(
      loadListInscriptions(
        event.id,
        searchInputText,
        1,
        stateSelected,
        selectedFilter.saleOrderState,
        currentPageSize,
        selectedFilter.orderBy,
        selectedFilter.orderDirection,
        loadType,
        numberFilterProps.withNumber,
        numberFilterProps.withoutNumber
      )
    )

    setSelectedFilter({ ...selectedFilter, ...numberFilterProps })
  }

  const handleSelected = (inscription) => {
    if (isInscriptionsPage) return

    if (showAssignNumberForm) {
      setShowAssignNumberForm(inscriptionSelected?.id === inscription?.id)
    } else {
      const canAssignNumber =
        !!bibNumbers.length &&
        bibNumbers.some((x) => !x.assignedAt) &&
        !!inscription &&
        inscription.state === EVENT_INSCRIPTION_STATES.ACCEPTED &&
        !inscription.number

      setShowAssignNumberForm(canAssignNumber)
    }

    setInscriptionSelected(inscription)
  }

  const renderLoading = () => (
    <div className={classes.loadingContainer}>
      <Typography variant='h3'>Cargando...</Typography>
    </div>
  )

  const assignNumberSuccess = (inscription) => {
    setInscriptionState(inscription)
    setOpenAssignNumberDialog(true)
    handleSelected(null)

    if (numberFilterOnlyApply) {
      dispatch(
        loadListInscriptions(
          event.id,
          searchInputText,
          1,
          getCurrentState(),
          selectedFilter.saleOrderState,
          currentPageSize,
          selectedFilter.orderBy,
          selectedFilter.orderDirection,
          'replace',
          selectedFilter.withNumber,
          selectedFilter.withoutNumber
        )
      )
    } else {
      const inscriptions = [...listInscription]

      const index = inscriptions.findIndex((x) => x.id === inscription.id)

      inscriptions[index] = inscription
      dispatch(setListinscription(inscriptions))
    }

    dispatch(loadBibNumbers(event.id))

    dispatch(loadListInscriptionSearchMetadata(event.id, searchInputText))
  }

  const getCurrentState = () => {
    if (isInscriptionsPage) return selectedFilter.state

    if (!searchInputText) return EVENT_INSCRIPTION_STATES.ACCEPTED

    return SELECT_FILTER_INITIAL_VALUE.state
  }

  const inscriptionFilterOnlyApply =
    !searchInputText &&
    (selectedFilter.state !== SELECT_FILTER_INITIAL_VALUE.state ||
      selectedFilter.saleOrderState !== SELECT_FILTER_INITIAL_VALUE.saleOrderState)

  const numberFilterOnlyApply =
    !searchInputText && (selectedFilter.withNumber || selectedFilter.withoutNumber)

  const submitInscriptionsSearch = async (e) => {
    e?.preventDefault()

    handleSelected(null)

    const stateSelected = getCurrentState()

    await dispatch(
      loadListInscriptions(
        event.id,
        searchInputText,
        1,
        stateSelected,
        selectedFilter.saleOrderState,
        currentPageSize,
        selectedFilter.orderBy,
        selectedFilter.orderDirection,
        'replace',
        selectedFilter.withNumber,
        selectedFilter.withoutNumber
      )
    )

    await dispatch(loadListInscriptionSearchMetadata(event.id, searchInputText))

    setSearchPending(false)
  }

  const debounceOnChange = debounce(submitInscriptionsSearch, 600)

  const hasErrors = () => {
    const { loggedUserMetadata, isSuspended } = event

    if (!loggedUserMetadata.canEdit)
      return 'No tiene los permisos necesarios para acceder a esta pagina. Ponte en contacto con el organizador.'

    if (isSuspended)
      return 'El evento se encuentra suspendido, no puedes acceder al listado de inscriptos.'
  }

  const renderNotFoundPage = () => (
    <div className={classes.notFoundContainer}>
      <Typography color='primary' variant='h5'>
        Ocurrió un error al cargar los inscriptos
      </Typography>
      <Typography color='primary'>Verifique el enlace.</Typography>
    </div>
  )

  const renderPage = () => (!event ? renderNotFoundPage() : renderInscriptionRegisteredListPage())

  const renderInscriptionRegisteredListPage = () => {
    const {
      to,
      organization: eventOwner,
      activeInscriptionForm,
      mercadoPagoCode,
      hasResults,
      isPublished,
      isFree,
      mercadoPagoEnabled
    } = event

    const canEditInscription = moment(to).isSameOrAfter(moment())

    const updateServiceLetterRequired =
      !isFree && !!ticketTypeFees.length && validateServiceLetter(eventOwner, ticketTypeFees)

    const enableInscription =
      isPublished &&
      canEditInscription &&
      !hasResults &&
      !!activeInscriptionForm &&
      !updateServiceLetterRequired &&
      (isFree ||
        !mercadoPagoCode ||
        mercadoPagoEnabled ||
        !activeInscriptionForm.ticketTypes.every(
          (x) =>
            x.mercadoPagoEnabled &&
            !x.paymentLocationsEnabled &&
            !x.bankAccountsEnabled &&
            !x.paymentUrl
        ))

    const errors = hasErrors()

    const handleChangeSaleOrderState = async (saleOrderState) => {
      if (listInscriptionPending) return

      const state = STRING_EMPTY

      await dispatch(
        loadListInscriptions(
          event.id,
          searchInputText,
          1,
          state,
          saleOrderState,
          currentPageSize,
          selectedFilter.orderBy,
          selectedFilter.orderDirection
        )
      )

      setSelectedFilter({ ...selectedFilter, saleOrderState, state })
    }

    const resetFilter = async () => {
      handleStateFilter(STRING_EMPTY)
      handleChangeSaleOrderState(STRING_EMPTY)
    }

    const filterClean = async () => {
      if (!isInscriptionsPage) {
        const currentState = getCurrentState()

        await dispatch(
          loadListInscriptions(
            event.id,
            searchInputText,
            1,
            currentState,
            selectedFilter.saleOrderState,
            currentPageSize,
            selectedFilter.orderBy,
            selectedFilter.orderDirection
          )
        )

        handleSelected(null)
        setSelectedFilter({ ...SELECT_FILTER_INITIAL_VALUE })
      } else {
        await handleChangeSaleOrderState(STRING_EMPTY)
      }

      scroll.scrollToTop()
    }

    const redirectEventProfile = () => history.push(`${ROUTES.EVENTS.PROFILE}/${slugUrlOrId}`)

    const serviceLetterUpdateSuccess = () => {
      showSnackbarSuccess('¡Carta de servicio actualizada correctamente!')
      setOpenSignServiceLetterDialog(false)
      history.push(`${ROUTES.EVENTS.INSCRIPTION}/${event.slugUrl}`)
    }

    const disabledAssignedNumber =
      !canEditInscription ||
      !bibNumbers.length ||
      bibNumbers.every((x) => x.assignedAt) ||
      !inscriptionSelected ||
      inscriptionSelected.state !== EVENT_INSCRIPTION_STATES.ACCEPTED ||
      !!inscriptionSelected?.number

    const hasInscriptionWithoutNumber =
      bibNumbers.some((x) => !x.assignedAt) &&
      inscriptionSelected &&
      inscriptionSelected.state === EVENT_INSCRIPTION_STATES.ACCEPTED &&
      !inscriptionSelected.number

    const getCalculateCount = (state) => {
      if (state === EVENT_INSCRIPTION_STATES.CANCELLED)
        return stateItems[selectedFilter.state] - orderItems[SALE_ORDER_STATES.EXPIRED] || 0

      return stateItems[selectedFilter.state]
    }

    const getFilterTabProps = () => {
      if (isInscriptionsPage)
        return {
          filterSelected:
            selectedFilter.saleOrderState !== STRING_EMPTY
              ? selectedFilter.saleOrderState
              : selectedFilter.state,
          title: event.name,

          filterCount:
            selectedFilter.saleOrderState !== STRING_EMPTY
              ? orderItems[selectedFilter.saleOrderState]
              : getCalculateCount(selectedFilter.state)
        }
      else
        return {
          filterCountHidden: true,
          title: `${event.name} - Asignar números`,
          filterSelected:
            (selectedFilter.withNumber && 'withNumber') ||
            (selectedFilter.withoutNumber && 'withoutNumber') ||
            STRING_EMPTY
        }
    }

    return !errors ? (
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <Typography
            color='primary'
            variant='h6'
            className={classes.eventName}
            onClick={redirectEventProfile}>
            {event.name} {!isDesktop && !isInscriptionsPage && '- Asignar números'}
          </Typography>
        </div>

        {!isDesktop && (inscriptionFilterOnlyApply || numberFilterOnlyApply) && (
          <InscriptionFilterTab {...getFilterTabProps()} filterClean={filterClean} />
        )}

        <div className={classes.bodyContainer}>
          <div className={classes.mainContainer}>
            {pageMode === INSCRIPTIONS_PAGE_STATES.ASSIGN_NUMBER ? (
              <div className={classes.assignNumberHeaderContainer}>
                <Link to={inscriptionListPath} className={classes.returnInscriptionListLink}>
                  <ArrowLeftIcon color='primary' /> Volver a inscripciones
                </Link>
                <Typography color='primary' variant='h6' className={classes.title} align='center'>
                  Asignación de números
                </Typography>
              </div>
            ) : (
              <Typography color='primary' variant='h6' className={classes.title} align='center'>
                INSCRIPCIONES
              </Typography>
            )}

            {isInscriptionsPage ? (
              <div
                className={clsx(
                  classes.buttonsSection,
                  !isDesktop && (numberFilterOnlyApply || inscriptionFilterOnlyApply) && 'hidden'
                )}>
                {!!activeInscriptionForm?.allInscriptionsCount && (
                  <Button
                    color='primary'
                    disabled={downloadInscriptionsSheetPending}
                    endIcon={downloadInscriptionsSheetPending && <CircularProgress size={16} />}
                    variant='contained'
                    className={classes.optionButton}
                    onClick={() => setOpenDownloadListsDialog(true)}>
                    Descargar listado
                  </Button>
                )}

                {canEditInscription && (
                  <Button
                    color='secondary'
                    variant='contained'
                    disabled={!activeInscriptionForm?.allInscriptionsCount}
                    className={classes.assignNumberButton}
                    to={`/events/${slugUrlOrId}/assign-number`}
                    component={Link}>
                    Asignar número
                  </Button>
                )}

                {enableInscription && (
                  <Button
                    variant='outlined'
                    color='primary'
                    disabled={!event.isPublished}
                    className={classes.optionButton}
                    endIcon={
                      <CustomizedTooltip
                        title='Sólo podrás inscribir a un deportista si hay cupo disponible.'
                        position={isDesktop ? 'right-end' : 'bottom'}
                        className={classes.tooltip}
                        arrowClassName={classes.tooltipArrow}>
                        {({ handleTooltip, handleTooltipClose }) => (
                          <InfoIcon
                            color='primary'
                            className={clsx(
                              classes.icon,
                              !event.isPublished && classes.disabledIcon
                            )}
                            onMouseOut={handleTooltipClose}
                            onMouseOver={handleTooltip}
                          />
                        )}
                      </CustomizedTooltip>
                    }
                    onClick={() => setOpenInscriptionOffLineDialog(!openInscriptionOffLineDialog)}>
                    Inscribir
                  </Button>
                )}
              </div>
            ) : (
              <div
                className={clsx(
                  classes.buttonsSection,
                  'assignNumber',
                  !bibNumbers.length && 'alt'
                )}>
                {!bibNumbers.length && (
                  <Typography variant='h6' color='error'>
                    No hay números disponibles, para asignar debes crear números.
                  </Typography>
                )}

                {!bibNumbers.length && isDesktop && (
                  <Button
                    color='primary'
                    variant='contained'
                    className={classes.createNumbersButton}
                    component={Link}
                    to={`/events/${slugUrlOrId}/create-number`}>
                    Crear números
                  </Button>
                )}

                {!isDesktop && (
                  <>
                    {!bibNumbers.length && (
                      <Link
                        to={`/events/${slugUrlOrId}/create-number`}
                        className={classes.createNumbersLink}>
                        Crear números
                      </Link>
                    )}

                    <div className={clsx(classes.assignContainer, showAssignNumberForm && 'full')}>
                      {showAssignNumberForm && (
                        <AssignNumberForm
                          currentPageSize={currentPageSize}
                          inscription={inscriptionSelected}
                          searchInputText={searchInputText}
                          currentInscriptionState={getCurrentState()}
                          selectedFilter={selectedFilter}
                          onSuccess={assignNumberSuccess}
                        />
                      )}

                      <AssignNumberDialog
                        currentPageSize={currentPageSize}
                        searchInputText={searchInputText}
                        selectedFilter={selectedFilter}
                        state='infoState'
                        currentInscriptionState={getCurrentState()}
                        open={openAssignNumberDialog}
                        onClose={() => setOpenAssignNumberDialog(false)}
                        inscriptionInfo={inscriptionState}
                      />

                      {!showAssignNumberForm && (
                        <Button
                          color='secondary'
                          variant='contained'
                          disabled={disabledAssignedNumber}
                          className={classes.altAssignNumberButton}>
                          Asignar número
                        </Button>
                      )}
                      {inscriptionSelected &&
                        inscriptionSelected?.state !== EVENT_INSCRIPTION_STATES.ACCEPTED &&
                        !isDesktop && (
                          <CustomizedTooltip
                            title='Sólo podrás asignar número a deportistas con estado inscripto.'
                            arrowClassName={classes.altArrow}
                            className={classes.altTooltip}
                            buttonClassName={classes.altButton}
                            position='bottom'
                            disabledClickAway>
                            {({ handleTooltip }) => (
                              <InfoIcon className={classes.alertIcon} onClick={handleTooltip} />
                            )}
                          </CustomizedTooltip>
                        )}
                    </div>

                    {!!bibNumbers.length && !hasInscriptionWithoutNumber && (
                      <Button
                        color='primary'
                        variant='contained'
                        className={classes.editNumberButton}
                        component={Link}
                        to={`/events/${slugUrlOrId}/edit-number`}>
                        Editar números
                      </Button>
                    )}

                    <IconButton
                      color='primary'
                      className={classes.backButton}
                      component={Link}
                      to={inscriptionListPath}>
                      <ArrowBack />
                    </IconButton>
                  </>
                )}
              </div>
            )}

            {!isDesktop && !inscriptionFilterOnlyApply && !numberFilterOnlyApply && (
              <InscriptionFilter
                mini
                selectedFilter={selectedFilter}
                handleStateFilter={handleStateFilter}
                handleNumberFilter={handleNumberFilter}
                orderItems={orderItems}
                slugUrlOrId={slugUrlOrId}
                mode={pageMode}
                numberItems={numberItems}
                stateItems={stateItems}
                handleChangeSaleOrderState={handleChangeSaleOrderState}
                resetFilter={resetFilter}
              />
            )}

            {pageMode === INSCRIPTIONS_PAGE_STATES.ASSIGN_NUMBER && (
              <>
                {isDesktop ? (
                  <Typography color='primary' variant='h6' className={classes.assignHelperText}>
                    Buscá el deportista o aplicá alguno de los filtros para encontrar al deportista
                    al cual quieres asignar número.
                  </Typography>
                ) : (
                  !numberFilterOnlyApply && (
                    <Typography color='primary' variant='h6' className={classes.assignHelperText}>
                      Buscá el deportista para asignar número
                    </Typography>
                  )
                )}

                {(!numberFilterOnlyApply || isDesktop) && (
                  <Typography variant='h6' color='primary' className={classes.assignInfo}>
                    (Sólo podrás asignar número a deportistas inscriptos).
                  </Typography>
                )}
              </>
            )}

            {isInscriptionsPage && !activeInscriptionForm.isFree && isDesktop && (
              <div className={classes.paymentInfoContainer}>
                <Typography color='primary' className={classes.paymentInfo} variant='h6'>
                  En el listado podrás ver el estado de los inscriptos a tu evento. Si la
                  inscripción fue abonada por transferencia o en efectivo, deberás modificar cada
                  estado manualmente. Sólo las inscripciones abonadas on line actualizan su estado
                  automáticamente.
                </Typography>
                <div className={classes.paymentMethodContainer}>
                  <Typography variant='h6' color='primary' className={classes.paymentMethodTitle}>
                    Medios de pago habilitados del evento:
                  </Typography>
                  <div className={classes.paymentMethodEnabled}>
                    {event.mercadoPagoEnabled && <span>Mercado Pago </span>}
                    {event.eventBankAccountsEnabled && <span>Transferencia Bancaria </span>}
                    {event.eventPaymentLocationsEnabled && <span>Pago en Efectivo</span>}
                    {event.activeInscriptionForm.ticketTypes.some(
                      (x) => x.isActive && !!x.paymentUrl
                    ) && <span>Link de Pago</span>}
                  </div>
                </div>
              </div>
            )}

            {(isDesktop || (!inscriptionFilterOnlyApply && !numberFilterOnlyApply)) && (
              <div className={classes.searchContainer}>
                <form onSubmit={submitInscriptionsSearch} role='search'>
                  <div className={classes.searchSection}>
                    <TextField
                      color='primary'
                      variant='outlined'
                      autoComplete='off'
                      value={searchInputText}
                      size='small'
                      type='search'
                      className={classes.field}
                      label='Buscar deportista'
                      helperText={
                        isInscriptionsPage
                          ? 'Búsqueda por nombre, apellido, DNI, categoría'
                          : 'Ingresá DNI, nombre, apellido o categoría del deportista inscripto'
                      }
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            type='submit'
                            color='primary'
                            className={classes.searchButton}
                            onClick={(e) => {
                              if (isDesktop) e.preventDefault()
                            }}>
                            <SearchIcon />
                          </IconButton>
                        )
                      }}
                      onChange={(e) => setSearchInputText(e.target.value)}
                    />
                  </div>
                </form>

                {isDesktop && (
                  <InscriptionFilter
                    selectedFilter={selectedFilter}
                    stateItems={stateItems}
                    mode={pageMode}
                    slugUrlOrId={slugUrlOrId}
                    handleNumberFilter={handleNumberFilter}
                    numberItems={numberItems}
                    orderItems={orderItems}
                    handleStateFilter={handleStateFilter}
                    resetFilter={resetFilter}
                    handleChangeSaleOrderState={handleChangeSaleOrderState}
                  />
                )}
              </div>
            )}

            <RegisteredList
              currentPageSize={currentPageSize}
              searchInputText={searchInputText}
              mode={pageMode}
              currentInscriptionState={getCurrentState()}
              handleSelected={handleSelected}
              numberFilterOnlyApply={numberFilterOnlyApply}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              inscriptionSelected={inscriptionSelected}
              isInscriptionsPage={isInscriptionsPage}
            />
          </div>
        </div>

        <SignServiceLetterDialog
          open={openSignServiceLetterDialog}
          action={eventOwner.hasActiveContract ? 'update' : 'create'}
          onUpdateSuccess={serviceLetterUpdateSuccess}
          onClose={() => setOpenSignServiceLetterDialog(false)}
        />

        <InscriptionOffLineDialog
          title={event.name}
          open={openInscriptionOffLineDialog}
          onClose={() => setOpenInscriptionOffLineDialog(false)}
        />

        <DownloadListsDialog
          open={openDownloadListsDialog}
          onClose={() => setOpenDownloadListsDialog(false)}
        />

        <UpdateServiceLetterInfoDialog
          open={openUpdateServiceLetterInfoDialog}
          onAccept={() => {
            setOpenUpdateServiceLetterInfoDialog(false)
            setOpenSignServiceLetterDialog(true)
          }}
          onClose={() => setOpenUpdateServiceLetterInfoDialog(false)}
        />
      </div>
    ) : (
      <div className={classes.messageContainer}>
        <Typography variant='h5' color='primary'>
          {errors}
        </Typography>
      </div>
    )
  }
  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />
      <PageContainer altMode={activeLoading}>
        {activeLoading ? renderLoading() : renderPage()}
      </PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default InscriptionRegisteredListPage
