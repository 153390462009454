import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  addLink: {
    cursor: 'pointer',
    fontWeight: 600,
    textDecoration: 'underline',
    fontSize: '.875rem',

    '&.disabled': {
      color: theme.palette.disabled.main,
      cursor: 'auto'
    }
  },
  saveButton: {
    width: 200,
    height: 48,
    borderRadius: 10
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    margin: theme.spacing(2, 0)
  },
  combo: {
    width: '100%',

    '& > div > label': {
      transform: 'translate(14px, 10px) scale(1)'
    },
    '& > div > div': {
      paddingBottom: `0 !important`,
      paddingTop: `0 !important`,
      paddingLeft: `${theme.spacing()} !important`,
      paddingRight: `${theme.spacing()} !important`
    },
    '& > div > div > div': {
      zIndex: 2
    },
    '& svg': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up('md')]: {
      width: 450
    }
  },
  option: {
    zIndex: '2 !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    color: theme.palette.secondary.contrastText,
    margin: theme.spacing(1, 0),
    marginRight: theme.spacing()
  },
  selectOption: {
    backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.8),
    width: '100%',
    borderRadius: '50px 0 0 50px',
    display: 'flex',
    gap: theme.spacing(2),

    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  input: {
    flexGrow: 1,
    '& fieldset': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      minHeight: 45
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing()
    },

    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.contrastText,
      paddingRight: theme.spacing()
    },
    '& input': {
      zIndex: 1,
      fontWeight: 500
    },
    '& > p': {
      color: theme.palette.secondary.contrastText
    }
  },
  imageContainer: {
    width: 50,
    height: 50,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 50,
    flexShrink: 0
  },
  timingName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '.875rem',
    width: 'calc(100vw - 200px)',
    [theme.breakpoints.up('md')]: {
      width: 300
    }
  },
  timekeeperName: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '.875rem'
  },
  profileImage: {
    width: 80,
    height: 80,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 50,
    flexShrink: 0
  },
  timeKeeperContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',

    '&.clicked': {
      cursor: 'pointer'
    }
  }
}))
