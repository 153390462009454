import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '69.2vh'
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.primary.contrastText,
    flexDirection: 'column',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    marginBottom: theme.spacing(),
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      minHeight: theme.spacing(8),
      padding: theme.spacing(0, 10)
    }
  },
  eventName: {
    textAlign: 'center',
    padding: theme.spacing(2, 4),
    fontSize: '1rem',
    cursor: 'pointer',

    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem'
    }
  },
  backButton: {
    alignSelf: 'center',

    '& svg': {
      width: 50,
      height: 50
    },

    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: 1280,
      padding: theme.spacing(0, 4),
      margin: '0 auto'
    }
  },
  assignNumberHeaderContainer: {
    position: 'relative'
  },
  title: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: '1.25rem'
    }
  },
  returnAssignNumberLink: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      marginTop: theme.spacing(2),
      fontWeight: 500,
      textDecoration: 'none',
      fontSize: '1rem',
      position: 'absolute',
      left: 0,

      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
}))
