import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),
    paddingTop: 0
  },
  dialog: {
    alignSelf: 'center',
    backgroundColor: theme.palette.primary.contrastText,

    [theme.breakpoints.up('md')]: {
      width: '70%',
      height: 'calc(100% - 60px)'
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1920,
      margin: '0 auto'
    }
  },
  altInput: {
    flexGrow: 1,
    '& fieldset': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      minHeight: 45
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing()
    },

    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.contrastText,
      paddingRight: theme.spacing(),
      zIndex: 1
    },
    '& input': {
      zIndex: 1,
      fontWeight: 500
    },
    '& > p': {
      color: theme.palette.secondary.contrastText
    }
  },
  combo: {
    width: '100%',

    '& > div > label': {
      transform: 'translate(14px, 10px) scale(1)'
    },
    '& > div > div': {
      paddingBottom: `0 !important`,
      paddingTop: `0 !important`,
      paddingLeft: `${theme.spacing()} !important`,
      paddingRight: `${theme.spacing()} !important`
    },
    '& > div > div > div': {
      zIndex: 2
    },
    '& svg': {
      color: theme.palette.primary.main
    },

    [theme.breakpoints.up('md')]: {
      width: 350
    }
  },
  option: {
    width: '100%',
    height: '100%',
    border: `1px solid ${theme.palette.primary.main}`,

    '&:hover': {
      backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.6)
    },

    '&.MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  optionLabel: {
    color: theme.palette.secondary.contrastText,
    fontWeight: 500,

    '&:hover': {
      color: theme.palette.primary.main
    },

    '&.selected': {
      backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.8)
    }
  },
  optionAlertLabel: {
    color: `${theme.palette.error.main} !important`
  },

  optionAlert: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(),
    gap: theme.spacing(),

    '& p': {
      fontSize: '.875rem',
      fontWeight: 500
    },

    '&.full': {
      marginTop: theme.spacing(2)
    }
  },
  numberContainer: {
    marginTop: theme.spacing(4)
  },
  headerContainer: {
    height: 77,
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    justifyContent: 'center',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    padding: theme.spacing(0, 2),
    '&.alt': {
      height: 60,
      backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2),
      boxShadow: 'none',

      '& h5': {
        fontWeight: 600
      }
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    marginLeft: theme.spacing(4)
  },
  title: {
    fontWeight: 500,
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    fontSize: '1rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem'
    }
  },

  button: {
    alignSelf: 'center',
    margin: theme.spacing(6, 0),
    padding: theme.spacing(1, 2.5)
  },
  backButton: {
    padding: 15,
    alignSelf: 'center',
    marginBottom: theme.spacing(5),
    '&  svg': {
      fontSize: '3rem',
      width: '100%',
      fontWeight: 600,
      lineHeight: '20px'
    }
  },

  sectionsContainer: {
    width: '80%',
    margin: '10px auto'
  },
  observationContainer: {
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
  },
  observation: {
    marginTop: theme.spacing(5),
    margin: '0 auto',
    width: '80%',
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',

    '&.alt': {
      marginTop: 0,
      minHeight: 'auto'
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      minHeight: 'auto',
      justifyContent: 'space-between'
    }
  },
  observationSelectFormGroup: {
    marginRight: 30,
    width: 100,

    '&.center': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },

    '& > h5': {
      fontWeight: 500,
      marginBottom: theme.spacing(2)
    }
  },
  assignNumberFormGroup: {
    width: '100%',
    marginBottom: theme.spacing(3),

    '& > h5': {
      fontSize: '.75rem'
    },

    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& span': {
      color: theme.palette.primary.main,
      '& h6': {
        fontSize: '.875rem',
        color: theme.palette.secondary.contrastText,
        fontWeight: 400
      }
    },
    '&.column': {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    }
  },
  formGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      '& > div': {
        width: '48%'
      },
      flexDirection: 'row'
    }
  },
  formGroup: {
    padding: theme.spacing(1, 0),
    '& h6': {
      fontSize: '.8rem',
      marginBottom: 2
    },
    '&.disabled': {
      '& h6': {
        color: theme.palette.disabled.main
      },
      '& svg': {
        color: '#ffff'
      },
      '& > div > div': {
        borderColor: theme.palette.disabled.main
      }
    },

    '& input': {
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  fieldContainer: {
    minHeight: 80,
    width: '100%',
    '& > div': {
      paddingBottom: theme.spacing()
    },
    '& > span': {
      marginLeft: theme.spacing()
    },
    [theme.breakpoints.up('md')]: {
      width: '48%',
      minHeight: 'auto'
    }
  },
  select: {
    width: '100%',
    '& svg': {
      color: theme.palette.secondary.main
    },

    '& > div': {
      borderColor: theme.palette.primary.main,
      borderRadius: 0,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
    },
    '& :nth-of-type(2)': {
      zIndex: 2
    }
  },
  textField: {
    width: '100%',
    '& fieldset': {
      height: 43,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
      borderColor: theme.palette.primary.main
    }
  },
  input: {
    marginBottom: theme.spacing(),
    flexGrow: 1,
    '& fieldset': {
      borderColor: theme.palette.primary.main
    },
    '&.datePicker': {
      '& > div': {
        paddingRight: 0,
        height: 40
      },
      '& button': {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: '#ffff',
        width: 40,
        height: 40
      },
      '& input': {
        padding: theme.spacing(1.1, 1.8)
      }
    },
    '&.multiline': {
      '& textarea': {
        minHeight: '100px'
      }
    }
  },
  comboxsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'column',
    minHeight: 130,
    alignItems: 'center',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('md')]: {
      minHeight: 'auto',
      alignItems: 'start',
      flexDirection: 'row'
    }
  },
  helperText: {
    marginTop: theme.spacing(),
    fontSize: '0.8125rem',
    fontStyle: 'italic'
  },
  separatorContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing()
  },
  termAndConditionsLabel: {
    color: 'rgba(150, 150, 150, 1)',
    fontSize: '0.8125rem',
    '& strong': {
      marginLeft: theme.spacing(0.5),
      color: theme.palette.primary.main,
      fontWeight: 400,
      textDecoration: 'underline'
    }
  },
  buttonsContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    flexDirection: 'column',
    justifyContent: 'center',
    height: 100,
    alignItems: 'center'
  },
  textAreaEdit: {
    width: '100%',
    '& > div > textArea': {
      minHeight: 150,
      color: theme.palette.primary.main,
      '&::placeholder': {
        color: 'rgba(105, 105, 105, 1)'
      },
      [theme.breakpoints.up('md')]: {
        minHeight: 50
      }
    },
    padding: theme.spacing(2, 0),
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  saveContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '&.mini': {
      width: '90%'
    }
  },

  saveButton: {
    width: 200,
    padding: theme.spacing(1, 2),
    borderRadius: 10
  },
  observationTextFieldContainer: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 1
    },
    '& > h5': {
      fontWeight: 500
    }
  },
  submitContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'flex-end',
    [theme.breakpoints.up('md')]: {
      height: 100,
      flexDirection: 'row'
    }
  },
  tosParagraph: {
    color: 'rgba(150, 150, 150, 1)',
    fontSize: '0.8125rem',
    '& a': {
      color: theme.palette.link.main,
      fontWeight: 600
    }
  },
  citiesContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    '& > div': {
      width: '100%'
    }
  },
  otherOptionText: {
    marginTop: 22,
    width: '100%'
  },
  optionOtherContainer: {
    width: '160px !important',
    flexShrink: 0
  },
  helperTextPhone: {
    fontSize: '.875rem !important',
    fontWeight: 600,
    marginTop: '10px'
  },
  phone: {
    '& input': {
      color: theme.palette.primary.main
    }
  },
  popper: {
    zIndex: 1300
  },
  expiredStateContainer: {
    marginLeft: theme.spacing(1.4),
    marginTop: theme.spacing(3.2)
  },
  inscriptionInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },

  miniObservation: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    alignItems: 'center'
  },
  changeDialogStateLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    alignSelf: 'center',
    marginTop: theme.spacing(3),
    fontWeight: 600
  }
}))
