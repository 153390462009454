import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'
import { Typography } from '@material-ui/core'
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram-icon.svg'
import { ReactComponent as PinardLogo } from 'assets/icons/pinard-logo.svg'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import { PrivacyPolicyContent, TermsAndConditionsContent, TermsAndConditionsDialog } from 'shared'

import useStyles from './Footer.style'

const Footer = ({ altMode }) => {
  const classes = useStyles()
  const location = useLocation()

  const [openTerms, setOpenTerms] = useState(false)
  const [openPolicy, setOpenPolicy] = useState(false)

  const showFooterEvent = (location) => {
    const route =
      location.pathname.includes(ROUTES.EVENTS.PROFILE) ||
      location.pathname.includes(ROUTES.EVENTS.INSCRIPTION)

    setShowFooterContent(route)
  }

  const [showFooterContent, setShowFooterContent] = useState(false)

  useEffect(() => {
    if (location) showFooterEvent(location)
  }, [showFooterContent, location])

  const handleOpen = (setOpen) => (e) => {
    e.preventDefault()

    setOpen(true)
  }

  return (
    <div className={clsx(classes.container, altMode && classes.containerAlt)}>
      <div className={clsx(classes.innerContainer, showFooterContent && 'showFooterContent')}>
        <div className={clsx(classes.logo, showFooterContent && 'align-start')} />
        {!showFooterContent && (
          <div className={classes.footerBlock}>
            <ul className={classes.list}>
              <li>
                <Link
                  to={`${ROUTES.LANDING.ORGANIZATION}?section=usSection`}
                  className={classes.link}>
                  Sobre nosotros
                </Link>
                <Link to={ROUTES.USERS_ACCOUNTS.FREQUENT_QUESTIONS} className={classes.link}>
                  Preguntas frecuentes
                </Link>

                <Typography color='primary' className={classes.label}>
                  <Link to='#' className={classes.link} onClick={handleOpen(setOpenTerms)}>
                    Condiciones de uso
                  </Link>
                  &nbsp;y la&nbsp;
                  <Link className={classes.link} onClick={handleOpen(setOpenPolicy)} to='#'>
                    Política <br />
                    de privacidad
                  </Link>
                  &nbsp;de Sportmetric
                </Typography>
              </li>
              <li className={classes.altItem}>
                <Link
                  to={ROUTES.LANDING.ORGANIZATION}
                  color='primary'
                  className={clsx(classes.link, classes.altLabel)}>
                  ¿Organizas eventos deportivos?
                </Link>
                <div>
                  <Typography color='primary' className={classes.altLabel}>
                    ¿Tenes algún problema?
                  </Typography>
                  <Typography className={classes.label}>
                    <strong>Escribí a:</strong>
                    &nbsp;
                    <a
                      href='mailto: soporte@sportmetric.net'
                      target='_blank'
                      className={classes.link}
                      title='soporte@sportmetric.net'
                      rel='noopener noreferrer'>
                      soporte@sportmetric.net
                    </a>
                  </Typography>
                </div>
              </li>
              <li className={classes.lastFooterItem}>
                <Typography color='primary' className={classes.label}>
                  WhatsApp
                  <br />
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://wa.me/5493885205251?text=Hola,%20quisiera%20más%20información%20sobre%20el%20producto'
                    className={classes.altLink}>
                    +54 9 388 520 5251
                  </a>
                </Typography>
                <div className={classes.followContainer}>
                  <Typography color='primary'>Seguinos en</Typography>
                  <a
                    href='https://www.instagram.com/sportmetric.oficial/'
                    target='_blank'
                    title='Instagram'
                    className={classes.instagramIcon}
                    rel='noopener noreferrer'>
                    <InstagramIcon />
                  </a>
                  <SocialIcon
                    className={classes.facebookIcon}
                    network='facebook'
                    title='facebook'
                    bgColor='transparent'
                    fgColor='#0600ad'
                    url='https://www.facebook.com/sportmetric.net/'
                    target='_blank'
                  />
                </div>

                <a
                  href='https://pinard.com.ar/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className={classes.developmentInfo}>
                  <Typography className={classes.developmentText}>Desarrollado por</Typography>
                  <PinardLogo className={classes.pinardLogo} />
                </a>
              </li>
            </ul>

            <TermsAndConditionsDialog
              handleClose={() => setOpenTerms(false)}
              open={openTerms}
              content={TermsAndConditionsContent}
              title='Condiciones de uso'
            />
            <TermsAndConditionsDialog
              handleClose={() => setOpenPolicy(false)}
              open={openPolicy}
              content={PrivacyPolicyContent}
              title='Política de privacidad'
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Footer
