import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import {
  Check as CheckIcon,
  MailOutline as MailOutlineIcon,
  WhatsApp as WhatsAppIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { EllipseStatus } from 'shared'
import { accreditEventInscription } from 'state/modules/events'
import { EVENT_INSCRIPTION_STATES, SALE_ORDER_STATES } from 'utils/constants'
import { loadAccreditTicket } from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'

import { AccreditDialog } from '../AccreditDialog'
import { AccreditTicketDialog } from '../AccreditTicketDialog'
import { EditInscriptionFormDialog } from '../EditInscriptionFomDialog'
import { InfoInscriptionDialog } from '../InfoInscriptionDialog'

import { useStyles } from './InscriptionCard.style'

const EVENT_INSCRIPTION_STATE_OPTIONS = {
  [EVENT_INSCRIPTION_STATES.PENDING]: {
    label: 'PREINSCRIPTO',
    icon: <EllipseStatus status='yellow' title='PREINSCRIPTO' />
  },

  [EVENT_INSCRIPTION_STATES.ACCEPTED]: {
    label: 'INSCRIPTO',
    icon: <EllipseStatus status='green' title='INSCRIPTO' />
  },

  [EVENT_INSCRIPTION_STATES.CANCELLED]: {
    label: 'ANULADO',
    icon: <EllipseStatus status='red' title='ANULADO' />
  },

  [SALE_ORDER_STATES.EXPIRED]: {
    label: 'VENCIDO',
    icon: <EllipseStatus status='grey' title='VENCIDO' />
  }
}

const InscriptionCard = ({
  inscription = {},
  editProps,
  paymentComponent,
  cardSelected = false,
  onSelected = () => {},
  canClicked,
  readOnly = false
}) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const [openAccreditDialog, setOpenAccreditDialog] = useState(false)
  const [openAccreditTicketDialog, setOpenAccreditTicketDialog] = useState(false)
  const [pending, setPending] = useState(false)
  const [fileUrl, setfileUrl] = useState(null)

  const { event } = useSelector((state) => state.events)

  const {
    firstName,
    lastName,
    idNumber,
    distance,
    category,
    state,
    hasWhatsApp,
    id,
    accreditedAt,
    phone,
    eMail,
    number: bibNumber
  } = inscription

  const inscriptionState = EVENT_INSCRIPTION_STATE_OPTIONS[state]

  const handleSelectedInscription = (inscription) => onSelected(inscription)

  const handleAccreditInscription = async (inscription, page = 1) => {
    try {
      setPending(true)
      handleSelectedInscription(inscription)

      if (!accreditedAt) {
        const data = await dispatch(accreditEventInscription(id))

        await editProps.load(page, data)
      }

      const fileUrl = await loadAccreditTicket(inscription)
      setOpenAccreditDialog(true)
      setfileUrl(fileUrl)
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setPending(false)
    }
  }

  return (
    <div
      className={clsx(classes.card, cardSelected && 'selected', canClicked && 'clicked')}
      onClick={() => handleSelectedInscription(!cardSelected ? inscription : null)}>
      <Typography className={classes.infoText}>
        {lastName} {firstName}
      </Typography>

      <Typography className={classes.infoText}>
        <span>D.N.I:</span> {idNumber}
      </Typography>

      <Typography className={classes.infoText}>
        <span>Distancia:</span> {distance.name}
      </Typography>
      <Typography className={classes.infoText}>
        <span>Categoría:</span> {category.name}
      </Typography>
      <div className={clsx(classes.infoText, classes.stateInfo)}>
        <span>Estado:</span>
        <div className={classes.state}>
          {inscriptionState.icon} {inscriptionState.label}
        </div>
      </div>

      <Typography className={classes.infoText}>
        <span>Número asignado: </span>
        {!bibNumber ? '-' : bibNumber.number}
      </Typography>

      {!readOnly && (
        <div className={classes.actionButtons} onClick={(e) => e.stopPropagation()}>
          {paymentComponent}

          {!hasWhatsApp ? (
            <InfoInscriptionDialog
              field={inscription}
              onCardSelected={() => handleSelectedInscription(inscription)}
            />
          ) : (
            <Button
              color='primary'
              variant='contained'
              href={`https://wa.me/${phone}?text=Desde%20la%20organización%20${event.organization.name}%20nos%20comunicamos%20por%20el%20evento%20${event.name}%20`}
              target='_blank'
              rel='noopener noreferrer'
              className={classes.actionButton}>
              <WhatsAppIcon />
            </Button>
          )}
          <Button
            color='primary'
            variant='contained'
            href={`mailto:${eMail}`}
            target='_blank'
            rel='noopener noreferrer'
            className={classes.actionButton}>
            <MailOutlineIcon />
          </Button>

          {editProps.canEditInscription && (
            <EditInscriptionFormDialog
              title={event.name}
              onSelected={() => handleSelectedInscription(inscription)}
              load={(page, inscription) => editProps.load(page, inscription)}
              inscription={inscription}
              handleOpen={editProps.handleOpenWarning}
            />
          )}

          <Button
            color={accreditedAt ? 'secondary' : 'primary'}
            variant='contained'
            className={classes.actionButton}
            disabled={!bibNumber || pending}
            onClick={() => handleAccreditInscription(inscription)}>
            {!pending ? <CheckIcon /> : <CircularProgress size={16} color='primary' />}
          </Button>

          <AccreditDialog
            open={openAccreditDialog}
            onClose={() => setOpenAccreditDialog(false)}
            inscription={inscription}
            loadInscriptions={(page, inscription) => editProps.load(page, inscription)}
            handleOpenAccreditTicket={() => {
              setOpenAccreditDialog(false)
              setOpenAccreditTicketDialog(true)
            }}
          />
          <AccreditTicketDialog
            open={openAccreditTicketDialog}
            onClose={() => setOpenAccreditTicketDialog(false)}
            fileUrl={fileUrl}
          />
        </div>
      )}
    </div>
  )
}

export default InscriptionCard
