import React from 'react'
import { Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './InfoDialog.style'

const InfoDialog = ({ numbers, open, onClose }) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      title='Error al crear números'
      open={open}
      onClose={onClose}
      className={classes.dialog}>
      <div className={classes.container}>
        <Typography>El/ los número/s:</Typography>
        <Typography color='primary'>{numbers.join(', ')}</Typography>
        <Typography>ya existe/existen.</Typography>
      </div>
    </SectionLayoutDialog>
  )
}

export default InfoDialog
