import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { Close as CloseIcon, Error as ErrorIcon, Info as InfoIcon } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import altNoImage from 'assets/img/blue-user-mock-alt.jpg'
import noImage from 'assets/img/user-mock.png'
import clsx from 'clsx'
import { Formik } from 'formik'
import debounce from 'lodash.debounce'
import { SearchWidget } from 'pages/HomePage/components'
import { ROUTES } from 'routes'
import { CountriesProxy, UserAccountsProxy } from 'services'
import {
  AdministratorPermissionDialog,
  CreateOrganizationInfoDialog,
  CustomizedTooltip,
  Footer,
  MainLayout,
  NavBar,
  PageContainer,
  PrivacyPolicyContent,
  StyledSwitch,
  TermsAndConditionsDialog
} from 'shared'
import { setActiveRole } from 'state/modules/app'
import { create } from 'state/modules/organizations'
import { loadOrganizationsAdminAll } from 'state/modules/userAccounts'
import { STRING_EMPTY, USER_ROLE } from 'utils/constants'
import { getCountries, removeDiacritics, searchDisciplines, validateCuit } from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'
import * as Yup from 'yup'

import { OrganizationListDialog, TermsAndConditionsOrganizationContent } from './components'
import useStyles from './CreatePage.style'

const ARGENTINA_COUNTRY = { label: 'Argentina', value: '01fa2de7-519f-4089-a33c-d48483ea0f20' }
const CUIT_LENGTH = 11

const CreatePage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const { pending } = useSelector((state) => state.organizations)
  const { user, userData } = useSelector((state) => state.auth)
  const { organizationsAdminAll } = useSelector((state) => state.userAccounts)

  const [countries, setcountries] = useState([])
  const [provinces, setProvinces] = useState([])
  const [disciplines, setDisciplines] = useState([])
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [openOrganizationListDialog, setOpenOrganizationListDialog] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)
  const [openAdministratorDialog, setOpenAdministratorDialog] = useState(false)
  const [openTerms, setOpenTerms] = useState(false)
  const [openPolicy, setOpenPolicy] = useState(false)
  const [openCreateOrganizationDialog, setOpenCreateOrganizationDialog] = useState(false)

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const NAVBAR_TRANSPARENT_SCROLL = isDesktop ? 25 : 20
  const empty = { value: '', label: '' }

  const fetchCountries = async () => setcountries(await getCountries()(''))
  const fetchDisciplines = async () => setDisciplines(await searchDisciplines(''))

  const fetchUsers = async (searchText = '', setFieldValue, values) => {
    setFieldValue('usernameOrEmail', searchText)

    if (!searchText || searchText.length < 3) {
      setOptions([])
      return
    }

    try {
      setLoading(true)

      const proxy = new UserAccountsProxy()
      const data = await proxy.getUserAccountOrganizationSuggestions(searchText, 1, 20)

      setOptions([...values.userAdmins, ...data.results])

      return data.results
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop)
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollTop])

  useEffect(() => {
    if (userData && userData.isOrganizer) {
      dispatch(setActiveRole(USER_ROLE.ORGANIZER))
    }
  }, [userData])

  useEffect(async () => {
    fetchCountries()
    fetchDisciplines()
    dispatch(loadOrganizationsAdminAll())

    onCountryChange()(ARGENTINA_COUNTRY.value)
  }, [])

  useEffect(() => {
    if (user && organizationsAdminAll.some((x) => x.organization.createdById === user.id)) {
      setOpenCreateOrganizationDialog(true)
    }
  }, [user, organizationsAdminAll])

  const onSubmit = async (values) => {
    try {
      const {
        name,
        disciplines,
        phone,
        comments,
        areaLevel1Id,
        countryId,
        eMail,
        hasWhatsApp,
        handle,
        userAdmins,
        referentName,
        idNumber
      } = values

      const formData = {
        name: name.trim(),
        phone,
        referentName: referentName.trim(),
        disciplines: disciplines.map((d) => ({ id: d.value })),
        comments,
        hasWhatsApp,
        userAccounts: userAdmins.map((x) => ({ userAccountId: x.id, isAdmin: true })),
        idNumber,
        eMail,
        areaLevel1Id,
        countryId,
        handle: handle.trim()
      }

      const createSuccess = await dispatch(create(formData))
      if (createSuccess) history.replace(`${ROUTES.ORGANIZATIONS.ONBOARDING}/${handle}`)
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const validationSchema = Yup.object().shape({
    eMail: Yup.string()
      .required('Ingresa tu dirección de correo')
      .min(5, 'El e-mail debe tener al menos 5 caracteres')
      .email('El e-mail es inválido')
      .max(254, 'El email debe tener como máximo 254 caracteres'),
    handle: Yup.string()
      .required('Ingresa el alias de la organización')
      .min(3, 'El alias de la organización debe tener al menos 3 caracteres')
      .max(30, 'El alias de la organización debe tener como máximo 30 caracteres')
      .matches(
        /^[a-z0-9]+$/i,
        'El alias de la organización no debe tener caracteres especiales ni espacios'
      )
      .trim(),
    name: Yup.string()
      .max(100, 'El nombre de la organización es demasiado largo')
      .required('Ingresa el nombre de la organización')
      .trim(),
    phone: Yup.string()
      .min(5, 'El número de teléfono debe tener al menos de 5 caracteres')
      .max(20, 'El número de teléfono no puede tener más de 20 caracteres')
      .required('Debe ingresar un número de télefono')
      .matches(/^[+]*(?:[0-9]?){5,20}[0-9]$/, 'Debe ingresar un número de télefono válido')
      .test(
        'test-phone',
        'El número de teléfono no puede contener números negativos',
        (value) => Number(value) > 0
      ),
    idNumber: Yup.string()
      .required('Debe ingresar el CUIT/CUIL')
      .matches(/^[0-9]+$/i, 'El CUIT/CUIL no debe tener caracteres especiales ni espacios')
      .test(
        'test-length',
        'El CUIT/CUIL debe tener un tamaño de 11 caracteres',
        (value) => !!value && value.length === CUIT_LENGTH
      )
      .test('test-cuit', 'El CUIT/CUIL no es válido', validateCuit)
      .trim(),

    countryId: Yup.string().required('Debe elegir el páis'),
    referentName: Yup.string()
      .transform((value) => removeDiacritics(value))
      .required('Debe ingresar el nombre del referente de la organización')
      .max(50, 'El nombre debe tener un tamaño máximo de 50 caracteres')
      .matches(
        /^[a-z ,.-]+$/i,
        'El nombre del referente no debe tener caracteres especiales ni números'
      )
      .trim(),
    acceptTermsAndConditions: Yup.boolean().oneOf(
      [true],
      'Debes aceptar los términos y condiciones'
    ),
    disciplines: Yup.mixed()
      .nullable()
      .test(
        'test-min',
        'Debe seleccionar al menos una opción',
        (value) => Array.isArray(value) && value.some(Boolean)
      )
  })

  const onCountryChange = (setFieldValue) => async (option) => {
    try {
      if (setFieldValue) {
        setFieldValue('countryId', option)
        setFieldValue('areaLevel1Id', null, false)
      }

      setProvinces([])

      if (option !== empty.value) {
        const proxy = new CountriesProxy()
        const data = await proxy.getAreaLevel1(option)
        if (Array.isArray(data) && !!data.length) {
          if (option !== ARGENTINA_COUNTRY.value) {
            setProvinces([empty, ...data.map((d) => ({ value: d.id, label: d.name }))])
          } else {
            setProvinces(data.map((d) => ({ value: d.id, label: d.name })))
          }
        }
      }
    } catch (e) {}
  }

  const values = {
    name: '',
    phone: '',
    eMail: '',
    handle: '',
    idNumber: '',
    countryId: ARGENTINA_COUNTRY.value,
    areaLevel1Id: '',
    referentName: userData ? userData.fullName : STRING_EMPTY,
    activeAddOrganizationAdmin: false,
    activeAddDisciplines: false,
    hasWhatsApp: true,
    acceptTermsAndConditions: false,
    userAdmins: [],
    disciplines: [],
    userSelected: null
  }

  const onDisciplinesChange = (setFieldValue) => (options) => setFieldValue('disciplines', options)

  const debouncerFetchUser = (inputValue, setFieldValue, values) =>
    fetchUsers(inputValue, setFieldValue, values)

  const debounceOnChange = debounce(debouncerFetchUser, 700)

  const handleOpenDialog = (setOpen) => (e) => setOpen(true)

  const handleCloseCreateOrganization = () => {
    setOpenCreateOrganizationDialog(false)
    history.replace(ROUTES.ROOT)
  }

  const renderAdminOption = (option) => {
    const { eMail, profileImages, username, id, isOrganizer } = option

    const currentNoImage = isOrganizer ? altNoImage : noImage

    const imageUrl =
      Array.isArray(profileImages) && profileImages.length > 0 ? profileImages[0] : currentNoImage

    return (
      <div className={clsx(classes.optionContainer, isOrganizer && 'organizer')}>
        <div className={classes.imageContainer} style={{ backgroundImage: `url(${imageUrl})` }} />

        <div>
          <Typography variant='h6' title={eMail}>
            {eMail}
          </Typography>
          <Typography
            variant='h6'
            title={`@${username || id} (Rol ${isOrganizer ? 'organizador' : 'deportista'})`}>
            @{username || id} <span>(Rol {isOrganizer ? 'organizador' : 'deportista'})</span>
          </Typography>
        </div>
      </div>
    )
  }

  const transparent = scrollTop < NAVBAR_TRANSPARENT_SCROLL

  return (
    <MainLayout>
      <NavBar
        widget={(props) => <SearchWidget mini {...props} />}
        transparent={transparent}
        altMode
      />

      <PageContainer customContainerClassName={classes.container} altMode>
        <div className={classes.mainContainer}>
          <Formik
            enableReinitialize
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.sectionBlock}>
                  <div className={classes.formGroup}>
                    <TextField
                      className={classes.input}
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete='off'
                      helperText={errors.name}
                      error={touched.name && Boolean(errors.name)}
                      fullWidth
                      required
                      label='Nombre de la Organización'
                      variant='outlined'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            {touched.name && Boolean(errors.name) ? (
                              <ErrorIcon color='error' />
                            ) : (
                              <CustomizedTooltip
                                position='top'
                                disabledClickAway
                                className={classes.tooltip}
                                buttonClassName={classes.tooltipButton}
                                arrowClassName={classes.tooltipArrow}
                                isTopMost={transparent}
                                title={
                                  <Typography className={classes.tooltipTitle}>
                                    Recuerda verificar que el nombre de tu organización no esté
                                    duplicado.
                                    <br /> Puedes revisar las organizaciones existentes en el{' '}
                                    <Link
                                      to='#'
                                      className={classes.tooltipLink}
                                      onClick={(e) => {
                                        e.preventDefault()
                                        setOpenOrganizationListDialog(true)
                                      }}>
                                      Listado de organizaciones.
                                    </Link>
                                  </Typography>
                                }>
                                {({ handleTooltip }) => (
                                  <IconButton
                                    onClick={handleTooltip}
                                    onMouseDown={(event) => event.preventDefault()}
                                    edge='end'
                                    color='primary'>
                                    <InfoIcon className={classes.infoIcon} />
                                  </IconButton>
                                )}
                              </CustomizedTooltip>
                            )}
                          </InputAdornment>
                        )
                      }}
                      size='small'
                      margin='none'
                    />

                    <OrganizationListDialog
                      open={openOrganizationListDialog}
                      onClose={() => setOpenOrganizationListDialog(false)}
                    />

                    <TextField
                      className={classes.input}
                      name='handle'
                      value={values.handle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete='off'
                      helperText={errors.handle || 'Nombre corto que identifique a la organización'}
                      error={touched.handle && Boolean(errors.handle)}
                      fullWidth
                      required
                      variant='outlined'
                      label='Alias de la organización'
                      size='small'
                      margin='none'
                      InputProps={{
                        endAdornment:
                          touched.handle && Boolean(errors.handle) ? (
                            <InputAdornment position='end'>
                              <ErrorIcon color='error' />
                            </InputAdornment>
                          ) : null
                      }}
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      name='eMail'
                      className={classes.input}
                      value={values.eMail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={errors.eMail}
                      error={touched.eMail && Boolean(errors.eMail)}
                      fullWidth
                      required
                      label='E-mail de la Organización'
                      autoComplete='off'
                      variant='outlined'
                      size='small'
                      type='email'
                      margin='none'
                      InputProps={{
                        endAdornment:
                          touched.eMail && Boolean(errors.eMail) ? (
                            <InputAdornment position='end'>
                              <ErrorIcon color='error' />
                            </InputAdornment>
                          ) : null
                      }}
                    />
                    <TextField
                      className={classes.input}
                      name='idNumber'
                      value={values.idNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete='off'
                      helperText={errors.idNumber || 'CUIT/CUIL usado por la organización'}
                      error={touched.idNumber && Boolean(errors.idNumber)}
                      fullWidth
                      required
                      variant='outlined'
                      label='Número de CUIT/CUIL'
                      size='small'
                      margin='none'
                      InputProps={{
                        endAdornment:
                          touched.idNumber && Boolean(errors.idNumber) ? (
                            <InputAdornment position='end'>
                              <ErrorIcon color='error' />
                            </InputAdornment>
                          ) : null
                      }}
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      className={classes.input}
                      name='referentName'
                      value={values.referentName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete='off'
                      helperText={errors.referentName}
                      error={touched.referentName && Boolean(errors.referentName)}
                      fullWidth
                      required
                      variant='outlined'
                      label='Referente contacto de la organización'
                      size='small'
                      margin='none'
                      InputProps={{
                        endAdornment:
                          touched.referentName && Boolean(errors.referentName) ? (
                            <InputAdornment position='end'>
                              <ErrorIcon color='error' />
                            </InputAdornment>
                          ) : null
                      }}
                    />
                    <div className={classes.phoneContainer}>
                      <TextField
                        name='phone'
                        className={classes.input}
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.phone}
                        error={touched.phone && Boolean(errors.phone)}
                        fullWidth
                        required
                        label='Teléfono de contacto'
                        autoComplete='off'
                        variant='outlined'
                        size='small'
                        type='text'
                        margin='none'
                        InputProps={{
                          endAdornment:
                            touched.phone && Boolean(errors.phone) ? (
                              <InputAdornment position='end'>
                                <ErrorIcon color='error' />
                              </InputAdornment>
                            ) : null
                        }}
                      />
                      <div className={classes.whatsAppContainer}>
                        <StyledSwitch
                          checked={values.hasWhatsApp}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name='hasWhatsApp'
                        />
                        <Typography variant='h6' className={classes.whatsAppTitle}>
                          Tiene WhatsApp
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className={classes.formGroup}>
                    <FormControl variant='outlined'>
                      <InputLabel id='country-native-simple' className={classes.labelSelect}>
                        País*
                      </InputLabel>
                      <Select
                        className={classes.select}
                        value={values.countryId}
                        error={Boolean(errors.countryId) && touched.countryId}
                        onBlur={handleBlur}
                        name='countryId'
                        onChange={(e) => onCountryChange(setFieldValue)(e.target.value)}
                        color='primary'
                        variant='outlined'
                        labelId='country-native-simple'
                        label='País*'>
                        {countries.map((x) => (
                          <MenuItem value={x.value} key={x.value}>
                            {x.label}
                          </MenuItem>
                        ))}
                      </Select>

                      <Typography variant='caption' className={classes.helperText}>
                        {errors.countryId || 'Seleccione su pais'}
                      </Typography>
                    </FormControl>

                    <FormControl variant='outlined'>
                      <InputLabel
                        id='area-level-1-select-outlined-label'
                        className={classes.labelSelect}>
                        Seleccione su Provincia
                      </InputLabel>
                      <Select
                        name='areaLevel1Id'
                        labelId='area-level-1-select-outlined-label'
                        id='area-level-1-select-outlined'
                        label='Seleccione su Provincia'
                        value={values.areaLevel1Id}
                        onChange={handleChange}
                        error={Boolean(errors.areaLevel1Id) && touched.areaLevel1Id}
                        onBlur={handleBlur}
                        className={classes.select}>
                        <MenuItem value={empty.value}>{empty.label} </MenuItem>

                        {provinces.map((x) => (
                          <MenuItem value={x.value} key={x.value}>
                            {x.label}
                          </MenuItem>
                        ))}
                      </Select>

                      <Typography variant='caption' className={classes.helperText}>
                        {errors.areaLevel1Id}
                      </Typography>
                    </FormControl>
                  </div>

                  <div className={classes.formGroup}>
                    <FormControl variant='outlined' className={classes.formControl}>
                      <Autocomplete
                        multiple
                        id='disciplinesSelect-autocomplete'
                        options={disciplines}
                        getOptionLabel={(option) => option.label}
                        selectOnFocus
                        onChange={(event, optionSelected) =>
                          onDisciplinesChange(setFieldValue)(optionSelected)
                        }
                        getOptionSelected={(x) =>
                          values.disciplines.some((o) => o.value === x.value)
                        }
                        filterSelectedOptions
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant='outlined'
                              label={option.label}
                              key={option.value}
                              title={option.label}
                              {...getTagProps({ index })}
                              color='primary'
                              className={classes.option}
                            />
                          ))
                        }
                        value={values.disciplines}
                        className={classes.combo}
                        clearText='Quitar todo'
                        openText='Abrir desplegable'
                        closeText='Cerrar desplegable'
                        noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={classes.comboInput}
                            name='disciplines'
                            label='Seleccione Deporte/ Disciplina*'
                            variant='outlined'
                            onBlur={handleBlur}
                            error={touched.disciplines && !!errors.disciplines}
                            helperText={
                              errors.disciplines || 'Seleccione la o las opciones del desplegable'
                            }
                          />
                        )}
                      />
                    </FormControl>

                    <div className={classes.organizationFormGroup}>
                      <div className={classes.checkContainer}>
                        <FormControlLabel
                          className={classes.formControl}
                          classes={{
                            label: classes.checkLabel
                          }}
                          control={
                            <Checkbox
                              checked={values.activeAddOrganizationAdmin}
                              className={classes.checkbox}
                              onChange={(e) => {
                                if (!values.activeAddOrganizationAdmin)
                                  setFieldValue('userAdmins', [])

                                handleChange(e)
                              }}
                              name='activeAddOrganizationAdmin'
                              color='primary'
                            />
                          }
                          label='¿Desea añadir uno o más colaboradores?'
                        />

                        <CustomizedTooltip
                          position={isDesktop ? 'right' : 'top-start'}
                          disabledClickAway
                          className={classes.adminToltip}
                          buttonClassName={classes.adminTooltipButton}
                          arrowClassName={classes.tooltipArrow}
                          title={
                            <Typography className={classes.tooltipTitle}>
                              La persona que añadas podrá gestionar eventos bajo tu supervisión/
                              autorización.
                              <Link
                                to='#'
                                className={classes.tooltipLinkBlue}
                                onClick={(e) => {
                                  e.preventDefault()
                                  setOpenAdministratorDialog(true)
                                }}>
                                ¿Te cuento más?
                              </Link>
                            </Typography>
                          }>
                          {({ handleTooltip }) => (
                            <IconButton
                              onMouseDown={(event) => event.preventDefault()}
                              edge='end'
                              size='small'
                              color='primary'
                              onClick={handleTooltip}>
                              <InfoIcon className={classes.infoIcon} />
                            </IconButton>
                          )}
                        </CustomizedTooltip>
                        <AdministratorPermissionDialog
                          open={openAdministratorDialog}
                          onClose={() => setOpenAdministratorDialog(false)}
                        />
                      </div>
                      {values.activeAddOrganizationAdmin && (
                        <div className={classes.selectContainer}>
                          <Autocomplete
                            id='organizationUser'
                            options={options.filter((x) => x.id !== user.id)}
                            multiple
                            selectOnFocus
                            getOptionSelected={(x) => values.userAdmins.some((u) => u.id === x.id)}
                            filterSelectedOptions
                            onInputChange={(e) =>
                              debounceOnChange(e?.target.value, setFieldValue, values)
                            }
                            onChange={(e, optionSelected) =>
                              setFieldValue('userAdmins', optionSelected)
                            }
                            closeIcon={<CloseIcon color='primary' className={classes.closeIcon} />}
                            loading={loading}
                            value={values.userAdmins}
                            className={classes.combo}
                            filterOptions={(options) => options}
                            loadingText={<Typography align='center'> Cargando...</Typography>}
                            getOptionLabel={(option) => option.username || option.eMail}
                            noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                            clearText='Quitar todo'
                            openText='Abrir desplegable'
                            closeText='Cerrar desplegable'
                            values={values.userAdmins}
                            renderOption={renderAdminOption}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant='outlined'
                                  label={option.username || option.eMail}
                                  key={option.id}
                                  title={option.username || option.eMail}
                                  {...getTagProps({ index })}
                                  color='primary'
                                  className={classes.option}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className={classes.comboInput}
                                name='usernameOrEmail'
                                label='Escribí el email o alias del usuario*'
                                variant='outlined'
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loading && <CircularProgress color='primary' size={16} />}

                                      <CustomizedTooltip
                                        position={isDesktop ? 'right' : 'top-start'}
                                        disabledClickAway
                                        className={classes.altTooltip}
                                        buttonClassName={classes.adminTooltipButton}
                                        arrowClassName={classes.tooltipArrow}
                                        title={
                                          <Typography className={classes.tooltipTitle}>
                                            El mail de invitación a los colaboradores se enviará al
                                            finalizar el proceso de creación de la cuenta.
                                          </Typography>
                                        }>
                                        {({ handleTooltip }) => (
                                          <IconButton
                                            onMouseDown={(event) => event.preventDefault()}
                                            size='small'
                                            color='primary'
                                            onClick={handleTooltip}
                                            className={classes.endButton}>
                                            <InfoIcon className={classes.infoIcon} />
                                          </IconButton>
                                        )}
                                      </CustomizedTooltip>
                                    </>
                                  )
                                }}
                              />
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={classes.buttonsContainer}>
                    <FormControlLabel
                      classes={{
                        label: classes.termsAndConditionsLabel
                      }}
                      control={
                        <Checkbox
                          checked={values.acceptTermsAndConditions}
                          className={classes.checkbox}
                          onChange={handleChange}
                          name='acceptTermsAndConditions'
                          color='primary'
                        />
                      }
                      label={
                        <>
                          Acepto las{' '}
                          <Link
                            className={classes.termsAndConditionsLink}
                            to='#'
                            onClick={handleOpenDialog(setOpenTerms)}>
                            Condiciones de uso y
                          </Link>{' '}
                          <Link
                            className={classes.termsAndConditionsLink}
                            to='#'
                            onClick={handleOpenDialog(setOpenPolicy)}>
                            la Política de privacidad.
                          </Link>
                        </>
                      }
                    />

                    <TermsAndConditionsDialog
                      open={openTerms}
                      handleClose={() => setOpenTerms(false)}
                      title='Condiciones de uso'
                      color='secondary'
                      content={TermsAndConditionsOrganizationContent}
                    />

                    <TermsAndConditionsDialog
                      open={openPolicy}
                      handleClose={() => setOpenPolicy(false)}
                      title='Política de privacidad'
                      color='secondary'
                      content={PrivacyPolicyContent}
                    />

                    <CreateOrganizationInfoDialog
                      open={openCreateOrganizationDialog}
                      onClose={handleCloseCreateOrganization}
                    />

                    <Button
                      color='primary'
                      variant='contained'
                      type='submit'
                      disabled={!isValid || isSubmitting}
                      endIcon={pending && <CircularProgress size={16} />}>
                      Crear organización
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </PageContainer>

      <Footer />
    </MainLayout>
  )
}

export default CreatePage
