import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import noImage from 'assets/img/blue-user-mock-alt.jpg'
import timeKeeperNoImage from 'assets/img/time-keeper-profile.jpg'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import { ROUTES } from 'routes'
import { OrganizationsProxy } from 'services'
import { EditableSection } from 'shared'
import { STRING_EMPTY } from 'utils/constants'
import { getProfileImageUrl } from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'
import * as Yup from 'yup'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './TimingSection.style'

const TimingSection = ({ onSave, canEdit, ...sectionProps }) => {
  const classes = useStyles()
  const history = useHistory()

  const { event } = useSelector((state) => state.events)

  const [organizationOptions, setOrganizationOptions] = useState([])
  const [organizationsPending, setOrganizationsPending] = useState(false)

  const onSubmit = async ({ value }) => {
    const { timekeeperSelected, timekeeperName } = value

    const hasTimeKeeperSelected =
      timekeeperSelected &&
      (timekeeperSelected.name === timekeeperName || timekeeperSelected.handle === timekeeperName)

    const eventTimekeeper = {
      id: event.eventTimekeeper?.id,
      organizationId: hasTimeKeeperSelected ? timekeeperSelected.id : null,
      name: timekeeperName
    }

    await onSave({ value: { eventTimekeeper: timekeeperName ? eventTimekeeper : null } })
  }

  const validations = Yup.object().shape({
    timekeeperName: Yup.string()
      .nullable()
      .min(3, 'Debe ingresar al menos 3 caracteres')
      .max(100, 'Tiene un máximo de 100 caracteres')
  })

  const onTimekeeperChange = (setFieldValue, options) =>
    setFieldValue('timekeeperSelected', options)

  const debounceFetchOrganizations = (inputValue) => fetchOrganizations(inputValue)

  const debounceOnChange = debounce(debounceFetchOrganizations, 700)

  const fetchOrganizations = async (searchText = STRING_EMPTY) => {
    searchText = (searchText ?? STRING_EMPTY).trim()

    if (!searchText || searchText.length < 3) {
      setOrganizationOptions([])
      return
    }

    try {
      setOrganizationsPending(true)

      const proxy = new OrganizationsProxy()
      const data = await proxy.getEventOrganizationSuggestions(searchText, 1, 20)

      setOrganizationOptions([...data.results])

      return data.results
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setOrganizationsPending(false)
    }
  }

  const renderSelectOption = ({ profileImages, name, handle, id }) => {
    const imageUrl =
      Array.isArray(profileImages) && profileImages.length > 0 ? profileImages[0] : noImage
    const handleOrId = handle || id

    return (
      <div className={classes.selectOption}>
        <div className={classes.imageContainer} style={{ backgroundImage: `url(${imageUrl})` }} />

        <div className={classes.info}>
          <Typography variant='h6' color='primary' className={classes.timingName}>
            {name}
          </Typography>

          <Typography variant='h6' color='primary' className={classes.timingName}>
            @{handleOrId}
          </Typography>
        </div>
      </div>
    )
  }

  const hasTimeKeeper = !!event.eventTimekeeper
  const hasTimeKeeperOrganization = hasTimeKeeper && event.eventTimekeeper.organization

  const tryRedirectOrganization = () => {
    if (!hasTimeKeeperOrganization) return

    history.push(`${ROUTES.ORGANIZATIONS.PROFILE}/${event.eventTimekeeper.organizationId}`)
  }

  const values = {
    ...event,
    timekeeperName: event.eventTimekeeper?.name ?? STRING_EMPTY,
    timekeeperSelected: event.eventTimekeeper
  }

  const onInputChange = (debounceOnChange, setFieldValue) => (event, value, reason) => {
    value = (value ?? STRING_EMPTY).trim()

    setFieldValue('timekeeperName', value)
    debounceOnChange(value)
  }

  return (
    <EditableSection
      entity={values}
      canEdit={canEdit}
      validationSchema={validations}
      onSave={onSubmit}
      {...sectionProps}>
      {({
        values,
        errors,
        setFieldValue,
        handleSubmit,
        touched,
        activeEdit,
        setActiveEdit,
        isSubmitting,
        handleBlur,
        isValid
      }) => (
        <SectionBlock title='Cronometra'>
          {activeEdit && (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Autocomplete
                id='timekeeper-autocomplete'
                options={organizationOptions.filter(
                  (x) => !event.organizations.some((o) => o.organization.id === x.id)
                )}
                getOptionLabel={(option) => option.handle || option.name}
                selectOnFocus
                filterSelectedOptions
                onInputChange={onInputChange(debounceOnChange, setFieldValue)}
                onChange={(event, optionSelected) =>
                  onTimekeeperChange(setFieldValue, optionSelected, event)
                }
                freeSolo
                renderOption={renderSelectOption}
                filterOptions={(x) => x}
                className={classes.combo}
                disabled={event.isSuspended}
                value={values.eventTimekeeper}
                loading={organizationsPending}
                loadingText={<Typography align='center'> Cargando...</Typography>}
                noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                clearText='Quitar todo'
                openText='Abrir desplegable'
                closeText='Cerrar desplegable'
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={clsx(classes.input, classes.comboInput)}
                    name='timekeeperName'
                    label='Nombre o alias del cronometrista'
                    variant='outlined'
                    onBlur={handleBlur}
                    error={touched.timekeeperName && Boolean(errors.timekeeperName)}
                    helperText={
                      errors.timekeeperName || 'Si tiene perfil en Sportmetric escribí su alias.'
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: organizationsPending ? (
                        <CircularProgress color='primary' size={16} />
                      ) : (
                        params.InputProps.endAdornment
                      )
                    }}
                  />
                )}
              />
              <Button
                variant='contained'
                color='primary'
                disabled={isSubmitting || !isValid}
                endIcon={isSubmitting && <CircularProgress size={16} color='primary' />}
                className={classes.saveButton}
                type='submit'>
                Guardar
              </Button>
            </form>
          )}

          {canEdit && !activeEdit && !hasTimeKeeper && (
            <Typography
              color='primary'
              variant='h6'
              className={clsx(classes.addLink, event.isSuspended && 'disabled')}
              onClick={() => {
                if (event.isSuspended) return

                setActiveEdit(true)
              }}>
              + Añadir cronometrista
            </Typography>
          )}

          {hasTimeKeeper && (
            <div
              className={clsx(classes.timeKeeperContainer, hasTimeKeeperOrganization && 'clicked')}
              onClick={tryRedirectOrganization}>
              {hasTimeKeeperOrganization && (
                <div
                  className={classes.imageContainer}
                  style={{
                    backgroundImage: `url(${getProfileImageUrl(
                      event.eventTimekeeper.organization,
                      0,
                      timeKeeperNoImage
                    )})`
                  }}
                />
              )}
              <Typography className={classes.timekeeperName} title={event.eventTimekeeper.name}>
                {event.eventTimekeeper.name}
              </Typography>
            </div>
          )}
        </SectionBlock>
      )}
    </EditableSection>
  )
}

export default TimingSection
